<template>
  <e-dialog
    :value="value"
    size="2x-large"
    fill-height
    title="Image editing tool"
    content-class="e-dialog-photo-cropper"
    @close="hide"
  >
    <v-layout class="overflow-hidden position-relative">
      <p v-if="errorMsg" class="absolute-center font-italic">
        {{ errorMsg }}
      </p>
      <Cropper
        v-if="value"
        ref="cropper"
        :src="imageBase64"
        :default-boundaries="boundaries"
        :stencil-props="{ aspectRatio }"
        :size-restrictions-algorithm="percentsRestriction"
        :min-height="40"
        :min-width="40"
        :max-width="1920"
        class="cropper h-full w-full"
        @ready="ready"
        @error="error"
        @change="change"
      />

      <v-layout v-show="!loading && !errorMsg" class="position-absolute bottom-3 left-0 right-0 justify-center">
        <button title="Zoom In" class="square-button" @click="zoom(2)">
          <img :src="'icons/zoom-in.svg' | assetImageUrl">
        </button>
        <button title="Zoom Out" class="square-button" @click="zoom(0.5)">
          <img :src="'icons/zoom-out.svg' | assetImageUrl">
        </button>
        <button title="Move Top" class="square-button" @click="move('top')">
          <img :src="'icons/arrow-top.svg' | assetImageUrl">
        </button>
        <button title="Move Left" class="square-button" @click="move('left')">
          <img :src="'icons/arrow-left.svg' | assetImageUrl">
        </button>
        <button title="Move Right" class="square-button" @click="move('right')">
          <img :src="'icons/arrow-right.svg' | assetImageUrl">
        </button>
        <button title="Move Bottom" class="square-button" @click="move('bottom')">
          <img :src="'icons/arrow-bottom.svg' | assetImageUrl">
        </button>
        <button title="Rotate Clockwise" class="square-button" @click="rotate(90)">
          <img :src="'icons/rotate-clockwise.svg' | assetImageUrl">
        </button>
        <button title="Rotate Counter-Clockwise" class="square-button" @click="rotate(-90)">
          <img :src="'icons/rotate-counter-clockwise.svg' | assetImageUrl">
        </button>
      </v-layout>

      <v-progress-circular
        v-show="loading"
        :size="100"
        color="primary"
        indeterminate
        class="absolute-center"
      />
    </v-layout>

    <template slot="actions">
      <v-layout column>
        <span v-if="recommendSize.width > 0 && recommendSize.height > 0" class="text-body-2 font-italic">{{ `Recommended size: ${recommendSize.width} x ${recommendSize.height}` }}</span>
        <span v-if="coordinates.width > 0 && coordinates.height > 0" class="text-body-2 font-italic">{{ `Result size: ${coordinates.width} x ${coordinates.height}` }}</span>
      </v-layout>
      <v-spacer />
      <v-btn
        v-if="errorMsg"
        :disabled="loading"
        large
        outlined
        color="primary"
        class="px-5 rounded-lg"
        @click="hide"
      >
        Close
      </v-btn>
      <v-btn
        v-if="!errorMsg"
        :disabled="loading"
        large
        outlined
        color="primary"
        class="px-5 rounded-lg"
        @click="reset"
      >
        Reset
      </v-btn>
      <v-btn
        v-if="!errorMsg"
        :disabled="loading"
        large
        depressed
        color="primary"
        class="px-5 rounded-lg"
        @click="save"
      >
        Crop & Save
      </v-btn>
    </template>
  </e-dialog>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import 'vue-advanced-cropper/dist/theme.compact.css'
import { getName } from '@/utils/file'
import { uploadFile2S3 } from '@/utils/upload-s3'

export default {
  name: 'EDialogPhotoCropper',

  components: {
    Cropper
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: 'Crop Image'
    },
    // eslint-disable-next-line vue/require-prop-types
    imageSrc: {
      default: ''
    },
    defaultCoordinates: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    isKeepSize: {
      type: Boolean,
      default: false
    },
    scale: {
      type: Number,
      default: 1
    },
    isSave: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    imageBase64: '',
    isTryAgain: false,

    loading: false,
    errorMsg: '',

    coordinates: {
      width: 0,
      height: 0,
      left: 0,
      top: 0
    },
    size: {
      width: null,
      height: null
    }
  }),

  computed: {
    aspectRatio() {
      return this.width !== 0 && this.height !== 0 ? this.width / this.height : null
    },
    recommendSize() {
      const width = this.isKeepSize ? this.width * this.scale : Math.max(this.width, 1920)
      const height = this.aspectRatio ? Math.round(width / this.aspectRatio) : 0
      return {
        width,
        height
      }
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.tryAgain = false
        this.loading = true
        this.errorMsg = ''
        this.imageBase64 = this.imageSrc
      } else {
        this.errorMsg = ''
        this.imageBase64 = ''
        this.loading = false
      }
    }
  },

  methods: {
    boundaries({ cropper }) {
      return {
        width: cropper.clientWidth,
        height: cropper.clientHeight
      }
    },
    percentsRestriction({ minWidth, minHeight, maxWidth, maxHeight, imageSize }) {
      return {
        maxWidth: maxWidth || (imageSize.width * (maxWidth || 100)) / 100,
        maxHeight: maxHeight || (imageSize.height * (maxHeight || 100)) / 100,
        minWidth: this.recommendSize.width || (imageSize.width * (minWidth || 0)) / 100,
        minHeight: this.recommendSize.height || (imageSize.height * (minHeight || 0)) / 100
      }
    },
    zoom(factor) {
      this.$refs.cropper.zoom(factor)
    },
    move(direction) {
      if (direction === 'left') {
        this.$refs.cropper.move(-this.size.width / 4)
      } else if (direction === 'right') {
        this.$refs.cropper.move(this.size.width / 4)
      } else if (direction === 'top') {
        this.$refs.cropper.move(0, -this.size.height / 4)
      } else if (direction === 'bottom') {
        this.$refs.cropper.move(0, this.size.height / 4)
      }
    },
    flip(x, y) {
      const { image } = this.$refs.cropper.getResult()
      if (image.transforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y)
      } else {
        this.$refs.cropper.flip(x, y)
      }
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle)
    },
    reset() {
      this.$refs.cropper.reset()
    },
    change({ coordinates, canvas }) {
      this.coordinates = coordinates
      this.size.width = Math.round(coordinates.width)
      this.size.height = Math.round(coordinates.height)
    },
    async error() {
      if (this.tryAgain) {
        this.errorMsg = 'Load image failed, please try another one'
        this.loading = false
      } else {
        this.tryAgain = true
        this.imageBase64 = await this.getImageBase64(this.imageSrc)
        this.loading = false
      }
    },
    ready() {
      this.loading = false
      this.$refs.cropper.setCoordinates(({ coordinates, imageSize }) => {
        const hasPropsValue = this.defaultCoordinates && this.defaultCoordinates.width > 0 && this.defaultCoordinates.height > 0
        if (hasPropsValue) {
          return this.defaultCoordinates
        }
        return {
          left: imageSize.width / 2 - coordinates.width / 2,
          top: imageSize.height / 2 - coordinates.height / 2
        }
      })
    },

    hide() {
      this.$emit('input', false)
    },
    async getImageBase64(url) {
      try {
        const { data } = await this.$axios.get('/image-content', { params: { url } })
        return data.base64Image
      } catch (error) {
        this.error()
      }
    },
    base64ToBlob(base64, mime, name) {
      const sliceSize = 1024
      const byteChars = window.atob(base64.replace(/^data:image\/(jpeg|jpg|png|gif|bmp);base64,/, ''))
      let byteArrays = []

      for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        const slice = byteChars.slice(offset, offset + sliceSize)
        let byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        byteNumbers = this.$helpers.cloneDeep(byteNumbers)
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays = [...byteArrays, byteArray.buffer]
      }

      const blob = new Blob(byteArrays, { type: mime || '' })
      if (name && typeof name === 'string') {
        blob.name = name
      }
      return blob
    },
    async save() {
      this.loading = true
      try {
        const { canvas } = this.$refs.cropper.getResult()
        const dataURL = canvas.toDataURL()
        if (dataURL && dataURL.match(/^data:image\/(jpeg|jpg|png|gif|bmp);base64,/)) {
          const base64 = dataURL.replace(/^data:image\/(jpeg|jpg|png|gif|bmp);base64,/, '')
          const blob = this.base64ToBlob(base64, 'image/jpg', getName(this.imageSrc))
          if (this.isSave) {
            const data = await uploadFile2S3(blob)
            this.$emit('saved', {
              url: data.preview,
              originalUrl: this.imageSrc,
              coordinates: this.coordinates
            })
          } else {
            this.$emit('saved', {
              file: blob,
              originalUrl: this.imageSrc,
              coordinates: this.coordinates
            })
          }
          this.hide()
        } else {
          this.$notify.error({ message: 'Save image failed, please try again' })
        }
      } catch (error) {
        this.$notify.error({ message: 'Save image failed, please try again' })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.e-dialog-photo-cropper {
  .square-button {
    background: rgba(0, 0, 0, .4);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    margin: 5px;
    cursor: pointer;
    transition: background .5s;
  }
}
</style>
