<template>
  <e-input>
    <v-text-field
      v-model="keyword"
      clearable
      dense
      outlined
      hide-details
      :placeholder="placeholder"
      class="bg-white e-search"
      prepend-inner-icon="search"
    />
  </e-input>
</template>

<script>
export default {
  name: 'ESearch',

  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true
    },
    placeholder: {
      type: String,
      default: 'Search...'
    },
    width: {
      type: String,
      default: '360'
    }
  },

  data: () => ({
    keyword: '',
    timeOut: false
  }),

  watch: {
    value: {
      handler(value) {
        this.keyword = value
      },
      immediate: true
    },
    keyword(value) {
      if (this.timeOut) {
        clearTimeout(this.timeOut)
      }
      this.timeOut = setTimeout(() => {
        this.$emit('input', value)
      }, 700)
    }
  }
}
</script>

<style lang="scss" scoped>
.e-search {
  width: 360px;
}
</style>
