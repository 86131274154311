<template>
  <e-dialog
    :value="value"
    :title="social.label"
    max-width="440px"
    persistent
    @close="hide"
  >
    <v-layout class="mx-5 mt-4 rounded-lg align-center">
      <v-form ref="ruleFormRef" class="form-edit-social-footer">
        <v-text-field
          v-model="formData.link"
          dense
          outlined
          placeholder="link"
          class="mt-2"
          :rules="[$formRules.validLink]"
        />
      </v-form>
    </v-layout>
    <template slot="actions">
      <v-spacer />
      <v-btn
        large
        outlined
        color="primary"
        class="px-5 rounded-lg"
        @click="hide"
      >
        Cancel
      </v-btn>
      <v-btn
        large
        depressed
        color="primary"
        class="px-5 rounded-lg"
        @click="save"
      >
        Save
      </v-btn>
    </template>
  </e-dialog>
</template>

<script>
export default {
  name: 'PopupEditSocialFooter',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    social: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    formData: {}
  }),
  watch: {
    value(v) {
      if (v) {
        this.formData = { ...this.social }
      }
    }
  },
  methods: {
    hide() {
      this.$refs.ruleFormRef.reset()
      this.$emit('input', false)
    },
    async save() {
      const ok = await this.$refs.ruleFormRef.validate()
      if (ok) {
        this.$emit('save', this.$helpers.cloneDeep(this.formData))
        this.hide()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-edit-social-footer {
  width: 440px;
}
</style>
