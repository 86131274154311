import { get } from 'lodash'
import { useMutationObserver } from '@vueuse/core'

export default {
  bind: (el, binding) => {
    if (get(binding, 'value.enabled', false)) {
      let stopWatch = false
      el.style.position = 'relative'
      const opacity = get(binding, 'value.opacity', '0.3')
      const blur = get(binding, 'value.blur', '5')
      const style = `background-color:#ffffff !important;opacity:${opacity} !important;position:absolute !important;left:0 !important;right:0 !important;top:0 !important;bottom:0 !important;z-index:100 !important;`
      const overlayElement = window.document.createElement('div')
      overlayElement.dataset.lockOverlay = ''
      el.style.setProperty('filter', `blur(${blur}px)`, 'important')
      useMutationObserver(el, (mutations) => {
        const mutation = mutations[0]
        if (mutation.target !== el && mutation.target !== overlayElement) {
          return
        }
        if (stopWatch) {
          stopWatch = false
          return
        }
        stopWatch = true
        const cssObj = window.getComputedStyle(el, null)
        if (!cssObj.filter || cssObj.filter !== `blur(${blur}px)`) {
          el.style.setProperty('filter', `blur(${blur}px)`, 'important')
        }
        if (!el.querySelector('[data-lock-overlay]')) {
          el.prepend(overlayElement)
        }
        overlayElement.style = style
      }, {
        attributes: true, childList: true, subtree: true
      })
    }
  }
}
