export const state = () => ({
  logginEmail: '',
  uploadStatus: [],
  loginRedirect: {
    path: '',
    query: {}
  },
  contentStreamsShortcuts: [
    { label: 'Listings', value: 'listing', imgSrc: 'dashboard/listing.png' },
    { label: 'Content Marketing', value: 'content-marketing', imgSrc: 'dashboard/content-marketing.png' },
    { label: 'Lead Generation', value: 'lead-generation', imgSrc: 'dashboard/lead-generation.png' },
    { label: 'Sales Page', value: 'sales-page', imgSrc: 'dashboard/sales-page.png' },
    { label: 'Events', value: 'events', imgSrc: 'dashboard/events.png' }
  ],
  promotionalContentChannels: [
    // Values is the template channel
    { label: 'Social Media', value: 'social media', values: ['seo', 'fb_post', 'twitter'], imgSrc: 'dashboard/social-media.png' },
    { label: 'Ads', value: 'fb_ads', values: ['fb_ads'], imgSrc: 'dashboard/ads.png' },
    { label: 'Emails', value: 'email', values: ['fb_ads'], imgSrc: 'dashboard/emails.png' },
    { label: 'Websites', value: 'embed', values: ['embed'], imgSrc: 'dashboard/websites.png' },
    { label: 'SMS', value: 'sms', values: ['sms'], imgSrc: 'dashboard/sms.png' }
  ],
  contentItemsTypes: [
    { label: 'Graphics', value: 'graphic', imgSrc: 'dashboard/graphics.png' },
    { label: 'Blog', value: 'blog', imgSrc: 'dashboard/blogs.png' },
    { label: 'Forms & Quizzes', value: 'form', imgSrc: 'dashboard/forms-quizzes.png' },
    { label: 'Curated Content', value: 'curated', imgSrc: 'dashboard/curated-content.png' }
  ],
  submitting: {},
  isRequesting: false,
  settings: null,
  popupAction: {
    action: null,
    data: null,
    flag: 0
  },
  userSubdomain: null
})

export const getters = {
  // getExampleState (state) {
  //   return state.exampleState
  // }
  isUploading(state) {
    return !!state.uploadStatus.length
  },
  submitting(state) {
    return state.submitting
  },
  isRequesting(state) {
    return state.isRequesting
  },
  popupAction(state) {
    return state.popupAction
  },
  userSubdomain: state => state.userSubdomain,
  settings: state => state.settings || {}
}

export const mutations = {
  // [types.SET_EXAMPLE_STATE] (state, payload) {
  //   state.exampleState = payload
  // },
  // SET_USER (state, user) {
  //   state.authUser = user
  // }
  setLogginEmail(state, email) {
    state.logginEmail = email
  },
  setUploadStatus(state, payload) {
    state.uploadStatus.push(payload)
  },
  unsetUploadStatus(state, payload) {
    const index = state.uploadStatus.findIndex(item => item.id === payload.id)
    state.uploadStatus.splice(index, 1)
  },
  setLoginRedirect(state, data = {}) {
    state.loginRedirect = Object.assign(state.loginRedirect, data)
  },
  setSubmiting(state, data) {
    state.submitting = {
      ...state.submitting,
      ...data
    }
  },
  setRequesting(state, isRequesting) {
    state.isRequesting = isRequesting
  },
  saveSetting(state, settings) {
    state.settings = settings
  },
  setPopupAction(state, data = {}) {
    state.popupAction = {
      ...state.popupAction,
      ...data,
      flag: (state.popupAction.flag || 0) + 1
    }
  },
  setUserSubdomain(state, subdomain) {
    state.userSubdomain = subdomain
  }
}

export const actions = {
  // [types.SET_EXAMPLE_STATE] ({ commit }, payload) {
  //   commit(types.SET_EXAMPLE_STATE, payload)
  // },
  // nuxtServerInit is called by Nuxt.js before server-rendering every page
  // nuxtServerInit ({ commit }, { req }) {
  //   if (req.session && req.session.authUser) {
  //     commit('SET_USER', req.session.authUser)
  //   }
  // },
  // async login ({ commit }, { email, password }) {
  //   try {
  //     const { data } = await axios.post('/api/auth/login', { email, password })
  //     commit('SET_USER', data)
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       throw new Error('Bad credentials')
  //     }
  //     throw error
  //   }
  // },

  // async logout ({ commit }) {
  //   await axios.post('/api/logout')
  //   commit('SET_USER', null)
  // }
}
