import Vue from 'vue'
import moment from 'moment'
import { lowerCase, capitalize } from 'lodash'
import { formatCurrency } from '../../utils'
import { assetImageUrl } from '../../utils/file'
const pluralize = require('pluralize')

Vue.filter('displayDate', (date, format = 'llll') => {
  if (!date) { return '' }
  return moment(date).format(format)
})

Vue.filter('capitalize', (str = '') => {
  return capitalize(str)
})

Vue.filter('lowerCase', (str = '') => {
  return lowerCase(str)
})

Vue.filter('maxLength', (str = '', length = 100) => {
  if (!str) { return '' }
  if (str.length < length) {
    return str
  }
  return str.substring(0, length) + '...'
})

Vue.filter('promotionalContentTitle', (item = {}) => {
  return item.subject || item.purpose
})

Vue.filter('promotionalContentDescription', (item) => {
  return item.body
})

Vue.filter('fromNow', (date) => {
  return moment(date).fromNow()
})

Vue.filter('formatNumber', (number = 0) => Number(number).toLocaleString('en-US'))

Vue.filter('formatCurrency', formatCurrency)

Vue.filter('assetImageUrl', assetImageUrl)

Vue.filter('formatTime', (date, format = 'HH:mm') => {
  if (date) {
    return moment(date).format(format)
  }
  return date
})

const FILTERS = {
  socialIcon(type) {
    switch (type) {
      case 'fb_page':
      case 'fb_group':
        return 'mdi-facebook'
      case 'instagram':
        return 'mdi-instagram'
      case 'linkedin_profile':
      case 'linkedin_organization':
        return 'mdi-linkedin'
    }
  },

  socialColor(type) {
    switch (type) {
      case 'fb_page':
      case 'fb_group':
        return 'primary'
      case 'instagram':
        return 'orange darken-4'
      case 'linkedin_profile':
      case 'linkedin_organization':
        return 'blue'
    }
  },

  statusColor(status) {
    switch (status) {
      case 'active':
      case 'published':
        return {
          bgColor: 'rgba(111, 207, 151, 0.10)',
          textColor: '#219653'
        }
      case 'draft':
        return {
          bgColor: '#E0E0E0',
          textColor: '#677788'
        }
      case 'sent':
        return {
          bgColor: 'rgba(111, 207, 151, 0.10)',
          textColor: '#219653'
        }
      case 'scheduled':
        return {
          bgColor: '#FF8B021A',
          textColor: '#FF8B02'
        }
      case 'incomplete':
        return {
          bgColor: '#FFF3E0',
          textColor: '#FF9800'
        }
      default:
        return {}
    }
  },

  productTypeText({ productType, stream } = {}) {
    const type = stream ? 'Stream' : 'Item'
    return {
      'paid-content': 'Paid ' + type,
      'gated-content': 'Gated ' + type
    }[productType]
  },

  trim(str = '', length = 100) {
    if (!str) { return '' }
    if (str.length < length) {
      return str
    }
    return str.substring(0, length) + '...'
  },

  pluralize(number, unit) {
    return pluralize(unit, number, true)
  }
}

for (const key in FILTERS) {
  Vue.filter(key, FILTERS[key])
}
