import { get } from 'lodash'

export const state = () => ({
  pages: {},
  currentPageId: null,
  currentPage: {},
  editingContent: {},
  triggerSaveFlag: 0,
  openProductFlag: 0,
  closeProductFlag: 0
})

export const mutations = {
  setUsedContentItems: (state, contentItemIds) => {
    const page = state.pages[state.currentPageId] || {}
    if (!page.usedContentItems) {
      page.usedContentItems = []
    }
    page.usedContentItems = contentItemIds
    state.pages[state.currentPageId] = page
  },

  addUsedContentItem: (state, contentItemId) => {
    const page = state.pages[state.currentPageId] || {}
    if (!page.usedContentItems) {
      page.usedContentItems = []
    }
    page.usedContentItems.push(contentItemId)
    state.pages[state.currentPageId] = page
  },

  removeUsedContentItem: (state, contentItemId) => {
    const page = state.pages[state.currentPageId] || {}
    if (!page.usedContentItems) {
      page.usedContentItems = []
    }
    page.usedContentItems = page.usedContentItems.filter(item => item !== contentItemId)
    state.pages[state.currentPageId] = page
  },

  setSelectedPage: (state, pageId) => {
    state.currentPageId = pageId
    state.currentPage = state.pages[state.currentPageId]
  },

  setEditingContent: (state, editingContent) => {
    state.editingContent = editingContent
  },

  triggerSave: (state) => {
    state.triggerSaveFlag += 1
  },

  openProductSetting: (state) => {
    state.openProductFlag += 1
  },

  closeProductSetting: (state) => {
    state.closeProductFlag += 1
  }
}

export const getters = {
  pages(state) {
    return state.pages
  },
  currentPage(state) {
    return state.currentPage
  },
  editingContent(state) {
    return state.editingContent
  },
  usedContentItems(state) {
    return get(state, 'currentPage.usedContentItems', [])
  },
  triggerSaveFlag: state => state.triggerSaveFlag,
  openProductFlag: state => state.openProductFlag,
  closeProductFlag: state => state.closeProductFlag
}
