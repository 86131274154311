import { createApi } from 'unsplash-js'

export default (context, inject) => {
  const unsplash = createApi({
    // Don't forget to set your access token here!
    // See https://unsplash.com/developers
    accessKey: context.$config.unsplashAccessKey
  })
  inject('unsplash', unsplash)
}
