<template>
  <v-app>
    <v-main class="min-w-960">
      <v-layout column class="full-head-foot">
        <v-container class="pa-0">
          <v-toolbar class="elevation-0">
            <v-btn text to="/">
              <img src="/logo-eown.svg" class="w-50">
            </v-btn>
            <v-spacer />

            <v-btn
              v-for="(menuItem, mIdx) in menuItems"
              :key="mIdx"
              :to="menuItem.path"
              text
              class="ml-8"
            >
              {{ menuItem.title }}
            </v-btn>

            <v-btn
              depressed
              outlined
              large
              color="primary"
              class="ml-10"
              to="/login"
            >
              Log In
            </v-btn>
            <v-btn
              depressed
              large
              color="primary"
              class="ml-2"
            >
              Try for Free
            </v-btn>
          </v-toolbar>
        </v-container>

        <v-layout column class="overflow-y-auto">
          <nuxt />
          <v-layout class="flex-0-0 footer white--text">
            <v-container class="pa-0">
              <v-layout class="mt-2 py-15 border-white">
                <v-flex xs4 class="d-flex flex-column mr-15">
                  <div class="mb-6">
                    <img src="/logo-white.svg">
                  </div>
                  <p>Help real estate agents connect with their customers like you actually know them.</p>
                </v-flex>

                <v-flex xs2 class="d-flex flex-column">
                  <h4 class="mb-8">
                    PRODUCT
                  </h4>
                  <a class="mb-5 white--text opacity-80">Pricing</a>
                  <a class="mb-5 white--text opacity-80">
                    Use Cases
                    <v-icon
                      size="20"
                      color="white"
                      class="icon-expand"
                      @click="itemExpand.useCases = !itemExpand.useCases"
                    >
                      {{ itemExpand.useCases ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </a>
                  <v-layout v-show="itemExpand.useCases" column class="ml-3 group-childs">
                    <a class="mb-5 white--text opacity-80">Lead Generation</a>
                    <a class="mb-5 white--text opacity-80">Lead Nurturing</a>
                    <a class="mb-5 white--text opacity-80">Referrals</a>
                  </v-layout>
                </v-flex>

                <v-flex xs2 class="d-flex flex-column">
                  <h4 class="mb-8">
                    TEMPLATES
                  </h4>
                  <a class="mb-5 white--text opacity-80">Stream Templates</a>
                  <a class="mb-5 white--text opacity-80">
                    Item Templates
                    <v-icon
                      size="20"
                      color="white"
                      class="icon-expand"
                      @click="itemExpand.itemTemplates = !itemExpand.itemTemplates"
                    >
                      {{ itemExpand.itemTemplates ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </a>
                  <v-layout v-show="itemExpand.itemTemplates" column class="ml-3 group-childs">
                    <a class="mb-5 white--text opacity-80">Graphic Templates</a>
                    <a class="mb-5 white--text opacity-80">Form Templates</a>
                  </v-layout>
                  <a class="mb-5 white--text opacity-80">
                    Promotion Templates
                    <v-icon
                      size="20"
                      color="white"
                      class="icon-expand"
                      @click="itemExpand.promotionTemplates = !itemExpand.promotionTemplates"
                    >
                      {{ itemExpand.promotionTemplates ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </a>
                  <v-layout v-show="itemExpand.promotionTemplates" column class="ml-3 group-childs">
                    <a class="mb-5 white--text opacity-80">Facebook Templates</a>
                    <a class="mb-5 white--text opacity-80">Email Templates</a>
                  </v-layout>
                </v-flex>

                <v-flex xs2 class="d-flex flex-column">
                  <h4 class="mb-8">
                    LET US HELP YOU
                  </h4>
                  <a class="mb-5 white--text opacity-80">Contact Us</a>
                  <a class="mb-5 white--text opacity-80">Help Center</a>
                  <a class="mb-5 white--text opacity-80">FAQs</a>
                </v-flex>

                <v-flex xs2 class="d-flex flex-column">
                  <h4 class="mb-8">
                    GET TO KNOW US
                  </h4>
                  <a class="mb-5 white--text opacity-80">Our Blog</a>
                  <a class="mb-5 white--text opacity-80">About EOwn</a>
                  <a class="mb-5 white--text opacity-80">Terms and Conditions</a>
                  <a class="mb-5 white--text opacity-80">Privacy</a>
                  <v-layout class="mt-5">
                    <v-icon color="white" class="mr-2">
                      mdi-facebook
                    </v-icon>
                    <v-icon color="white" class="mr-2">
                      mdi-twitter
                    </v-icon>
                    <v-icon color="white">
                      mdi-linkedin
                    </v-icon>
                  </v-layout>
                </v-flex>
              </v-layout>
              <p class="mb-0 py-5">
                © 2021 EOwn Network All rights reserved.
              </p>
            </v-container>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    itemExpand: {
      useCases: false,
      itemTemplates: false,
      promotionTemplates: false
    },
    menuItems: [
      { title: 'About', path: '/' },
      { title: 'Solutions', path: '/' },
      { title: 'Pricing', path: '/' },
      { title: 'Blog', path: '/' },
      { title: 'Support', path: '/' }
    ]
  })
}
</script>

<style lang="scss">
.full-head-foot {
  background-color: #fff;
  height: 100vh;
  .footer {
    background: #303745;
    .border-white {
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);
    }
    .opacity-80 {
      opacity: .8;
    }
    .icon-expand::after {
      display: none;
    }
    .group-childs {
      // transition: all
    }
  }
}
</style>
