const ALLOWED_ROUTE_NAMES = [
  'streams-id',
  'streamSlug',
  'streamSlug-contentSlug',
  'membership',
  'processing-type'
]

const REQUIRED_SUBDOMAIN_ROUTE_NAMES = [
]

export default async ({ $axios, req, route, $config, redirect, store, error }) => {
  const host = req ? req.headers.host : ''
  const { MY_EOWN_HOST } = process.env

  if (host && host === MY_EOWN_HOST && REQUIRED_SUBDOMAIN_ROUTE_NAMES.includes(route.name)) {
    return error({
      statusCode: 404,
      message: 'Page not found'
    })
  }

  if (host && host !== MY_EOWN_HOST && host.includes(MY_EOWN_HOST)) {
    const subdomain = host.replace(MY_EOWN_HOST, '').replace(/^[.-]*|[.-]*$/g, '')

    if (REQUIRED_SUBDOMAIN_ROUTE_NAMES.includes(route.name) && !subdomain) {
      return error({
        statusCode: 404,
        message: 'Page not found'
      })
    }

    try {
      await $axios.get(`/users/${subdomain}/check`)
      store.commit('setUserSubdomain', subdomain)
      const { name, fullPath } = route
      if (!ALLOWED_ROUTE_NAMES.includes(name)) {
        const url = new URL(fullPath, $config.baseUrl)
        redirect(url.href) // redirect to the frontend url when the route with the subdomain is not allowed
      }
    } catch (e) {
      error({
        statusCode: 404,
        message: 'Page not found'
      })
    }
  }
}
