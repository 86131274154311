<template>
  <div>
    <e-confirm
      ref="price-confirm"
      :head-line="priceConfirmData.title"
      :head-text="priceConfirmData.message"
      no-text="No"
      yes-text="Confirm"
      @yes="
        () => {
          $helpers.get(priceConfirmData, 'yesAction', () => {})();
        }
      "
    />
    <e-confirm
      ref="payment-confirm"
      :head-line="paymentConfirmData.title"
      :head-text="paymentConfirmData.message"
      no-text="No"
      yes-text="Confirm"
      @yes="redirectPayment(paymentConfirmData.url)"
    />
  </div>
</template>

<script>
import { isObject, last } from 'lodash'
import { mapGetters } from 'vuex'
import MixinAuth from '../../mixins/auth'

export default {
  name: 'PricingPlanHandler',
  mixins: [MixinAuth],
  data() {
    return {
      paymentConfirmData: {},
      isSubmiting: false,
      selectedPrices: [],
      subscribeType: '',
      promotionCode: null,
      broadcastChannel: null
    }
  },
  computed: {
    priceConfirmData() {
      if (this.currentPricingKeys.length && !this.selectedPrices.length) {
        return {
          title: 'Are you sure to cancel the subscription?',
          message: 'Your current plan will be valid until the end of the billing period. You will get no refund.<br><a class="primary--text" target="_blank" href="#">Learn more</a>.',
          yesAction: () => {
            this.cancelSubscription()
          }
        }
      }
      if (this.selectedPrices.length < this.currentPricingKeys.length) {
        return {
          title: 'Are you sure to downgrade?',
          message: 'Your current plan will be valid until the end of the billing period. Then your account will be updated to new plan. You will be charge the price of new plan on the first day of new billing period.<br><a class="primary--text" target="_blank" href="#">Learn more</a>.',
          yesAction: () => {
            this.downgradeSubscription(this.selectedPrices)
          }
        }
      }
      return {}
    },
    ...mapGetters({
      planHandlerActions: 'user/planHandlerActions'
    })
  },
  watch: {
    isSubmiting() {
      this.$emit('onSubmiting', this.isSubmiting)
      if (this.isSubmiting) {
        this.$helpers.notifyInfo('Processing...')
      }
    },
    planHandlerActions(val) {
      if (val && val.length) {
        const { name, params } = last(val)
        if (this[name]) {
          this[name](...(params || []))
        }
      }
    }
  },
  mounted() {
    this.loadUserPricing()
    this.broadcastChannel = new BroadcastChannel('checkout')
    this.broadcastChannel.onmessage = ({ data }) => {
      if (data.status) {
        this.$helpers.notifySuccess('Your checkout is successful')
        this.reloadInfo()
      } else {
        this.$helpers.notifyError('Your checkout is failed')
        this.isSubmiting = false
      }
    }
  },
  beforeDestroy() {
    this.broadcastChannel.close()
  },
  methods: {
    async handle({ pricingKeys = [], subscribeType, promotionCode }, ignoreConfirm = false) {
      this.selectedPrices = pricingKeys.map(price => isObject(price) ? price : { id: price, quantity: 1 })
      this.subscribeType = subscribeType
      this.promotionCode = promotionCode

      if (Object.keys(this.priceConfirmData).length === 0) {
        await this.upgradeSubscription(this.selectedPrices)
      } else if (ignoreConfirm) {
        this.priceConfirmData.yesAction()
      } else {
        this.$refs['price-confirm'].clickHandle()
      }
    },
    async upgradeSubscription(price) {
      this.isSubmiting = true
      try {
        const data = await this.$axios.$post('/billing/subscribe', {
          price, subscribeType: this.subscribeType, promotionCode: this.promotionCode
        })
        if (data && data.status === 302) {
          if (data.confirm) {
            this.paymentConfirmData = {
              ...data.confirm,
              url: data.url
            }
            this.$refs['payment-confirm'].clickHandle()
          } else {
            this.redirectPayment(data.url)
          }
        } else {
          this.$helpers.notifySuccess('Your pricing plan is updated successfully')
        }
      } catch (error) {
        this.$helpers.notifyError(error)
      }
      this.reloadInfo()
    },
    async downgradeSubscription(pricingKeys, trial = false) {
      this.isSubmiting = true
      try {
        await this.$axios.$post('/billing/downgrade', { price: pricingKeys, trial, subscribeType: this.subscribeType })
        this.$helpers.notifySuccess('Your current plan will be valid until the end of the billing period')
      } catch (error) {
        this.$helpers.notifyError(error)
      }
      this.reloadInfo()
    },
    async cancelSubscription() {
      this.isSubmiting = true
      try {
        const data = await this.$axios.$post('/billing/unsubscribe')
        // eslint-disable-next-line no-console
        console.log(data)
        this.$helpers.notifySuccess('Your current plan will be valid until the end of the billing period')
      } catch (error) {
        this.$helpers.notifyError(error)
      }
      this.reloadInfo()
    },
    redirectPayment(url) {
      window.open(
        url,
        '_blank'
      )
    },
    reloadInfo() {
      setTimeout(() => {
        this.$nuxt.refresh()
        this.isSubmiting = false
        this.$emit('onReloadInfo')
        this.loadUserPricing(true)
      }, 3000)
    },
    async setupPaymentMethod() {
      try {
        const { url } = await this.$axios.$get('/billing/payment-method-setup-url')
        this.redirectPayment(url)
      } catch (error) {
        this.$helpers.notifyError(error)
      }
    }
  }
}
</script>

<style>
</style>
