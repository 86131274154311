<template>
  <div>
    <e-dialog
      :value="showPopup"
      content-class="popup-social-publish"
      max-width="350"
      @close="hide"
    >
      <v-layout column>
        <v-card color="primary" class="py-5 px-5 white--text text-h5 rounded-0">
          {{ selectedDatetime }}
        </v-card>
        <v-date-picker
          v-model="date"
          :disabled="disabled"
          full-width
          no-title
          :min="getMinTime"
        />
        <v-layout class="mx-10 mb-3" column>
          <v-text-field
            ref="timePicker"
            v-model="time"
            :disabled="disabled"
            label="Time"
            type="time"
            @input="onTimeChange"
          />

          <v-layout v-if="date && !greaterThanMin" class="error--text" align-start>
            <v-icon color="error">
              mdi-alert-circle-outline
            </v-icon>
            <span>Selected date must be greater than {{ displayMin }} </span>
          </v-layout>
        </v-layout>
      </v-layout>
      <template slot="actions">
        <v-spacer />
        <v-btn text color="primary" @click="hide">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="clear">
          Clear
        </v-btn>
        <v-btn color="primary" @click="save">
          OK
        </v-btn>
      </template>
    </e-dialog>
    <div @click="openPopup(true)">
      <slot />
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'EDatetimePicker',

  props: {
    value: {
      type: [Date, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: Function,
      default: null
    }
  },

  data: () => ({
    showPopup: false,
    date: null,
    time: null
  }),

  computed: {
    selectedDatetime() {
      if (this.getFinalDate) {
        const date = moment(this.getFinalDate)
        return date.format('llll')
      }
      return '--'
    },

    getFinalDate() {
      if (this.date) {
        let date
        if (this.time) {
          date = moment(`${this.date} ${this.time}`, 'YYYY-MM-DD HH:mm')
        } else {
          date = moment(this.date, 'YYYY-MM-DD')
        }

        return date.toDate()
      }

      return null
    },

    greaterThanMin() {
      if (this.getMinTime && this.getFinalDate) {
        const date = moment(this.getFinalDate)
        const minTime = moment(this.getMinTime, moment.ISO_8601)
        if (date < minTime) {
          return false
        }
      }

      return true
    },

    displayMin() {
      if (this.getMinTime) {
        const minTime = moment(this.getMinTime, moment.ISO_8601)
        return minTime.format('DD/MM/YYYY hh:mm A')
      }
      return null
    },

    getMinTime() {
      if (this.min) {
        return this.min()
      }

      return null
    }
  },

  watch: {
    value: {
      handler(date) {
        this.parseValue(date)
      },
      immediate: true
    }
  },

  created() {
    this.parseValue(this.value)
  },

  methods: {
    hide() {
      this.showPopup = false
    },

    parseValue(date) {
      if (date) {
        const parsedDate = moment(date)
        if (parsedDate.isValid()) {
          this.date = parsedDate.format('YYYY-MM-DD')
          this.time = parsedDate.format('HH:mm')
        }
      }
    },

    emitChange() {
      this.$emit('input', this.getFinalDate)
    },

    clear() {
      this.date = null
      this.time = null
    },

    save() {
      if (this.greaterThanMin) {
        this.hide()
        this.emitChange()
      }
    },

    openPopup(flag) {
      if (!this.disabled) { this.showPopup = flag }
    },

    onTimeChange() {
      if (this.time && !this.date) {
        this.date = moment().format('YYYY-MM-DD')
      }
    }
  }
}
</script>

<style lang="scss">

</style>
