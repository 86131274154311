<template>
  <v-layout :class="['notification-item', {unread: !notification.isRead}]">
    <v-avatar size="40" class="ma-3">
      <img :src="$helpers.getFilePreview($helpers.get(authUser, 'profile.avatar')) || require('~/assets/images/avatar.png')">
    </v-avatar>

    <v-layout class="py-2" column>
      <v-layout column>
        <v-layout>
          <span class="text-subtitle-2 font-weight-medium" @click.prevent="clickNotification">
            {{ title }}
          </span>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <a
                v-bind="attrs"
                class="read-marker d-flex align-center justify-center"
                v-on="on"
                @click.prevent="updateReadStatus(!notification.isRead)"
              >
                <v-icon v-if="!notification.isRead" size="10" color="primary">
                  mdi-checkbox-blank-circle
                </v-icon>
              </a>
            </template>
            <span>Mark as {{ notification.isRead ? 'unread' : 'read' }}</span>
          </v-tooltip>
        </v-layout>
        <span class="text-caption opacity-50 grey--text text--darken-4 mt-1">
          {{ notification.createdAt | fromNow }}
        </span>
      </v-layout>

      <div class="text-caption grey--text text--darken-4 mt-3">
        <v-layout v-if="notification.type === 'unpublishStream'" column>
          <v-layout v-if="$helpers.get(notification, 'variables.streamIds.length')" column>
            <span>Content streams still published:</span>
            <nuxt-link :to="{name: 'manage-analytics-streams'}" class="primary--text">
              {{ $helpers.get(notification, 'variables.streamIds', []) | publishedStreamText }}
            </nuxt-link>
          </v-layout>
          <v-layout v-if="$helpers.get(notification, 'variables.singlePropertyPageIds.length')" column class="mt-2">
            <span>Single property pages still published:</span>
            <nuxt-link :to="{name: 'manage-analytics-streams'}" class="primary--text">
              {{ $helpers.get(notification, 'variables.singlePropertyPageIds', []) | publishedStreamText }}
            </nuxt-link>
          </v-layout>
        </v-layout>
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  filters: {
    publishedStreamText(streams = []) {
      const clonedStreams = cloneDeep(streams)
      const firstStreams = clonedStreams.splice(0, 2)
      return firstStreams.map(item => item.name).join(', ') + (clonedStreams.length ? ` and ${clonedStreams.length} others` : '')
    }
  },

  props: {
    notification: {
      type: Object,
      required: true
    },
    showFull: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    title() {
      return {
        unpublishStream: 'Your published content streams and published single property websites have been unpublished',
        emailLimitReached: 'You have reached your email limit, please upgrade your plan to send more emails'
      }[this.notification.type] || ''
    },

    authUser() {
      return this.$auth.user
    }
  },

  methods: {
    async updateReadStatus(isRead = true) {
      try {
        await this.$axios.$post('/notification/mark-read', {
          notificationId: this.notification._id,
          isRead
        })
        this.$store.commit('notification/updateListItem', {
          id: this.notification._id,
          data: {
            isRead
          }
        })
      } catch (error) {}
    },

    clickNotification() {
      if (this.notification.type === 'emailLimitReached') {
        this.$nuxt.$emit('permission', {
          recommendedRole: 'emailMarketing',
          message: 'You have reached your email limit, please upgrade your plan to send more emails'
        })
      }
      if (!this.notification.isRead) {
        this.updateReadStatus(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-item {
  cursor: pointer;
  padding: 1em 0;
  padding-right: 0.5em;

  &:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  .read-marker {
    width: 26px;
    height: 26px;
    border: 1px solid #ddd;
    border-radius: 20px;
    text-align: center;
    flex: 0 0 26px;
  }
}
</style>
