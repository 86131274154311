<template>
  <div>
    <div
      v-if="$slots.default"
      type="button"
      class="d-flex"
      @click.prevent="clickHandle"
    >
      <slot name="default" />
    </div>

    <e-dialog
      :value="value"
      :title-line="false"
      :max-width="maxWidth"
      content-class="e-prompt"
    >
      <v-layout column class="mx-7 mt-3 mb-6 align-center">
        <img :src="'information.gif' | assetImageUrl" alt="" class="h-50 mx-auto">

        <h3 v-if="title" class="mb-4 text-h5 text-center">
          {{ title }}
        </h3>

        <p class="text-center" v-html="message" />

        <v-layout class="justify-center w-full">
          <v-flex v-if="noText" class="px-2">
            <v-btn
              outlined
              height="36"
              min-width="158"
              :color="noBtnColor"
              :class="`rounded-md w-full ${noBtnColor ? '' : 'v-btn-default'}`"
              :data-cy="noBtnDataCy"
              @click="handleNo"
            >
              {{ noText }}
            </v-btn>
          </v-flex>
          <v-flex v-if="yesText" class="px-2">
            <v-btn
              depressed
              height="36"
              min-width="158"
              color="primary"
              class="rounded-md w-full"
              :data-cy="yesBtnDataCy"
              @click="handleYes"
            >
              {{ yesText }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-layout>
    </e-dialog>
  </div>
</template>

<script>

export default {
  name: 'EConfirmation',

  props: {
    maxWidth: {
      type: String,
      default: '512px'
    },
    title: {
      type: String,
      default: 'Confirm!'
    },
    message: {
      type: String,
      default: 'Are you sure you want to continue?'
    },
    yesText: {
      type: String,
      default: 'Yes'
    },
    noText: {
      type: String,
      default: 'No'
    },
    yesBtnDataCy: {
      type: String,
      default: ''
    },
    noBtnDataCy: {
      type: String,
      default: ''
    },
    noBtnColor: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    value: false,
    resolve: null,
    reject: null
  }),

  mounted() {
    window.addEventListener('keydown', this.handleKeydown)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown)
  },

  methods: {
    clickHandle(e) {
      this.value = true
      e.stopPropagation()
    },
    open() {
      this.value = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleNo() {
      if (this.reject && typeof this.reject === 'function') {
        this.reject(false)
      }
      this.$emit('canceled')
      this.value = false
    },
    handleYes() {
      if (this.resolve && typeof this.resolve === 'function') {
        this.resolve(true)
      }
      this.$emit('confirmed')
      this.value = false
    },
    handleKeydown(e) {
      if (this.value && e.key === 'Enter') {
        this.handleYes(e)
      }
    }
  }
}
</script>
