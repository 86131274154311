<template>
  <v-chip
    :color="statusColor.bgColor"
    class="text-caption font-weight-medium"
    small
  >
    <span :style="`color: ${statusColor.textColor}`">
      {{ status | capitalize }}
    </span>
  </v-chip>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    statusColor() {
      return this.$options.filters.statusColor(this.status)
    }
  }
}
</script>

<style>

</style>
