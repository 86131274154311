<template>
  <v-layout column>
    <v-layout
      v-if="!list || !list.length"
      justify-center
      align-center
      class="py-20"
    >
      No notification
    </v-layout>

    <v-layout v-else column>
      <div class="grey lighten-4 text-right">
        <v-btn text class="text-body-2" @click="markAllAsRead">
          Mark all as read
        </v-btn>
      </div>

      <v-layout
        id="notification-list"
        class="notification-list"
        column
        :style="listStyle"
      >
        <Detail
          v-for="item in list"
          :key="item._id"
          class="flex-grow-0"
          :notification="item"
          :show-full="false"
        />
        <v-card v-scroll:#notification-list="onScroll" />
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import Detail from './detail.vue'

export default {
  components: {
    Detail
  },

  props: {
    maxHeight: {
      type: String,
      default: 'unset'
    }
  },

  computed: {
    ...mapState({
      list: state => state.notification.list,
      unreadCount: state => state.notification.unreadCount
    }),

    listStyle() {
      return {
        'max-height': this.maxHeight
      }
    }
  },

  watch: {
    unreadCount(val, old) {
      if (val > old) {
        this.$store.commit('notification/resetList')
      }
    },
    list() {
      if (!this.list) {
        this.$api.notification.getNotification()
      }
    }
  },

  methods: {
    async markAllAsRead() {
      try {
        await this.$axios.$post('/notification/mark-read', {
          isRead: true
        })
        this.$store.commit('notification/markAllAsRead')
        this.$store.commit('notification/setUnreadCount', 0)
      } catch (error) {}
    },

    onScroll(e) {
      const { offsetHeight, scrollTop, scrollHeight } = e.target
      if (offsetHeight + scrollTop >= scrollHeight) {
        this.$api.notification.getNotification()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-list {
  overflow: auto;
}
</style>
