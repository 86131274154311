<template>
  <v-layout
    column
    justify-center
    align-center
    class="px-20 pb-10 pt-0 text-center"
  >
    <span class="text-h5">
      Promote your products to find your first customer.
    </span>
    <span class="mt-3">
      Capture your audience to your database by incorporating forms, quizzes, property listings, lead magnets, or advertising graphics into your content streams
    </span>
    <v-img :src="image" class="rounded-lg mt-10" />
    <v-btn
      class="mt-5"
      color="primary"
      href="https://stream-studio.eown.io/streams/64579f11c5505c0009a74f82"
      target="blank"
      elevation="0"
      large
    >
      Get tips on how to generate sales on your digital product
    </v-btn>
    <span class="mt-5">
      Or
      <a href="https://stream-studio.eown.io/streams/64579faac5505c0009a74f86" class="primary--text" target="blank">
        learn more about the Digital Product dashboard.
      </a>
    </span>
  </v-layout>
</template>

<script>
import image from '@/assets/images/getting-started/stream-first-lead.png'

export default {
  data: () => ({
    image
  })
}
</script>

<style>

</style>
