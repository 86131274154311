<template>
  <v-layout>
    <v-flex xs4 class="primary d-flex align-center rounded-xl">
      <img src="~/assets/images/pricing/email-marketing.svg" class="left-image">
    </v-flex>
    <v-flex xs8>
      <v-card-text class="pa-20 pr-5 pb-0">
        <div class="text-h5 font-weight-bold">
          EOwn Email Marketing
        </div>
        <p class="text-subtitle-2">
          Unlimited Subscribers
        </p>
        <div class="text-body-2">
          <p>
            Only pay for what you use: purchase an additional {{ subscribeType === 'monthly' ? 'monthly' : 'yearly' }} volume of every 10,000 emails for {{ value[`${subscribeType}Price`] | formatCurrency }}.
          </p>
          <p>
            Email marketing features
            <v-layout>
              <v-flex xs6>
                <ul>
                  <li>Intuitive newsletter editor</li>
                  <li>Beautiful newsletter templates and layouts</li>
                  <li>Email broadcasting</li>
                  <li>Automated email workflow</li>
                </ul>
              </v-flex>
              <v-flex xs6>
                <ul>
                  <li>Integration with form and e-commerce flow</li>
                  <li>Audience segmentation</li>
                  <li>Robust email analytics</li>
                  <li>Data export</li>
                </ul>
              </v-flex>
            </v-layout>
          </p>
        </div>
        <EmailMarketingSlider
          :value="value.quantity"
          :subscribe-type="subscribeType"
          :pricing="value"
          @input="emitChange"
        />
      </v-card-text>
    </v-flex>
  </v-layout>
</template>

<script>
import EmailMarketingSlider from './email-marketing-slider.vue'

export default {
  components: {
    EmailMarketingSlider
  },

  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    subscribeType: {
      type: String,
      default: 'monthly'
    }
  },

  methods: {
    emitChange(e) {
      this.$emit('input', { ...this.value, quantity: e })
    }
  }
}
</script>

<style lang="scss" scoped>
.left-image {
  width: 100%;
  margin-left: 8%;
}
</style>
