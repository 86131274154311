<template>
  <v-chip-group
    v-if="Array.isArray(tags) && tags.length"
    :show-arrows="scrollable"
    :column="!scrollable"
    class="e-tags"
  >
    <v-chip
      v-for="(tag, tIndex) in tags"
      :key="tIndex"
      :color="tagColor"
      :text-color="color"
      small
      :class="tags.length === 1 ? 'mx-0' : ''"
      class="px-2 py-1 h-auto font-weight-medium leading-1p333"
      @click="$emit('onClick', tag)"
    >
      {{ (tag && tag.name) ? tag.name : tag }}
      <v-icon
        v-if="removeable"
        small
        class="ml-1"
        @click="$emit('remove', tag)"
      >
        close
      </v-icon>
    </v-chip>
  </v-chip-group>
</template>

<script>
export default {
  name: 'ETags',

  props: {
    items: {
      type: Array,
      default: () => []
    },
    item: {
      type: [String, Object],
      default: () => ({})
    },
    color: {
      type: String,
      validator: v => ['primary', 'secondary', 'accent'].includes(v),
      default: 'primary'
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    removeable: Boolean
  },

  computed: {
    tags() {
      if ((this.item && this.item.name) || typeof this.item === 'string') {
        return [this.item]
      }
      return this.items
    },
    tagColor() {
      switch (this.color) {
        case 'primary':
          return '#DCEEFF'
        case 'secondary':
          return '#D9D9D9'
        case 'accent':
          return '#F0F0F0'
        default:
          return ''
      }
    }
  }
}
</script>
