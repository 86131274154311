<template>
  <v-layout column>
    <div>
      <v-checkbox v-model="enabled" hide-details>
        <template #label>
          <v-layout column class="black--text">
            <div class="text-subtitle-1">
              {{ subscribeTypeText }} email volume (marketing. & transactional)
            </div>
            <div class="text-caption">
              Price based on {{ subscribeTypeText | lowerCase }} email volume.
            </div>
          </v-layout>
        </template>
      </v-checkbox>
    </div>

    <v-slider
      v-model="quantity"
      class="my-5 text-caption"
      :tick-labels="quantityLabels"
      :min="1"
      :max="10"
      :disabled="!enabled"
      step="1"
      ticks="always"
      tick-size="5"
      @input="emitChange"
    />
    <v-divider />
    <div class="mt-5 text-right">
      <span class="text-h6">{{ estimatedPrice | formatCurrency }}</span>
      <span> / {{ numberOfEmails | formatNumber }} emails</span>
      <span> / month</span>
    </div>
    <slot name="default" />
  </v-layout>
</template>

<script>
export default {
  props: {
    subscribeType: {
      type: String,
      default: 'monthly'
    },

    value: {
      type: Number,
      default: 1
    },

    pricing: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    quantityLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => `${i * 10}k`),
    enabled: false,
    quantity: 1
  }),

  computed: {
    subscribeTypeText() {
      return this.subscribeType === 'monthly' ? 'Monthly' : 'Yearly'
    },

    estimatedPrice() {
      if (!this.quantity || !this.enabled) { return 0 }
      return this.basePrice + (this.quantity - 1) * (this.additionalPrice)
    },
    basePrice() {
      return (this.subscribeType === 'monthly' ? this.pricing.monthlyPrice : this.pricing.annualPrice) || 0
    },
    additionalPrice() {
      return (this.subscribeType === 'monthly' ? this.pricing.monthlyAdditionalPrice : this.pricing.annualAdditionalPrice) || 0
    },
    numberOfEmails() {
      if (!this.quantity || !this.enabled) { return 0 }
      return this.quantity * 10000
    }
  },

  watch: {
    enabled() {
      this.emitChange(this.enabled ? 1 : 0)
    }
  },

  created() {
    this.enabled = this.value > 0
    this.quantity = this.value
  },

  methods: {
    emitChange(e) {
      this.$emit('input', this.enabled ? this.quantity : 0)
    }
  }
}
</script>

<style>

</style>
