const assetImageUrl = imgUrl => require(`~/assets/images/${imgUrl.startsWith('/') ? imgUrl.substring(1) : imgUrl}`)

const getName = (fileUrl) => {
  if (!fileUrl) {
    return ''
  }
  const arr = fileUrl.split('/')
  const fileName = arr[arr.length - 1]
  const prefixRe = new RegExp('^eown-[0-9a-f]{8}-[0-9a-f]{4}-1[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}-', 'gm')
  return fileName.replace(prefixRe, '')
}

const getFileType = (fileUrl) => {
  if (!fileUrl) {
    return ''
  }
  const arr = fileUrl.split('.')
  return arr[arr.length - 1]
}

const getDocType = (fileUrl) => {
  if (!fileUrl) {
    return ''
  }
  const fileType = getFileType(fileUrl)
  let docType = 'other'
  if (fileType === 'pdf') {
    docType = 'pdf'
  } else if (fileType === 'doc' || fileType === 'docx') {
    docType = 'word'
  } else if (fileType === 'xls' || fileType === 'xlsx' || fileType === 'csv') {
    docType = 'excel'
  } else if (fileType === 'ppt' || fileType === 'pptx') {
    docType = 'powerpoint'
  // } else if (fileType === 'zip' || fileType === 'rar') {
  //   docType = 'other'
  // } else if (fileType === 'mp3' || fileType === 'wav') {
  //   docType = 'other'
  } else if (fileType === 'mp4' || fileType === 'avi' || fileType === 'mov') {
    docType = 'video'
  } else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif') {
    docType = 'image'
  }
  return docType
}

const getFileIcon = (fileUrl) => {
  if (!fileUrl) {
    return ''
  }
  const docType = getDocType(fileUrl)
  return assetImageUrl(`document-types/${docType}.svg`)
}

function checkFileSize(file) {
  const maxSize = 25 * 1024 * 1024 // 25MB in bytes
  if (file.size > maxSize) {
    return false // File size exceeds 25MB
  }
  return true // File size is within the limit
}

export {
  assetImageUrl,
  getName,
  getFileType,
  getDocType,
  getFileIcon,
  checkFileSize
}
