<template>
  <e-dialog
    :value="value"
    :title-line="false"
    :max-width="version === 'v2' ? '650' : maxWidth"
    dense
    content-class="e-prompt"
    @close="no"
  >
    <template v-if="version === 'v1'">
      <v-layout v-if="!$slots.content" column class="mx-5">
        <v-icon size="70" color="warning" class="mb-2">
          info
        </v-icon>
        <p v-if="title" class="mb-2 text-body-1 font-weight-bold text-center">
          {{ title }}
        </p>
        <span class="text-center" v-html="message" />
      </v-layout>
      <slot name="content" />

      <v-layout class="mx-6 mt-9 mb-7">
        <v-flex v-if="noText" class="d-flex justify-end mr-2">
          <v-btn
            outlined
            :color="noBtn.color"
            :class="noBtn.class"
            :data-cy="datacyNo"
            @click="no"
          >
            {{ noText }}
          </v-btn>
        </v-flex>
        <v-flex v-if="yesText">
          <v-btn
            depressed
            :color="yesBtn.color"
            :class="yesBtn.class"
            :data-cy="datacyYes"
            @click="yes"
          >
            {{ yesText }}
          </v-btn>
        </v-flex>
      </v-layout>
    </template>

    <template v-if="version === 'v2'">
      <div class="h-100">
        <img :src="'information.gif' | assetImageUrl" alt="" class="h-full mx-auto">
      </div>

      <v-layout v-if="!$slots.content" column class="mx-5">
        <p v-if="title" class="mb-2 text-body-1 font-weight-bold text-center">
          {{ title }}
        </p>
        <span class="text-center" v-html="message" />
      </v-layout>
      <slot name="content" />

      <v-layout class="mx-5 mt-6 mb-10 justify-center">
        <v-btn
          v-if="noText"
          large
          outlined
          color="primary"
          min-width="158"
          class="mx-2 rounded"
          @click="no"
        >
          {{ noText }}
        </v-btn>
        <v-btn
          v-if="yesText"
          large
          depressed
          color="primary"
          min-width="158"
          class="mx-2 rounded"
          @click="yes"
        >
          {{ yesText }}
        </v-btn>
      </v-layout>
    </template>
  </e-dialog>
</template>

<script>

export default {
  name: 'EPrompt',

  props: {
    version: {
      type: String,
      default: 'v1'
    },
    maxWidth: {
      type: String,
      default: '512px'
    },
    title: {
      type: String,
      default: 'Leave this page?'
    },
    message: {
      type: String,
      default: 'Changes your made may not be saved.'
    },
    yesText: {
      type: String,
      default: 'Leave'
    },
    datacyYes: {
      type: String,
      default: ''
    },
    datacyNo: {
      type: String,
      default: ''
    },
    yesBtn: {
      type: Object,
      default: () => ({
        color: 'primary',
        class: 'w-full'
      })
    },
    noText: {
      type: String,
      default: 'Cancel'
    },
    noBtn: {
      type: Object,
      default: () => ({
        color: 'primary',
        class: 'w-full'
      })
    }
  },

  data: () => ({
    resolve: null,
    reject: null,
    value: false
  }),

  methods: {
    open() {
      this.value = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    no() {
      this.reject(false)
      this.value = false
    },
    yes() {
      this.resolve(true)
      this.value = false
    }
  }
}
</script>
