<template>
  <v-dialog
    v-model="showPopup"
    :max-width="maxWidth"
  >
    <v-card v-if="recommendedRoleData && recommendedRoleData.name" class="wrap-content position-relative">
      <div class="position-absolute btn-close">
        <v-icon color="gray" @click="showPopup = false">
          close
        </v-icon>
      </div>

      <v-card-text class="flex pa-5">
        <v-alert
          class="mt-5 mx-2 py-3"
          dense
          color="#FFDCDC"
          rounded="lg"
        >
          <v-layout align-center>
            <v-icon color="red">
              mdi-alert
            </v-icon>
            <div class="red--text text--darken-1 text-subtitle-2 ml-3" v-html="message" />
          </v-layout>
        </v-alert>
        <v-row class="ma-0 pa-0">
          <v-col v-if="pricingData.image" cols="5" class="position-relative">
            <v-img :src="pricingData.image" />
            <v-layout column class="position-absolute recommended-plan white--text px-5 py-2 mt-5">
              <span class="text-caption">We recommend the plan</span>
              <span class="text-h5 font-weight-black">
                Unlimited
              </span>
              <span class="text-h5 font-weight-black">
                {{ pricingData.name }}
              </span>
            </v-layout>
          </v-col>
          <v-col v-if="pricingData.leftSide" cols="5" class="position-relative">
            <v-flex class="primary d-flex align-center rounded-xl py-10">
              <img src="~/assets/images/pricing/email-marketing.svg" class="w-full ml-5">
            </v-flex>
          </v-col>
          <v-col cols="7" class="d-flex grey--text text--darken-4 text-caption align-center">
            <v-layout column>
              <div class="text-subtitle-1 my-5">
                {{ pricingData.title }}
              </div>
              <div v-if="pricingData.subtitle" class="font-weight-bold mb-2">
                {{ pricingData.subtitle }}
              </div>
              <div v-if="pricingData.description" v-html="pricingData.description" />
              <template v-if="Array.isArray(pricingData.features)">
                <ul class="mb-5 leading-1p5">
                  <li v-for="(feature, idx) in pricingData.features" :key="`feature-${idx}`">
                    {{ feature }}
                  </li>
                </ul>
              </template>
              <template v-else>
                <v-layout>
                  <ul v-if="pricingData.features.left" class="mb-5 leading-1p5">
                    <li v-for="(feature, idx) in pricingData.features.left" :key="`feature-left-${idx}`">
                      {{ feature }}
                    </li>
                  </ul>
                  <ul v-if="pricingData.features.right" class="mb-5 leading-1p5">
                    <li v-for="(feature, idx) in pricingData.features.right" :key="`feature-right-${idx}`">
                      {{ feature }}
                    </li>
                  </ul>
                </v-layout>
              </template>
              <EmailMarketingSlider
                v-if="recommendedRoleData.key === 'emailMarketing'"
                v-model="recommendedRoleData.quantity"
                :pricing="recommendedRoleData"
              >
                <template #default>
                  <v-layout justify-space-between align-center>
                    <span>
                      Your current email volume is:
                    </span>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      :loading="$store.getters.isRequesting"
                      rounded
                      elevation="0"
                      class="px-8"
                      :disabled="!recommendedRoleData.quantity"
                      @click="upgradePlan"
                    >
                      Upgrade
                    </v-btn>
                  </v-layout>
                </template>
              </EmailMarketingSlider>
              <v-layout v-else class="flex-grow-0">
                <div class="mr-10">
                  <span v-if="discountPercent" class="text-decoration-line-through text-h6 grey--text">{{ recommendedRoleData.originPrice | formatCurrency }}</span>
                  <span class="text-h5">{{ chargePrice | formatCurrency }}</span>
                  <span>/{{ userSubscribeType === 'annual' ? 'year' : 'month' }}</span>
                </div>
                <v-btn
                  color="primary"
                  :loading="$store.getters.isRequesting"
                  rounded
                  elevation="0"
                  class="px-8"
                  @click="upgradePlan"
                >
                  Upgrade
                </v-btn>
              </v-layout>
              <span v-if="discountText" class="mt-2">
                {{ discountText }}
              </span>
              <a
                class="primary--text mt-2"
                href="javascript:void(0)"
                @click="openPricingPopup"
              >
                View Full Pricing plan
              </a>
            </v-layout>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-if="!recommendedRole" class="wrap-content position-relative">
      <v-card-title class="flex-0-0 py-7 justify-center wrap-card-title">
        <img src="~/assets/images/limit-permission.png">
      </v-card-title>
      <div class="position-absolute btn-close">
        <v-icon color="white" @click="showPopup = false">
          close
        </v-icon>
      </div>

      <v-card-text class="flex">
        <h3 class="mt-8 text-title grey--text text--darken-4 text-center">
          This action cannot be done
        </h3>
        <pre class="mx-15 mt-4 mb-1 text-body-2 secondary--text text-center" v-html="message" />
      </v-card-text>

      <v-card-actions class="flex-0-0 pt-0 pb-7">
        <v-layout column>
          <v-btn
            depressed
            nuxt
            color="primary"
            width="183"
            class="mx-auto mb-2"
            @click="goToSignUp"
          >
            Sign Up
          </v-btn>
          <a href="https://eown.io/pricing/" class="mx-auto text-subtitle-2">Learn more</a>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get, compact, pick } from 'lodash'
import MixinAuth from '../../../mixins/auth'
import EmailMarketingSlider from '@/components/billing/email-marketing-slider.vue'

export default {
  name: 'PopupLimitPermission',

  components: {
    EmailMarketingSlider
  },

  mixins: [MixinAuth],

  props: {
    maxWidth: {
      type: String,
      default: '800px'
    }
  },

  data: () => ({
    showPopup: false,
    message: '',
    userPricing: null,
    recommendedRoleData: {},
    recommendedRole: null
  }),

  computed: {
    pricingData() {
      const features = compact(get(this.recommendedRoleData, 'features', '').split('\n'))
      const commonData = pick(this.recommendedRoleData, ['originPrice', 'discountPercentConfig'])
      return {
        contentStreams: {
          ...commonData,
          image: '/images/unlimited-content-streams.png',
          name: get(this.recommendedRoleData, 'name').replace('Unlimited', ''),
          title: get(this.recommendedRoleData, 'title') || 'You’ll get Unlimited content streams and premium content templates to generate Unlimited leads',
          features: features.length ? features : [
            'Unlimited content streams to convert to content destinations, landing pages, blog hub',
            'Unlimited gated contents and forms',
            'Unlimited leads and visitors',
            'Access to 1000+ Premium customizable content streams, graphics, forms, and article templates',
            'Access to 1000+ Premium social posts, social ads, emails, and text messages',
            'Hide EOwn branding'
          ]
        },

        listingMarketing: {
          ...commonData,
          image: '/images/unlimited-listing-marketing.png',
          name: get(this.recommendedRoleData, 'name').replace('Unlimited', ''),
          title: get(this.recommendedRoleData, 'title') || 'You’ll get unlimited high-converting property websites to collect unlimited seller and buyer leads.',
          features: features.length ? features : [
            'Unlimited Property Listings to create curated property websites, property alert newsletters, or market properties with other content resources',
            'Unlimited Single Property Websites to showcase a dedicated property effectively'
          ]
        },

        digitalProducts: {
          ...commonData,
          image: get(this.recommendedRoleData, 'title') || '/images/unlimited-digital-products.png',
          name: get(this.recommendedRoleData, 'name').replace('Unlimited', ''),
          title: 'You’ll get unlimited high-converting checkout experiences to monetize your content.',
          features: features.length ? features : [
            'Host Unlimited Products with sales pages, checkout pages, and product hosting',
            'Templates for digital products',
            'Manage products, incomes, and customers'
          ]
        },

        emailMarketing: {
          ...commonData,
          leftSide: {
            background: 'primary',
            image: '~/assets/images/pricing/email-marketing.svg'
          },
          name: get(this.recommendedRoleData, 'name').replace('Unlimited', ''),
          ...(this.currentPricingKeys.includes('emailMarketing') ? {
            title: `Increase ${this.userSubscribeType === 'monthly' ? 'Monthly' : 'Yealy'} Email Volumn`,
            features: []
          } : {
            title: 'EOwn Email Marketing',
            subtitle: 'Unlimited Subscribers',
            description: `
              <div class="mb-2">Only pay for what you use: purchase an additional monthly volume of every 10,000 emails for $10.</div>
              <div class="mb-2">Email marketing features</div>
            `,
            features: {
              left: [
                'Intuitive newsletter editor',
                'Beautiful newsletter templates and layouts',
                'Email broadcasting',
                'Automated email workflow'
              ],
              right: [
                'Integration with form and e-commerce flow',
                'Audience segmentation',
                'Robust email analytics',
                'Data export'
              ]
            }
          })
        }
      }[this.recommendedRole] || {}
    },

    discountText() {
      if (this.discountPercent) {
        return `You are qualified for a ${this.discountPercent}% discount because you are currently subscribed to “${this.currentPricingName}”`
      }
      return ''
    },

    expectedPlans() {
      return compact([...this.currentPricingKeys, this.recommendedRole])
    },

    discountPercent() {
      const mainPlans = this.expectedPlans.filter(plan => get(this.pricingConstant, 'mainRoles', []).includes(plan))
      return get(this.recommendedRoleData, 'discountPercentConfig', {})[mainPlans.length] || 0
    },

    chargePrice() {
      return this.pricingData.originPrice - (this.discountPercent * this.pricingData.originPrice / 100)
    }
  },

  created() {
    this.$nuxt.$off('permission')
    this.$nuxt.$on('permission', async ({ message, recommendedRole }) => {
      this.showPopup = true
      this.message = message
      this.recommendedRole = recommendedRole
      this.userPricing = null
      await this.loadUserPricing(true)
      this.recommendedRoleData = this.userPricing.find(pricing => pricing.key === recommendedRole) || {}
    })
  },

  methods: {
    openPricingPopup() {
      this.showPopup = false
      this.$nuxt.$emit('pricing/openPopup')
    },

    goToSignUp() {
      this.showPopup = false
      this.$router.push('/sign-up')
    },

    upgradePlan() {
      const pricingKeys = this.userPricing.filter(pricing => pricing.selected || pricing.key === this.recommendedRoleData.key).map((pricing) => {
        return pricing.key === this.recommendedRoleData.key ? {
          id: this.recommendedRoleData.key,
          quantity: this.recommendedRoleData.quantity || 1
        } : {
          id: pricing.key,
          quantity: pricing.quantity || 1
        }
      })
      this.$store.commit('user/addPlanHandlerAction', {
        name: 'handle',
        params: [{ pricingKeys }, true]
      })
      this.showPopup = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-card-title {
  background: rgba(239, 154, 154, 0.9);
  word-break: unset;
}
.btn-close {
  top: 16px;
  right: 16px;
}
.text-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.001em;
}
.recommended-plan {
  top: 0;
}
</style>
