<template>
  <v-layout>
    <slot name="opener" :open-modal="openModal" />
    <e-dialog
      v-model="showModal"
      :title="title"
      :max-width="maxWidth"
    >
      <v-form ref="form" class="pa-3">
        <slot name="content" :save-data="saveData" :form-data="savedData" />
      </v-form>

      <template #actions>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="cancel()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="save()"
        >
          Save
        </v-btn>
      </template>
    </e-dialog>
  </v-layout>
</template>

<script>
export default {
  name: 'EModalInput',
  props: {
    title: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: [String, Number],
      default: '400'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    showModal: false,
    savedData: {}
  }),
  methods: {
    resetSavedData() {
      this.savedData = { ...this.formData }
    },
    openModal() {
      if (this.readonly) { return }
      this.resetSavedData()
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
    cancel() {
      this.$emit('cancel')
      this.closeModal()
    },
    save() {
      if (!this.$refs.form.validate() || this.readonly) { return }

      this.$emit('save', {
        ...this.formData,
        ...this.savedData
      })
      this.closeModal()
    },
    saveData(field, value) {
      this.savedData[field] = value
    }
  }
}
</script>

<style>

</style>
