<template>
  <v-select
    v-model="dataValue"
    :items="periods"
    outlined
    dense
    background-color="white"
  />
</template>

<script>
export default {
  name: 'PeriodDropdown',

  props: {
    value: {
      type: String,
      default: 'this_week'
    }
  },

  data: () => ({
    periods: [
      {
        text: 'All time',
        value: 'all_time'
      },
      {
        text: 'This week',
        value: 'this_week'
      },
      {
        text: 'This month',
        value: 'this_month'
      },
      {
        text: 'This quarter',
        value: 'this_quarter'
      },
      {
        text: 'This year',
        value: 'this_year'
      },
      {
        text: 'Last year',
        value: 'last_year'
      }
    ],
    dataValue: 'this_week'
  }),

  watch: {
    value(val) {
      this.dataValue = val
    },

    dataValue(val) {
      this.$emit('input', val)
      this.$emit('change', val)
    }
  },

  created() {
    this.dataValue = this.value
  }
}
</script>

<style>
</style>
