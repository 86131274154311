import { watch } from '@vue/composition-api'
import { useActiveElement, useMouseInElement } from '@vueuse/core'
import { get } from 'lodash'

let currentActiveFrame, isOnClick

const isTouchDevice = () => {
  return ('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0)
}

const onWindowClick = () => {
  if (isOnClick) { return }
  isOnClick = true
  window.addEventListener('click', () => {
    currentActiveFrame = null
  })
}

export const onIframeClick = (el) => {
  if (el.dataset.isOnClick) { return }
  el.dataset.isOnClick = true
  el.blur()

  let isActiveElement = false
  let blurTimeout, clickTimeout

  try {
    const mouseInElement = useMouseInElement(el)
    const activeElement = useActiveElement()
    const triggerClick = () => {
      clearTimeout(clickTimeout)
      clickTimeout = setTimeout(() => {
        if (currentActiveFrame !== el) {
          el.click()
          currentActiveFrame = el
        }
      }, 250)
    }
    const triggerDelayBlur = () => {
      blurTimeout = setTimeout(() => {
        el.blur()
      }, 500)
    }

    watch(mouseInElement.isOutside, (isOutside) => {
      if (isOutside) {
        el.blur()
        if (isActiveElement) {
          isActiveElement = false
          currentActiveFrame = null
        }
      }
    })

    watch(activeElement, (activeEl) => {
      clearTimeout(blurTimeout)
      isActiveElement = (activeEl === el)
      if (isActiveElement) {
        triggerClick()
        if (isTouchDevice()) {
          triggerDelayBlur()
        }
      }
    })

    onWindowClick()
  } catch (error) {
    console.log(error)
  }
}

export default {
  bind: (el, binding) => {
    if (get(binding, 'value.enabled', true)) {
      onIframeClick(el)
    }
  }
}
