<template>
  <v-layout
    column
    justify-center
    align-center
    class="px-20 pb-10 pt-0 text-center"
  >
    <span class="text-h5">
      You can turn any content streams or items into revenue-generating products by gating them with a beautiful checkout page.
    </span>
    <span class="mt-3">
      To turn a content stream into a digital product, simply enable its "Paid" setting. This will prompt you to set up a sales page and checkout page to restrict access to the content stream.
    </span>
    <iframe
      class="mt-5 grey lighten-3"
      src="https://capture.dropbox.com/embed/pfOFMBFEmkBKyGlR?source=copy-embed"
      width="560"
      height="315"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
    <v-btn
      class="mt-5"
      color="primary"
      href="https://stream-studio.eown.io/streams/6455553128ad9600088a9480"
      target="blank"
      elevation="0"
      large
    >
      Get tips on what digital products you can create
    </v-btn>
    <span class="mt-5">
      Or
      <a href="https://stream-studio.eown.io/streams/645557e928ad9600088a9489" class="primary--text" target="blank">
        learn more about Paid Product and Income dashboard.
      </a>
    </span>
  </v-layout>
</template>

<script>
import imageFirstView from '@/assets/images/getting-started/stream-first-view.gif'

export default {
  data: () => ({
    imageFirstView
  })
}
</script>

<style>

</style>
