<template>
  <v-layout
    column
    justify-center
    align-center
    class="px-20 pb-10 pt-0 text-center"
  >
    <span class="text-h5">
      Sharing a published content stream on your favorite channels to start growing audience.
    </span>
    <span class="mt-3">
      To share on a specific channel, click on the corresponding channel icon in the "Links" column to copy its sharing link.
    </span>
    <v-img :src="imageFirstView" class="rounded-lg mt-10" />
    <v-btn
      class="mt-5"
      color="primary"
      href="https://stream-studio.eown.io/streams/6455553128ad9600088a9480"
      target="blank"
      elevation="0"
      large
    >
      Get tips on growing your audience
    </v-btn>
    <span class="mt-5">
      Or
      <a href="https://stream-studio.eown.io/streams/645557e928ad9600088a9489" class="primary--text" target="blank">
        learn more about the Content Stream Analytics dashboard.
      </a>
    </span>
  </v-layout>
</template>

<script>
import imageFirstView from '@/assets/images/getting-started/stream-first-view.gif'

export default {
  data: () => ({
    imageFirstView
  })
}
</script>

<style>

</style>
