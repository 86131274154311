<template>
  <div ref="parent" class="position-relative overflow-hidden" :class="[{'read-more-one-line': !isShowFull && lineClamp === 1}, textClass]">
    <pre
      v-if="!unuseHtml"
      ref="text"
      class="read-more-text"
      :style="`line-height:${lineHeight}em;${textStyle}`"
      v-html="text"
    />
    <pre
      v-else
      ref="text"
      class="read-more-text"
      :style="`line-height:${lineHeight}em;${textStyle}`"
    >
      {{ text }}
    </pre>
    <span
      class="primary--text cursor-pointer"
      :class="readMoreClass"
      :style="`white-space:nowrap;line-height:${lineHeight}em;${readMoreStyle}`"
      @click="toggleShow"
    >
      {{ readMoreText }}
    </span>
    <span ref="lineHeight" class="position-absolute d-block" :style="`top:-1em;left:-1em;opacity:0;height:${lineHeight}em`" />
  </div>
</template>

<script>
export default {
  name: 'ReadMore',

  props: {
    text: {
      type: String,
      required: true
    },
    lineClamp: {
      type: Number,
      default: 1
    },
    unuseHtml: {
      type: Boolean,
      default: false
    },
    lineHeight: {
      type: Number,
      default: 1.5
    },
    moreText: {
      type: String,
      default: () => 'Read more'
    },
    lessText: {
      type: String,
      default: () => 'Read less'
    },
    textClass: {
      type: [String, Array],
      default: () => ''
    }
  },

  data: () => ({
    isShowFull: false
  }),

  computed: {
    readMoreText() {
      return this.isShowFull ? this.lessText : this.moreText
    },

    textStyle() {
      if (this.isShowFull) {
        return ''
      }
      return `max-height:${this.lineClamp * this.lineHeight}em;-webkit-line-clamp:${this.lineClamp};line-height:${this.lineHeight}em`
    },

    readMoreStyle() {
      if (this.isShowFull) {
        return ''
      }
      return `top:${this.lineClamp * this.lineHeight}em;left:0`
    },

    readMoreClass() {
      if (this.isShowFull || this.lineClamp === 1) {
        return ''
      }
      return 'position-absolute'
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.changeParentHeight()
    })
  },

  methods: {
    toggleShow() {
      this.isShowFull = !this.isShowFull
      this.changeParentHeight()
    },

    changeParentHeight() {
      if (this.isShowFull) {
        this.$refs.parent.style.height = 'auto'
        return
      }

      const textHeight = this.$refs.text.scrollHeight
      const lineHeight = this.$refs.lineHeight.scrollHeight
      if (textHeight >= lineHeight * this.lineClamp) {
        this.$refs.parent.style.height = `${(this.lineClamp === 1 ? this.lineClamp : this.lineClamp + 1) * this.lineHeight}em`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.read-more-one-line {
  display: flex;
}
.read-more-text {
  display: block;
  display: -webkit-box;
  width: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
