<template>
  <v-layout class="flex-0-0 my-4 e-pagination">
    <v-flex class="d-flex my-auto flex-0-0">
      <template v-if="!hideLimit">
        <span class="my-auto">Show</span>
        <v-autocomplete
          :value="limit"
          :items="optionsShowingItems"
          dense
          outlined
          hide-details
          class="mx-2 limit-input"
          @input="updateLimit"
        />
        <span class="my-auto">in {{ total }} results</span>
      </template>
    </v-flex>

    <v-pagination
      :value="page"
      :length="pages"
      :total-visible="visiblePage"
      class="flex my-auto"
      @input="updatePage"
    />

    <v-flex class="d-flex flex-0-0 my-auto">
      <template v-if="!hideJump">
        <span class="my-auto mr-2 text-body-2">Jumb to page</span>
        <v-text-field
          v-model="jumpToPage"
          type="number"
          min="1"
          :max="pages"
          step="1"
          dense
          outlined
          hide-details
          class="page-input"
          @keyup.enter="updatePage(jumpToPage)"
        />
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
import { uniq } from 'lodash'

export default {
  name: 'EPagination',

  props: {
    showOptions: {
      type: Array,
      default: () => [5, 10, 25, 50, 100]
    },
    limit: {
      type: Number,
      default: 25
    },
    page: {
      type: Number,
      default: 1
    },
    pages: {
      type: Number,
      default: 1
    },
    visiblePage: {
      type: Number,
      default: 6
    },
    total: {
      type: Number,
      default: 0
    },
    hideLimit: {
      type: Boolean,
      default: false
    },
    hideJump: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    jumpToPage: 1
  }),

  computed: {
    optionsShowingItems() {
      return uniq([...this.showOptions, this.limit]).sort((a, b) => a - b)
    }
  },

  methods: {
    updatePage(page) {
      this.$emit('onUpdate', { page })
    },
    updateLimit(limit) {
      this.$emit('onUpdate', { limit })
    }
  }
}
</script>

<style lang="scss">
.e-pagination {
  .limit-input {
    width: 75px;
    .v-input__append-inner {
      margin-top: 4px !important;
    }
    &.v-select.v-input--is-focused input {
      min-width: 0 !important;
    }
  }
  .page-input {
    width: 50px;
  }
  .limit-input, .page-input {
    .v-input__control .v-input__slot {
      min-height: 30px !important;
    }
  }
}
</style>
