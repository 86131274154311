export default function ({ $auth, store, error, redirect }) {
  // console.log('$auth', $auth)
  // if (!store.state.authUser) {
  //   return redirect('/sign-in')
  // error({
  //   message: 'You are not connected',
  //   statusCode: 403
  // })
  // }
}
