<template>
  <v-layout
    column
    justify-center
    align-center
    class="px-20 pb-10 pt-0 text-center"
  >
    <span class="text-h5">
      You haven’t published any paid products.
    </span>
    <span class="mt-3">
      Click the "Publish" button on the product that you want to sell to customers
    </span>
    <v-img :src="imagePublicStream" class="rounded-lg mt-10" />
  </v-layout>
</template>

<script>
import imagePublicStream from '@/assets/images/getting-started/public-product.gif'

export default {
  data: () => ({
    imagePublicStream
  })
}
</script>

<style>

</style>
