<template>
  <div class="d-inline-block cursor-pointer" :class="contentClass">
    <div v-if="$slots.default" class="d-flex" @click.prevent="clickHandle">
      <slot name="default" />
    </div>

    <v-dialog
      :value="dialog"
      :max-width="480"
      content-class="e-main-dialog"
      @input="outSideClick"
      @keydown.esc="callNo"
    >
      <v-card tile class="wrap d-flex flex-column position-relative">
        <!-- <v-icon class="position-absolute right-20 top-20" @click="callNo">
          close
        </v-icon> -->

        <v-card-title v-if="popupDataComputed.headLine || popupDataComputed.headIcon" class="ma-0 pa-0 text-h6 grey--text text--darken-4 d-flex flex-column">
          <v-icon
            v-if="popupDataComputed.headIcon"
            size="70"
            :color="iconColor"
            class="mb-5 mx-auto"
          >
            {{ popupDataComputed.headIcon }}
          </v-icon>
          <p v-html="popupDataComputed.headLine" />
        </v-card-title>

        <v-card-text v-if="popupDataComputed.headText && !$slots.content" class="ma-0 pa-0 text-body-1 grey--text text--darken-4">
          <p v-html="popupDataComputed.headText" />
        </v-card-text>

        <v-card-text v-if="$slots.content" class="ma-0 pa-0">
          <slot name="content" />
        </v-card-text>

        <v-card-actions class="ma-0 pa-0 pt-2">
          <v-spacer />
          <v-btn
            text
            color="error"
            class="text-body-1"
            @click.prevent="callNo"
          >
            {{ noText }}
          </v-btn>
          <v-btn
            text
            color="primary"
            class="text-body-1"
            @click.prevent="callYes"
          >
            {{ popupDataComputed.yesText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const confirmTypes = ['publish', 'remove', 'confirm']

export default {
  name: 'EConfirm',

  props: {
    confirmType: {
      type: String,
      validator: e => confirmTypes.includes(e),
      default: 'confirm'
    },
    headIcon: {
      type: String,
      default: ''
    },
    headLine: {
      type: String,
      default: 'Confirm'
    },
    headText: {
      type: String,
      default: 'Are you sure?'
    },
    noText: {
      type: String,
      default: 'Cancel'
    },
    yesText: {
      type: String,
      default: 'Confirm'
    },
    // template props
    itemType: {
      type: String,
      default: ''
    },
    itemName: {
      type: String,
      default: ''
    },
    // End: template props
    clickStop: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: 'warning'
    },
    contentClass: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    dialog: false
  }),

  computed: {
    popupDataComputed() {
      switch (this.confirmType) {
        case 'remove':
          return {
            headLine: `Remove ${this.itemType ? this.itemType : ''}?`,
            headText: `Are you sure to remove <strong>${this.itemName ? this.itemName : ''}</strong>? This action <strong>cannot be undone.</strong>`,
            noText: 'Cancel',
            yesText: 'Confirm Remove'
          }
        case 'publish':
          return {
            headIcon: 'info',
            headLine: '',
            headText: 'Publish this content stream before sending to your audience. Otherwise, they won’t able to see it',
            noText: 'Cancel',
            yesText: 'Confirm Publish'
          }
        default:
          return {
            headIcon: this.headIcon,
            headLine: this.headLine,
            headText: this.headText,
            noText: this.noText,
            yesText: this.yesText
          }
      }
    }
  },

  mounted() {
    window.addEventListener('keydown', this.handleWindowKeydown)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleWindowKeydown)
  },

  methods: {
    clickHandle(e) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.dialog = true
        }, 400)
      })
      if (this.clickStop) {
        e.stopPropagation()
      }
    },
    callYes(e) {
      this.dialog = false
      this.$emit('yes', e)
    },
    callNo(e) {
      this.dialog = false
      this.$emit('no', e)
    },
    outSideClick(e) {
      this.dialog = false
    },
    handleWindowKeydown(e) {
      if (this.dialog && e.key === 'Enter') {
        this.callYes(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog.e-main-dialog {
  border-radius: 18px !important;
  .wrap {
    border-radius: 18px !important;
    padding: 32px 36px 24px;
  }
}
.btn-close {

}
</style>
