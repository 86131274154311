<template>
  <img
    src="/logo.svg"
    alt="EOwn"
    :width="size"
  >
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 60
    }
  }
}
</script>

<style>

</style>
