<template>
  <v-layout column class="e-data-table" :class="{ 'h-0' : scrollable }">
    <v-layout column class="rounded-t-lg border border-solid border-secondary bg-white" :class="{ 'overflow-y-auto' : scrollable }">
      <v-layout class="flex-0-0 rounded-t-lg bb-secondary header-table" :class="{ 'sticky': scrollable }">
        <v-flex v-if="selectable" class="flex-center flex-0-0 pl-5 pr-2" :class="[denseHeader ? 'py-2' : 'py-4']">
          <v-checkbox
            :value="isSelectAll"
            :ripple="false"
            hide-details
            color="primary"
            class="e-checkbox"
            @click.prevent="selectItemAll"
          />
        </v-flex>
        <v-layout>
          <v-hover v-for="(header, hIdx) in headers" :key="hIdx" v-slot="{ hover }">
            <v-flex class="d-flex" :class="[header.width, header.headerClass, (hIdx === 0 || hIdx === headers.length - 1) ? 'px-5' : 'px-2', denseHeader ? 'py-2' : 'py-4']">
              <div
                class="d-flex text-subtitle-2 my-auto"
                :class="{ 'cursor-pointer': header.sortable !== false }"
                @click="setSort(header)"
              >
                <template v-if="$scopedSlots[`header.${header.value}`]">
                  <slot
                    :name="`header.${header.value}`"
                    :item="header"
                    :index="hIdx"
                    :hover="hover"
                  />
                </template>
                <template v-else>
                  {{ header.text }}
                  <v-icon
                    v-if="header.sortable !== false"
                    v-show="hover || sortObj.sortBy === header.value"
                    size="16"
                    class="ml-2"
                  >
                    {{ sortObj.sortBy === header.value && sortObj.sortDir === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
                  </v-icon>
                </template>
              </div>
            </v-flex>
          </v-hover>
        </v-layout>
      </v-layout>

      <v-layout column>
        <template v-if="Array.isArray(cptItems) && cptItems.length">
          <v-hover v-for="(item, index) in cptItems" :key="(item._id || index)" v-slot="{ hover }">
            <v-layout column class="flex-0-0">
              <v-layout
                class="flex-0-0 item cursor-pointer"
                :class="{ 'bt-secondary': index !== 0, 'grey lighten-3 item-disabled': item.disabled }"
                @click="clickRow($event, { item, index })"
              >
                <v-flex v-if="selectable" class="flex-center flex-0-0 pl-5 pr-2" :class="[denseHeader ? 'py-2' : 'py-4']">
                  <v-checkbox
                    v-model="selected"
                    :value="item._id"
                    :ripple="false"
                    hide-details
                    color="primary"
                    class="e-checkbox"
                    @change="$emit('select', { selected })"
                  />
                </v-flex>
                <v-layout>
                  <v-flex
                    v-for="(header, hIdx) in headers"
                    :key="(item._id || index)+'_'+hIdx"
                    :class="[header.width, header.class, `table-col-${header.value}`, (hIdx === 0 || hIdx === headers.length - 1) ? 'px-5' : 'px-2']"
                    class="d-flex py-3 text-body-2"
                  >
                    <template v-if="$scopedSlots[`item.${header.value}`]">
                      <slot
                        :name="`item.${header.value}`"
                        :item="item"
                        :index="index"
                        :hover="hover"
                      />
                    </template>
                    <span v-else>
                      {{ $helpers.get(item, header.value) }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-layout>
              <v-layout
                v-if="$scopedSlots[`item.additionalRow`]"
                class="flex-0-0 item cursor-pointer"
                @click="clickRow($event, { item, index })"
              >
                <slot
                  :name="`item.additionalRow`"
                  :item="item"
                  :index="index"
                  :hover="hover"
                />
              </v-layout>
            </v-layout>
          </v-hover>
        </template>
        <p v-else class="my-6 mx-auto max-w-800 text-center font-italic text-body-2">
          {{ noDataText }}
        </p>
      </v-layout>
    </v-layout>

    <v-layout v-if="!hideDefaultFooter" class="flex-0-0 my-4">
      <v-flex class="d-flex my-auto flex-0-0">
        <span class="my-auto">Show</span>
        <v-autocomplete
          :value="pagination.limit"
          :items="limits"
          dense
          outlined
          hide-details
          class="mx-2 limit-input"
          @input="updateLimit"
        />
        <span class="my-auto">in {{ total }} results</span>
      </v-flex>

      <v-pagination
        :value="pagination.page"
        :length="pages"
        :total-visible="pagination.numberVisiblePages"
        class="flex my-auto"
        @input="updatePage"
      />

      <v-flex class="d-flex flex-0-0 my-auto">
        <span class="my-auto mr-2 text-body-2">Jumb to page</span>
        <v-text-field
          v-model="jumpToPage"
          dense
          outlined
          hide-details
          class="page-input"
          @keyup.enter="updatePage(jumpToPage)"
        />
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { uniq } from 'lodash'
import { sortCallBack } from '@/utils/index'

export default {
  name: 'EDataTable',

  props: {
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => ({})
    },
    sort: {
      type: Object,
      default: () => ({})
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    hideDefaultFooter: Boolean,
    internalSort: Boolean,
    denseHeader: Boolean,
    noDataText: {
      type: String,
      default: 'No data available'
    }
  },

  data: () => ({
    selected: [],
    limits: [5, 10, 15, 20],
    sortObj: {},
    jumpToPage: 1
  }),

  computed: {
    cptItems() {
      if (this.internalSort) {
        return this.items.slice(0).sort(sortCallBack(this.sortObj.sortBy, this.sortObj.sortDir === 'asc', this.sortObj.sortType))
      }
      return this.items
    },

    total() {
      return this.pagination.total || this.pagination.totalDocs
    },

    pages() {
      return this.pagination.pages || this.pagination.totalPages
    },

    isSelectAll: {
      get() {
        return this.cptItems.every(item => this.selected.includes(item._id))
      },
      set() {}
    }
  },

  watch: {
    sort: {
      handler(value) {
        this.sortObj = value
      },
      immediate: true
    },
    'pages'(v) {
      if (v < this.pagination.page) {
        this.jumpToPage = v
        this.$helpers.updateQueryUrl(this, { page: v })
      }
    }
  },

  methods: {
    clickRow(e, data) {
      const path = e.path || (e.composedPath && e.composedPath())
      const ignoreClasses = ['table-col-actions', 'item-action', 'cool-lightbox', 'ignore-cell']
      const hasIgnoreEl = this.$helpers.ensureArray(path).some((p) => {
        const classList = (p.className && p.className.split && p.className.split(' ')) || []
        return classList.some(className => ignoreClasses.includes(className))
      })
      if (!hasIgnoreEl) {
        this.$emit('click:row', data)
      }
    },
    setSort({ sortable = true, value, sortType = '' }) {
      if (sortable) {
        if (this.sortObj.sortBy === value) {
          this.sortObj.sortDir = this.sortObj.sortDir === 'asc' ? 'desc' : 'asc'
        } else {
          this.sortObj.sortBy = value
          this.sortObj.sortDir = 'asc'
        }
        this.sortObj = { ...this.sortObj, sortType }
        this.$emit('sort', this.sortObj)
      }
    },
    updateLimit(limit) {
      this.$emit('pagination', { limit })
    },
    updatePage(value) {
      if (!isNaN(value) && Number(value) !== this.pagination.page) {
        const page = Math.min(Number(value), this.pages)
        this.jumpToPage = page
        this.$emit('pagination', { page })
      }
    },
    selectItemAll() {
      if (this.isSelectAll) {
        this.selected = this.selected.filter(id => !this.cptItems.some(s => s._id === id))
      } else {
        this.selected = uniq([
          ...this.selected,
          ...this.cptItems.filter(item => !item.disabled).map(item => item._id)
        ])
      }
      this.$emit('select', { selected: this.selected })
    }
  }
}
</script>

<style lang="scss">
.e-data-table {
  .header-table {
    background: #F5F7F9;
  }
  .sticky {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .item {
    &:hover {
      background: #F6F8FA;
    }
    .item-action {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      padding-left: 16px;
      padding-right: 16px;
      background-color: #F6F8FA;
    }
    &.item-disabled {
      .item-action {
        background-color: transparent;
      }
    }
  }
  .limit-input {
    width: 75px;
    .v-input__append-inner {
      margin-top: 4px !important;
    }
    &.v-select.v-input--is-focused input {
      min-width: 0 !important;
    }
  }
  .page-input {
    width: 50px;
  }
  .limit-input, .page-input {
    .v-input__control .v-input__slot {
      min-height: 30px !important;
    }
  }
  .e-checkbox.v-input--is-label-active {
    .mdi.mdi-checkbox-marked {
      color: var(--v-primary-base) !important;
    }
  }

  @mixin fw($percent) {
    width: $percent !important;
    flex: 0 0 $percent !important;
  }
  .fw-60 {
    @include fw(60%);
  }
  .fw-50 {
    @include fw(50%);
  }
  .fw-45 {
    @include fw(45%);
  }
  .fw-40 {
    @include fw(40%);
  }
  .fw-35 {
    @include fw(35%);
  }
  .fw-33 {
    @include fw(33%);
  }
  .fw-30 {
    @include fw(30%);
  }
  .fw-25 {
    @include fw(25%);
  }
  .fw-20 {
    @include fw(20%);
  }
  .fw-17-5 {
    @include fw(17.5%);
  }
  .fw-15 {
    @include fw(15%);
  }
  .fw-12-5 {
    @include fw(12.5%);
  }
  .fw-10 {
    @include fw(10%);
  }
  .fw-5 {
    @include fw(5%);
  }
}
</style>
