<template>
  <v-layout
    column
    class="flex-0-0 e-slider position-relative"
    :style="sliderDimension"
  >
    <div ref="swiper" class="swiper w-full h-full" :class="!$scopedSlots['swiper-slide-content'] ? 'position-absolute w-full h-full top-0 left-0' : ''">
      <div class="swiper-wrapper">
        <div v-for="(image, iIdx) in images" :key="iIdx" class="swiper-slide flex-center">
          <slot name="swiper-slide-content" :item="image" />
          <template v-if="!$scopedSlots['swiper-slide-content']">
            <img
              :src="$helpers.getFilePreview(imageKey ? $helpers.get(image, imageKey) : image, 'origin')"
              :class="[
                viewFullSize ? 'cursor-pointer' : 'cursor-default',
                cover ? 'object-cover' : 'object-contain',
              ]"
              loading="lazy"
              class="d-block w-full h-full bg-gray-200"
              @click.stop="viewFullSize && viewAllPhotos(iIdx)"
            >
            <div class="swiper-lazy-preloader swiper-lazy-preloader-white" />
          </template>
        </div>
      </div>
      <div v-if="dot" class="swiper-pagination" />
      <div v-if="arrow" class="swiper-button-prev" />
      <div v-if="arrow" class="swiper-button-next" />
    </div>

    <CoolLightBox
      v-if="viewFullSize"
      :items="images.map(image => $helpers.getFilePreview(imageKey ? $helpers.get(image, imageKey) : image, 'origin'))"
      :index="previewIdx"
      @close="previewIdx = null"
    />
  </v-layout>
</template>

<script>
import Swiper from 'swiper/swiper-bundle.min'
import 'swiper/swiper-bundle.min.css'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'ESlider',

  components: {
    CoolLightBox
  },

  props: {
    images: {
      type: Array,
      required: true
    },
    imageKey: {
      type: String,
      default: 'imageUrl'
    },
    slidesPerView: {
      type: Number,
      default: 1
    },
    loop: {
      type: Boolean,
      default: true
    },
    aspectRatio: {
      type: Number,
      default: 1
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    cover: {
      type: Boolean,
      default: false
    },
    viewFullSize: {
      type: Boolean,
      default: true
    },
    dot: {
      type: Boolean,
      default: true
    },
    arrow: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    previewIdx: null
  }),

  computed: {
    sliderDimension() {
      if (this.aspectRatio) {
        return {
          width: this.width ? (this.width + 'px') : '100%',
          paddingTop: this.width ? (this.width + 'px') : '100%'
        }
      }
      return {
        width: this.width ? (this.width + 'px') : '100%',
        height: this.height ? (this.height + 'px') : '100%'
      }
    }
  },

  async mounted() {
    await this.$nextTick()
    // eslint-disable-next-line no-new
    new Swiper(this.$refs.swiper, {
      // Pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      lazy: true,
      loop: this.loop,
      slidesPerView: 1,
      breakpoints: {
        // Tablet
        640: {
          slidesPerView: Math.min(this.slidesPerView, 2),
          spaceBetween: 10
        },
        // Desktop
        850: {
          slidesPerView: this.slidesPerView,
          spaceBetween: 20
        }
      },
      spaceBetween: 20
    })
  },

  methods: {
    viewAllPhotos(idx = 0) {
      this.previewIdx = idx
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper {
  --swiper-navigation-size: 24px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-weight: 600;
}
</style>
