<template>
  <v-layout>
    <EDialogPhotoCropper
      v-model="showPopupCropper"
      :image-src="photo.file"
      :width="width"
      :height="height"
      :is-save="false"
      fix-size
      @saved="changePhoto"
    />

    <div @click="$refs.uploadPhoto.click()">
      <slot name="upload" />
    </div>

    <input
      ref="uploadPhoto"
      type="file"
      :accept="IMAGE_TYPES.map(type => `.${type}`).join(', ')"
      class="d-none"
      @change="loadPhoto"
    >
  </v-layout>
</template>

<script>
import EDialogPhotoCropper from './e-dialog-photo-cropper'
import { IMAGE_TYPES } from '@/constants/files.js'

export default {
  name: 'EDialogUploadAndCropPhoto',

  components: {
    EDialogPhotoCropper
  },

  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },

  data: () => ({
    IMAGE_TYPES,
    photo: {},
    showPopupCropper: false
  }),

  methods: {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    resetData() {
      this.photo = {}
      this.showPopupCropper = false
    },
    async loadPhoto(event) {
      this.showPopupCropper = true
      const { files } = event.target
      if (files && files[0]) {
        this.photo = {
          fileName: files[0].name,
          file: await this.getBase64(files[0])
        }
      }
      this.$refs.uploadPhoto.value = ''
    },
    changePhoto({ file }) {
      this.$emit('change', { file, fileName: this.photo.fileName })
    }
  }
}
</script>
