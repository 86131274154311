<template>
  <v-flex class="e-input" :class="{ 'position-relative': disabled }">
    <v-layout v-if="label || showWhenFalsy" class="mb-1 align-center e-input__label">
      <label v-if="label" class="text-subtitle-2 font-weight-medium" :class="{ 'required': required }">{{ label }}</label>
      <slot name="append-label" />
      <EInfo v-if="info" :text="info" />
    </v-layout>
    <slot name="label" />
    <slot name="default" />
    <div v-if="disabled" class="position-absolute bg-white left-0 top-0 right-0 bottom-0 opacity-70 z-100" />
  </v-flex>
</template>

<script>
import EInfo from './e-info.vue'

export default {
  name: 'EInput',

  components: {
    EInfo
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    showWhenFalsy: Boolean,
    disabled: Boolean,
    required: {
      type: Boolean,
      default: () => false
    },
    info: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.e-input {
  label.required::after {
    content: ' *';
    color: var(--v-error-base);
  }
  .v-input, .v-label {
    font-size: 14px;
    letter-spacing: 0.0025em;
  }
  // .v-label {
  //   opacity: 0.4;
  // }
}
</style>
