<template>
  <v-layout
    v-if="loggedIn && $helpers.get(authUser, 'role') !== 'guest'"
    justify-center
    align-center
    class="flex-grow-0"
  >
    <NotificationBell />
    <v-menu offset-y nudge-bottom="8">
      <template #activator="{ on }">
        <v-avatar size="40" class="mr-4 cursor-pointer" v-on="on">
          <img :src="$helpers.getFilePreview($helpers.get(authUser, 'profile.avatar')) || require('~/assets/images/avatar.png')">
        </v-avatar>
      </template>
      <v-card width="300">
        <v-list>
          <v-list-item>
            <v-list-item-avatar size="56">
              <img :src="$helpers.getFilePreview($helpers.get(authUser, 'profile.avatar')) || require('~/assets/images/avatar.png')">
            </v-list-item-avatar>

            <v-list-item-content class="py-2">
              <v-list-item-title class="text-subtitle-2 grey--text text--darken-4">
                {{ $helpers.get(authUser, 'profile.fullName') }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-caption opacity-50 grey--text text--darken-4">
                {{ authUser.email }}
              </v-list-item-subtitle>
              <div class="text-caption">
                <span class="green white--text pa-1 rounded" style="line-height:2">{{ pricingName }}</span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list nav class="px-0 sm-menu">
          <v-list-item
            class="px-5 py-1 text-subtitle-2 menu-item"
            to="/settings/profile"
            @click="updateGA({ eventCategory: 'Dashboard', eventAction: 'dashboard_settings_click' }, ('dashboard_settings_click'))"
          >
            Settings
          </v-list-item>
          <v-list-item
            class="px-5 py-1 text-subtitle-2 menu-item"
            @click="updateGA({ eventCategory: 'Dashboard', eventAction: 'dashboard_quickguide_click' })"
          >
            Quick Guide
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list nav class="px-0 sm-menu">
          <v-list-item id="logout" class="px-5 py-1 text-subtitle-2 error--text" @click="logout">
            Log Out
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-layout>
</template>

<script>
import Auth from '@/mixins/auth'

export default {
  mixins: [Auth],

  methods: {
    updateGA(ga, mixpanel) {
      ga && this.$helpers.gaTracking(ga.eventCategory, ga.eventAction)
      mixpanel && this.$helpers.tractMixpanel(mixpanel)
    }
  }
}
</script>

<style>

</style>
