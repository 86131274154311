import { getFilePreview } from '../upload-s3'

const getOrDefaultStreamCover = (coverImage) => {
  const imageUrl = coverImage && getFilePreview(coverImage)
  return imageUrl || '/images/default-template-cover.jpg'
}

const isShowPublished = (contentItem) => {
  return ['article', 'listing', 'product'].includes(contentItem.category) || contentItem.isProductType
}

const getStreamIcon = (stream) => {
  return stream ? require(`@/assets/images/streams/${stream.streamTheme}.png`) : ''
}

export {
  getOrDefaultStreamCover,
  isShowPublished,
  getStreamIcon
}
