<template>
  <nuxt-link v-if="nuxt" class="d-flex align-center primary--text" :to="to">
    <v-icon size="28" color="primary">
      keyboard_backspace
    </v-icon>
    <span class="ml-2 text-button">{{ text }}</span>
  </nuxt-link>
  <a v-else class="d-flex align-center primary--text" @click="$emit('click'); backClick()">
    <v-icon size="28" color="primary">
      keyboard_backspace
    </v-icon>
    <span class="ml-2 text-button">{{ text }}</span>
  </a>
</template>

<script>
export default {
  name: 'EBack',

  props: {
    nuxt: Boolean,
    to: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: 'Back'
    }
  },
  methods: {
    backClick() {
      this.$helpers.gaTracking('Template list', 'template_back_click')
    }
  }
}
</script>

<style>

</style>
