<template>
  <v-app class="min-w-960">
    <v-app-bar
      absolute
      app
      flat
      class="app-app-bar min-w-960"
      color="white"
      :height="(userWarnings.length * 44) + 60"
    >
      <v-layout column>
        <template v-if="userWarnings.length">
          <v-alert
            v-for="(warning, idx) in userWarnings"
            :key="`warning-${idx}`"
            dense
            type="warning"
            height="44"
            :icon="false"
            class="mb-0 rounded-0 text-center"
          >
            <v-layout class="flex-center">
              <v-icon class="material-symbols-rounded">
                warning
              </v-icon>
              <router-link class="ml-2 mb-0 text-subtitle-2 white--text cursor-pointer" :to="warning.href">
                {{ warning.text }}
              </router-link>
              <a v-if="warning.additional" :href="warning.additional.href" class="ml-5 white--text text-subtitle-2 text-decoration-underline">
                {{ warning.additional.text }}
              </a>
            </v-layout>
          </v-alert>
        </template>

        <v-layout class="align-center h-30">
          <v-app-bar-nav-icon v-if="loggedIn" class="ml-1 mr-2" @click.stop="drawer = !drawer" />

          <v-toolbar-title v-if="loggedIn" class="d-none d-lg-block">
            <nuxt-link to="/" class="d-flex">
              <img src="/logo-eown.svg" alt="EOwn Logo" class="w-50">
            </nuxt-link>
          </v-toolbar-title>

          <v-list nav class="ma-auto px-4 py-0">
            <!-- <v-list-item-group class="d-flex align-center">
              <template v-for="(menuItem, mIdx) in topMenus">
                <v-list-item
                  v-if="!menuItem.submenu"
                  :key="mIdx"
                  :to="menuItem.to"
                  :href="menuItem.href"
                  active-class="primary--text"
                  class="mx-5 mb-0 rounded app-bar-item"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2">
                      <v-menu offset-y>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            class="button-header"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ menuItem.title }}
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in menuItem.submenu"
                            :key="index"
                          >
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <div
                  v-else
                  :id="`app-bar-item-${mIdx}`"
                  :key="'has-submenu' + mIdx"
                  class="mx-5 rounded app-bar-item"
                >
                  <v-menu
                    :attach="`#app-bar-item-${mIdx}`"
                    offset-y
                    open-on-hover
                    content-class="app-bar-submenu"
                  >
                    <template #activator="{ on, attrs }">
                      <v-list-item v-bind="attrs" class="mb-0" v-on="on">
                        <v-list-item-content>
                          <v-list-item-title class="text-subtitle-2">
                            {{ menuItem.title }}
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-icon class="my-auto ml-2">
                          <v-icon>expand_more</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </template>

                    <v-layout class="white px-10 py-5" :class="menuItem.version">
                      <v-list v-for="(submenu, sIdx) in menuItem.submenu" :key="sIdx" class="flex">
                        <v-list-item
                          :href="submenu.href"
                          class="ma-0 pa-0 text-subtitle-2 submenu-title"
                        >
                          {{ submenu.title }}
                        </v-list-item>

                        <v-list-item
                          v-for="(item, idx) in submenu.items"
                          :key="`${sIdx}-${idx}`"
                          :href="item.href"
                          class="pa-0 mt-3 text-subtitle-2 submenu-item"
                        >
                          {{ item.title }}
                        </v-list-item>
                      </v-list>
                    </v-layout>
                  </v-menu>
                </div>
              </template>
            </v-list-item-group> -->
          </v-list>

          <template v-if="loggedIn && $helpers.get(authUser, 'role') !== 'guest'">
            <v-badge
              v-if="$helpers.get(authUser, 'pricing.type') && $helpers.get(authUser, 'pricing.type') !== 'free'"
              :content="$helpers.get(authUser, 'pricing.type')"
              color="green"
              inline
              class="text-capitalize badge-large mr-3"
            />
            <v-btn
              v-else
              depressed
              outlined
              height="30"
              color="primary"
              class="mr-4"
              rounded
              @click="upgradeAccount"
            >
              Upgrade
            </v-btn>
          </template>

          <e-user-menu />
        </v-layout>
      </v-layout>
    </v-app-bar>

    <v-main class="bg-white h-screen min-w-960 app-main">
      <v-navigation-drawer
        v-if="loggedIn"
        v-model="drawer"
        :fixed="!drawer && !$vuetify.breakpoint.mdAndDown"
        :absolute="$vuetify.breakpoint.mdAndDown"
        :temporary="$vuetify.breakpoint.mdAndDown"
        stateless
        width="260"
        class="flex-0-0 app-navigation-drawer"
      >
        <slot v-if="$slots['navigation-drawer']" name="navigation-drawer" />
        <v-layout v-else column class="py-5">
          <v-list
            v-for="(item, index) in leftMenus"
            :key="index"
            dense
            class="px-4 py-0"
          >
            <v-list-item
              v-if="item.to"
              :key="`list-item-${index}`"
              :to="item.to"
              exact
              :class="isActiveMenu(item.to) ? 'primary--text v-list-item--active' : ''"
              class="my-1 pl-6 rounded overflow-hidden"
            >
              <v-list-item-icon v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-2">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              v-if="Array.isArray(item.children)"
              :key="`list-group-${index}`"
              no-action
              class="my-1 rounded overflow-hidden custom-list-group secondary--text"
              @click="$event => handleOpenMenuGroup($event, $helpers.get(item, 'children.0.to'))"
            >
              <template #prependIcon>
                <v-icon>{{ item.icon }}</v-icon>
              </template>

              <template #activator>
                <v-list-item-title class="text-subtitle-2">
                  {{ item.headline }}
                </v-list-item-title>
              </template>

              <v-list-item
                v-for="(subItem) in item.children"
                :key="subItem.text"
                :to="subItem.to"
                exact
                :class="isActiveMenu(subItem.to) ? 'primary--text v-list-item--active' : ''"
                class="my-1 pl-6 rounded overflow-hidden"
              >
                <v-list-item-icon class="min-w-min">
                  <v-icon size="14" color="#D7D7D7">
                    fiber_manual_record
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-2">
                    {{ subItem.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-layout>
      </v-navigation-drawer>

      <v-layout id="main-content" class="overflow-y-auto" @scroll="scrollMainContent">
        <slot v-if="$slots['main-content']" name="main-content" />
        <nuxt v-else />
      </v-layout>

      <PopupPricingPlan class="position-absolute" />
      <PopupLimitPermission class="position-absolute" />
      <PricingPlanHandler class="position-absolute" />
      <PopupAction />
    </v-main>
  </v-app>
</template>

<script>
import PopupAction from '../components/common/popup-action.vue'
import { mailtoVerificationHelp } from '@/utils/index.js'
import PopupLimitPermission from '@/components/auth/popups/popup-limit-permission.vue'
import PopupPricingPlan from '@/components/auth/popups/popup.pricing-plan.vue'
import AuthMixin from '@/mixins/auth'
import PricingPlanHandler from '@/components/auth/pricing-plan-handler.vue'

export default {
  name: 'AppLayout',

  components: {
    PopupLimitPermission,
    PopupPricingPlan,
    PopupAction,
    PricingPlanHandler
  },

  mixins: [AuthMixin],

  middleware: ['app-authenticated'],

  props: {
    showDrawer: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    drawer: true,
    topMenus: [
      {
        title: 'Creation Tools',
        to: '',
        version: 'two-column',
        submenu: [
          {
            title: 'Content Sharing',
            link: 'https://eown.io/coming-soon/'
          },
          {
            title: 'Listing Alerts',
            link: 'https://eown.io/coming-soon/'
          },
          {
            title: 'Single Property Website',
            link: 'https://eown.io/coming-soon/'
          },
          {
            title: 'Social Post Landing Page',
            link: 'https://eown.io/coming-soon/'
          },
          {
            title: 'Ads Landing Page',
            link: 'https://eown.io/coming-soon/'
          },
          {
            title: 'Resource site',
            link: 'https://eown.io/coming-soon/'
          },
          {
            title: 'Website',
            link: 'https://eown.io/coming-soon/'
          },
          {
            title: 'Event marketing',
            link: 'https://eown.io/coming-soon/'
          },
          {
            title: 'Bio Link',
            link: 'https://eown.io/coming-soon/'
          },
          {
            title: 'Courses',
            link: 'https://eown.io/coming-soon/'
          }
        ]
      },
      {
        title: 'Solution',
        version: 'four-column',
        submenu: [
          {
            title: 'CONTENT EXPERIENCE',
            link: 'https://eown.io/solutions-content-experience/'
          },
          {
            title: 'Multi-channel Marketing',
            link: 'https://eown.io/solutions-multi-channel-marketing/'
          },
          {
            title: 'Multi-media Content',
            link: 'https://eown.io/solutions-multi-media-content/'
          },
          {
            title: 'Niche Marketing',
            link: 'https://eown.io/solutions-niche-marketing/'
          },
          {
            title: 'Lead Generation',
            link: 'https://eown.io/solutions-content-experience/#lead-generation'
          },
          {
            title: 'Facebook Ads',
            link: 'https://eown.io/solutions-multi-channel-marketing/#facebook-ads'
          },
          {
            title: 'Graphic Content',
            link: 'https://eown.io/solutions-multi-media-content/#graphic'
          },
          {
            title: 'By Demographics',
            link: 'https://eown.io/solutions-niche-marketing/#By-Demographic'
          },
          {
            title: 'Lead Conversion',
            link: 'https://eown.io/solutions-content-experience/#lead-conversion'
          },
          {
            title: 'Facebook Posts',
            link: 'https://eown.io/solutions-multi-channel-marketing/#facebook-posts'
          },
          {
            title: 'Forms Content',
            link: 'https://eown.io/solutions-multi-media-content/#form'
          },
          {
            title: 'By Geography',
            link: 'https://eown.io/solutions-niche-marketing/#By-Geography'
          },
          {
            title: 'Customer Support',
            link: 'https://eown.io/solutions-content-experience/#customer-support'
          },
          {
            title: 'Email',
            link: 'https://eown.io/solutions-multi-channel-marketing/#email'
          },
          {
            title: 'Text Content',
            link: 'https://eown.io/solutions-multi-media-content/#text'
          },
          {
            title: 'By Experience',
            link: 'https://eown.io/solutions-niche-marketing/#By-Experience'
          },
          {
            title: 'Referrals',
            link: 'https://eown.io/solutions-content-experience/#referrals'
          },
          {
            title: 'SEO',
            link: 'https://eown.io/solutions-multi-channel-marketing/#seo'
          },
          {
            title: 'Video',
            link: 'https://eown.io/solutions-multi-media-content/#video'
          },
          {
            title: 'By Housing Types',
            link: 'https://eown.io/solutions-niche-marketing/#Housing-Types'
          }
        ]
      },
      {
        title: 'Pricing'
        // to: '/stream-evaluator'
      },
      {
        title: 'Learn',
        version: 'two-column',
        submenu: [
          {
            title: 'Academy',
            link: 'https://eown.io/academy'
          },
          {
            title: 'Blog',
            link: 'https://blog.eown.io/'
          },
          {
            title: 'Help Center',
            link: 'https://newaccount1640137058467.freshdesk.com/support/home'
          },
          {
            title: 'Community Forum',
            link: 'https://community.eown.io/'
          },
          {
            title: 'Why content streams',
            link: 'https://eown.io/why-content-stream/'
          },
          {
            title: 'How It works',
            link: 'https://eown.io/why-content-stream/'
          },
          {
            title: 'Contact Us',
            link: 'https://eown.io/contact-us/'
          },
          {
            title: 'Affiliate programs',
            link: 'https://eown.io/coming-soon/'
          }
        ]
        // to: '/stream-evaluator'
      },
      {
        title: 'Agent Network'
        // to: '/stream-evaluator'
      }
    ],
    leftMenus: [
      {
        text: 'Dashboard',
        icon: 'icon-dashboard',
        to: '/'
      },
      {
        headline: 'Content Streams',
        icon: 'icon-stream',
        children: [
          { text: 'Manage', to: '/manage-analytics/streams?tab=manage' },
          { text: 'Analytics', to: '/manage-analytics/streams?tab=analytics' },
          { text: 'Templates', to: '/templates/content-streams' }
        ]
      },
      {
        headline: 'Paid Product',
        icon: 'icon-paid-product',
        children: [
          { text: 'Manage Products', to: '/paid-products/manage-products' },
          { text: 'Manage Income', to: '/users/income' },
          { text: 'Manage Customer', to: '/paid-products/manage-customer' }
        ]
      },
      {
        headline: 'Property Listings',
        icon: 'icon-listing',
        children: [
          { text: 'Manage', to: '/manage-analytics/listings?tab=manage' },
          { text: 'Analytics', to: '/manage-analytics/listings?tab=analytics' }
        ]
      },
      {
        headline: 'Contacts',
        icon: 'icon-book',
        children: [
          { text: 'Form Leads', to: '/manage-analytics/leads' },
          { text: 'Email Subscribers', to: '/manage-analytics/contacts' }
        ]
      },
      {
        headline: 'Social Publishing',
        icon: 'icon-campaign',
        children: [
          { text: 'Calendar', to: '/scheduled-publishing?mode=calendar' },
          { text: 'Templates', to: '/templates/promotional-content' }
        ]
      },
      {
        headline: 'Emails',
        icon: 'email',
        children: [
          { text: 'Manage', to: '/manage-analytics/emails' },
          { text: 'Workflow', to: '/emails/workflows' }
        ]
      },
      {
        headline: 'Content Items',
        icon: 'icon-content',
        children: [
          { text: 'Manage', to: '/manage-analytics/contents?tab=manage' },
          { text: 'Analytics', to: '/manage-analytics/contents?tab=analytics' },
          { text: 'Templates', to: '/templates/content-templates' }
        ]
      },
      {
        text: 'Account',
        icon: 'account_circle',
        to: '/settings/profile'
      }
    ],
    mailtoVerificationHelp
  }),

  computed: {
    userWarnings() {
      const warnings = []
      if (this.authUser && this.authUser.role !== 'guest' && !this.authUser.isVerified) {
        warnings.push({
          text: 'Please check your email inbox to find EOwn verification email.',
          href: '/settings/emails',
          additional: {
            text: 'You need help?',
            href: mailtoVerificationHelp
          }
        })
      }
      if (this.$helpers.get(this.authUser, 'preferences.stripe.subscriptionStatus') === 'past_due') {
        warnings.push({
          text: 'You have an unpaid invoice, please pay to continue your subscription.',
          href: '/settings/plan-billing?unpaid_invoice=1'
        })
      }
      return warnings
    }
  },

  created() {
    this.drawer = this.showDrawer
  },

  methods: {
    handleOpenMenuGroup(e, to) {
      const groupEl = e.target.closest('.v-list-group')
      if (to && groupEl && !groupEl.classList.contains('v-list-group--active')) {
        this.$router.push(to)
      }
    },
    isActiveMenu(to) {
      const currentPath = this.$route.path
      if (to === currentPath) {
        return true
      }
      if (this.$route.query.tab && to === currentPath + `?tab=${this.$route.query.tab}`) {
        return true
      }
      return false
    },
    scrollMainContent() {
      const btnScroll = document.getElementById('e-btn-scroll')
      const contentScroll = document.getElementById('main-content')
      if (btnScroll) {
        if (contentScroll.scrollTop > 100) {
          btnScroll.classList.remove('d-none')
        } else {
          btnScroll.classList.add('d-none')
        }
      }
    },
    upgradeAccount() {
      this.$nuxt.$emit('pricing/openPopup')
      this.$helpers.tractMixpanel('dashboard_upgrade_click', 'dashboard upgrade')
    }
  }
}
</script>

<style lang="scss">
.app-main .v-main__wrap {
  display: flex;
  overflow-y: auto;
}
.app-app-bar .v-toolbar__content {
  padding: 0 !important;
}
.custom-list-group {
  .v-list-group__header {
    padding-left: 24px !important;
  }
  .v-list-group__header__append-icon {
    min-width: unset !important;
    margin-left: 0 !important;
    position: absolute;
    left: 0;
    .v-icon {
      font-size: 18px;
    }
  }
  & .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: rotate(-90deg);
  }
  &.v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: rotate(0);
  }
}
.app-navigation-drawer {
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--v-secondary-base);
    .v-icon {
      color: var(--v-secondary-base);
    }
  }
  .v-list-item.v-list-item--active:not(.v-list-group__header) .v-list-item__icon .v-icon {
    color: var(--v-primary-base) !important;
  }

}
</style>

<style lang="scss" scoped>
.app-app-bar {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05) !important;
  z-index: 100 !important;
}
.app-bar-submenu {
  z-index: 1;
  overflow: inherit;
  min-width: 500px !important;
  contain: inherit;
  box-shadow: none !important;
  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    height: 100%;
    left: -2000px !important;
    right: -2000px !important;
    background-color: white;
    box-shadow: 0 13px 14px -13px rgba(0, 0, 0, .1);
  }
}
.app-bar-item {
  button {
    background-color: transparent !important;
    border-radius: 0;
    &:hover {
      background-color: transparent !important;
      &:before {
        content: none;
      }
    }
  }
  .v-list-item__content {
    padding: 0;
  }
}

.submenu-item:hover {
  color: var(--v-primary-base) !important;
  &::before {
    display: none;
  }
}
.h-30 {
  height: 60px;
}
.two-column {
  display: flex;
  flex-wrap: wrap;
  .v-list {
    width: 50%;
  }
  .text-subtitle-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
.four-column {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -100px;
  .v-list {
    width: 25%;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      opacity: .4;
    }
  }
  .text-subtitle-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media (min-width: 1500px) {
  .four-column {
    margin: 0 -470px;
  }
}
</style>
