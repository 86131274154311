import { createPopup } from '@typeform/embed'
import { onIframeClick } from './iframe-active'
import '@typeform/embed/build/css/popup.css'

export default {
  bind: (el, binding) => {
    if (!window.typeformEmbed) { return }
    const { value, modifiers } = binding
    if (modifiers.popup) {
      const { toggle } = createPopup(value)
      el.onclick = toggle
      return
    }
    window.typeformEmbed.makeWidget(el, value, {
      hideHeaders: true,
      hideFooter: true,
      opacity: 50,
      onReady() {
        const typeformIframe = el.querySelector('iframe')
        if (typeformIframe) {
          onIframeClick(typeformIframe)
        }
      }
    })
  }
}
