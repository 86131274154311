import Vue from 'vue'
import VimeoPlayer from '@vimeo/player'
import YoutubePlayer from 'youtube-player'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VJsoneditor from 'v-jsoneditor/src/index'

Vue.use(CKEditor)

Vue.use({
  install(Vue) {
    Vue.prototype.$vimeo = VimeoPlayer
  }
})

Vue.use({
  install(Vue) {
    Vue.prototype.$youtube = YoutubePlayer
  }
})

Vue.use(VJsoneditor)

export default (context, inject) => {
  if (window.Stripe) {
    const stripe = window.Stripe(context.$config.stripePublicKey)
    inject('stripe', stripe)
  }
}
