<template>
  <v-layout column>
    <v-form ref="ruleFormRef" class="px-5 pt-5 pb-1">
      <e-input label="Image">
        <EDropFileUpload
          v-model="formData.imageUrl"
          :rules="[$formRules.required]"
          :accept="IMAGE_TYPES"
          hide-details
          class="mb-6"
        >
          <template #uploaded="{ removeFile }">
            <div class="position-relative">
              <img :src="$helpers.getFilePreview(formData.imageUrl)" alt="Logo" width="300">
              <v-icon
                size="20"
                color="secondary"
                class="position-absolute bg-white pa-1 ma-1 rounded-circle right-0 top-0"
                @click="removeFile"
              >
                close
              </v-icon>
            </div>
          </template>
        </EDropFileUpload>
      </e-input>

      <e-input label="Redirect link">
        <v-text-field
          v-model="formData.link"
          dense
          outlined
          placeholder="Redirect link"
        />
      </e-input>
    </v-form>

    <v-layout class="w-full white px-5 pb-5" :class="hideLine ? '' : 'pt-5 border-t border-t-secondary border-solid'">
      <v-spacer v-if="!leftActions" />
      <v-btn
        v-if="!hideCancel"
        large
        outlined
        color="primary"
        class="mr-3 rounded-lg"
        @click="$emit('close')"
      >
        Cancel
      </v-btn>
      <v-btn
        large
        depressed
        color="primary"
        class="rounded-lg"
        :loading="$store.getters.isUploading || $store.getters.isRequesting"
        @click="save"
      >
        Save Changes
      </v-btn>
    </v-layout>
  </v-layout>
</template>

<script>
import { getFile2Send } from '@/utils/upload-s3'
import { IMAGE_TYPES } from '@/constants/files.js'
import EDropFileUpload from '@/plugins/components/e-drop-file-upload.vue'

export default {
  name: 'GettingStartedLogoBranding',

  components: {
    EDropFileUpload
  },

  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    hideCancel: {
      type: Boolean,
      default: false
    },
    hideLine: {
      type: Boolean,
      default: false
    },
    leftActions: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    IMAGE_TYPES,
    formData: {}
  }),

  async mounted() {
    if (this.value && Object.keys(this.value).length) {
      this.formData = this.$helpers.cloneDeep(this.value)
    } else {
      const { branding } = await this.$axios.$get(`/users/${this.$auth.user._id}/branding`)
      this.formData = this.$helpers.ensureObject(branding?.logo)
    }
  },

  methods: {
    async save() {
      const ok = await this.$refs.ruleFormRef.validate()
      if (!ok) { return }
      try {
        const sendData = {
          ...this.$helpers.cloneDeep(this.formData),
          ...getFile2Send(this.formData)
        }
        await this.$axios.$patch(`/users/${this.$auth.user._id}/branding/logo`, { logo: sendData })
        this.$notify.success({
          title: 'Branding Logo',
          message: 'Update successfully!'
        })
        this.$emit('saved')
      } catch (error) {
        this.$notify.error({
          title: 'Branding Logo',
          message: this.$helpers.apiErrorMessage(error)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-111 {
  top: 222px;
}
</style>
