import contentItemService from './api/content-item'
import notificationService from './api/notification'
import streamService from './api/stream'

export default function ($axios, context) {
  return {
    contentItem: contentItemService($axios, context),
    notification: notificationService($axios, context),
    stream: streamService($axios, context)
  }
}
