<template>
  <div class="h-full">
    <v-layout
      v-if="error.statusCode === 404"
      class="error-404 h-full"
      justify-center
      align-center
    >
      <div class="my-auto">
        <v-container fluid>
          <v-card class="text-center pa-10 max-w-500">
            <v-avatar size="60" class="mb-5">
              <img
                src="/logo.svg"
                alt="EOwn"
              >
            </v-avatar>

            <div class="login-head">
              <h1 class="text-h4">
                {{ pageNotFound }}
              </h1>
            </div>

            <div class="error-404-content my-10">
              <img src="~assets/images/404.svg" width="300" alt="404">
            </div>

            <div class="error-404-button">
              <p>{{ error.message }}</p>
              <v-btn to="/" large color="primary">
                Back To Home
              </v-btn>
            </div>
          </v-card>
        </v-container>
      </div>
    </v-layout>

    <v-layout
      v-if="error.statusCode === 403"
      class="error-404 h-full"
      justify-center
      align-center
    >
      <div class="my-auto">
        <v-container fluid>
          <v-card class="text-center pa-10 max-w-500">
            <v-avatar size="60" class="mb-5">
              <img
                src="/logo.svg"
                alt="EOwn"
              >
            </v-avatar>

            <div class="login-head">
              <h1 class="text-h4">
                {{ forbidden }}
              </h1>
            </div>

            <div class="error-404-content my-10">
              <img src="~assets/images/error.svg" width="300" alt="error">
            </div>

            <div class="error-404-button">
              <p>{{ error.message }}</p>
              <v-btn to="/" large color="primary">
                Back To Home
              </v-btn>
            </div>
          </v-card>
        </v-container>
      </div>
    </v-layout>

    <v-layout
      v-else
      class="error-404 h-full"
      justify-center
      align-center
    >
      <div class="my-auto">
        <v-container fluid>
          <v-card class="text-center pa-10 max-w-500">
            <v-avatar size="60" class="mb-5">
              <img
                src="/logo.svg"
                alt="EOwn"
              >
            </v-avatar>

            <div class="login-head">
              <h1 class="text-h4">
                {{ otherError }}
              </h1>
            </div>

            <div class="error-404-content my-10">
              <img src="~assets/images/error.svg" width="300" alt="error">
            </div>

            <div class="forgot-password-button">
              <p>
                {{ errorMessage }}
              </p>
              <v-btn large color="primary" @click="handleReload">
                Reload Page
              </v-btn>
            </div>
          </v-card>
        </v-container>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { get } from 'lodash'

export default {
  layout: 'blank',

  props: {
    error: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      pageNotFound: 'Oops! 404',
      forbidden: 'Your are not allowed to access this page',
      otherError: 'An error occurred'
    }
  },

  head() {
    const title = {
      404: this.pageNotFound,
      403: this.forbidden
    }[this.error.statusCode] || this.otherError
    return { title }
  },

  computed: {
    errorMessage() {
      return get(this.error, 'response.data.message') || get(this.error, 'response.data.error') || get(this.error, 'response.statusText') || this.error.message
    }
  },

  methods: {
    handleReload() {
      location.reload()
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
