import EBack from './e-back.vue'
import EConfirmation from './e-confirmation.vue'
import EConfirm from './e-confirm.vue'
import EDataTable from './e-data-table.vue'
import EDialog from './e-dialog.vue'
import EInput from './e-input.vue'
import ESearch from './e-search.vue'
import ETags from './e-tags.vue'
import ETooltip from './e-tooltip.vue'
import EInfo from './e-info.vue'
import EDatetimePicker from './e-datetime-picker.vue'
import EReadMore from './e-read-more.vue'
import EOwnLogo from './eown-logo.vue'
import EUserMenu from './e-user-menu.vue'
import EPagination from './e-pagination.vue'
import EStatus from './e-status.vue'
import EModalInput from './e-modal-input.vue'

import ELoadingIcon from '@/components/common/loading-icon.vue'
import NotificationBell from '@/components/notification/bell.vue'

import DataTableContentItem from '@/components/data-table/content-item.vue'
import DataTableStream from '@/components/data-table/stream.vue'

import FilterPeriodDropdown from '@/components/filters/period-dropdown.vue'

export default {
  EBack,
  EConfirmation,
  EConfirm,
  EDataTable,
  EDialog,
  EInput,
  ESearch,
  ETags,
  ETooltip,
  EDatetimePicker,
  EInfo,
  EReadMore,
  ELoadingIcon,
  NotificationBell,
  EOwnLogo,
  EUserMenu,
  EPagination,
  EStatus,
  EModalInput,

  // component use in data table
  DataTableContentItem,
  DataTableStream,

  FilterPeriodDropdown
}
