<template>
  <v-app class="bg-transparent">
    <v-layout column class="bg-gray-custom">
      <nuxt />
      <MainBranding :is-show-try-eown="false" theme="light" />
    </v-layout>
  </v-app>
</template>

<script>
import MainBranding from '@/components/global/main-branding.vue'

export default {
  components: {
    MainBranding
  }
}
</script>

<style lang="scss" scoped>
.bg-gray-custom {
  background-color: #EBEBEB;
}
</style>
