<template>
  <v-card class="elevation-0 border border-solid border-secondary">
    <v-card-text class="pa-6 d-flex flex-column elevation-0 overflow-x-hidden grey--text text--darken-4">
      <h3 class="mb-3 secondary--text text-subtitle-2">
        Setup your default EOwn domain
      </h3>
      <v-form ref="ruleFormRef">
        <e-input>
          <v-text-field
            v-model="formData.subdomain"
            :rules="[$formRules.required, $formRules.isValidSubdomain]"
            suffix=".myeown.io"
            dense
            outlined
            placeholder="Enter your email"
            class="mt-1"
          />
        </e-input>
      </v-form>
      <p class="mb-3 secondary--text text-caption">
        We recommend using the name of your business or something related to your niche.
      </p>
    </v-card-text>

    <v-card-actions class="px-5 py-4 elevation-0 bt-secondary">
      <v-spacer />
      <v-btn
        large
        outlined
        color="primary"
        class="px-5 rounded-lg text-button"
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="showSkip"
        large
        depressed
        color="primary"
        class="px-5 rounded-lg text-button"
        @click="skip"
      >
        Skip & Continue
      </v-btn>
      <v-btn
        large
        depressed
        color="primary"
        class="px-5 rounded-lg text-button"
        :data-cy="$dataCy.BtnPublishContentItem"
        @click="save"
      >
        Done
      </v-btn>
    </v-card-actions>

    <EPrompt
      ref="e-prompt-changes"
      version="v2"
      title="Change subdomain"
      message="Are you sure you want to continue? This will break all the links that you have shared with your previous domain."
      yes-text="Yes"
      no-text="No"
    />
  </v-card>
</template>

<script>
import EPrompt from '@/plugins/components/e-prompt.vue'

export default {
  name: 'SetupSubdomain',

  components: {
    EPrompt
  },

  props: {
    subdomain: {
      type: String,
      default: ''
    },
    showSkip: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    formData: {
      subdomain: ''
    }
  }),

  mounted() {
    this.formData.subdomain = this.subdomain
  },

  methods: {
    cancel() {
      this.$emit('cancel')
      this.formData.subdomain = this.subdomain
    },
    skip() {
      this.$emit('skip')
    },
    async save() {
      const isValid = await this.$refs.ruleFormRef.validate()
      if (!isValid) { return }
      if (!this.$helpers.get(this.$auth, 'user.hasPermissionRole')) {
        this.$nuxt.$emit('pricing/openPopup')
        this.cancel()
        return
      }
      if (this.subdomain) {
        this.$refs['e-prompt-changes'].open().then(() => {
          this.saveFormData()
        }).catch(() => {})
      } else {
        this.saveFormData()
      }
    },
    async saveFormData() {
      try {
        await this.$axios.$post(`user/${this.$auth.user._id}`, this.formData)
        this.$notify.success({
          title: 'User',
          message: 'Update subdomain'
        })
        this.$auth.fetchUser()
        this.$emit('saved')
      } catch (error) {
        this.$notify.error({
          title: 'User',
          message: this.$helpers.apiErrorMessage(error)
        })
      }
    }
  }
}
</script>

<style>

</style>
