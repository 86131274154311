import { get } from 'lodash'

export default async ({ $api, store, $auth }) => {
  if (!get($auth, 'loggedIn')) {
    store.commit('notification/updateList', {
      page: 1,
      list: null,
      isStopped: false
    })
  }
  if (get($auth, 'loggedIn') && !get(store, 'state.notification.list')) {
    await $api.notification.getNotification()
  }
}
