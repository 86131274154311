import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f8c2f41 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _04dbdc64 = () => interopDefault(import('../pages/angent-profile.vue' /* webpackChunkName: "pages/angent-profile" */))
const _3e31cc26 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _2d8b2272 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _29e735bc = () => interopDefault(import('../pages/confirm-account-deletion.vue' /* webpackChunkName: "pages/confirm-account-deletion" */))
const _3890da32 = () => interopDefault(import('../pages/cookie-declaration.vue' /* webpackChunkName: "pages/cookie-declaration" */))
const _b7ca0a56 = () => interopDefault(import('../pages/create-agent.vue' /* webpackChunkName: "pages/create-agent" */))
const _2548db98 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _041cde58 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _b6bc338c = () => interopDefault(import('../pages/membership/index.vue' /* webpackChunkName: "pages/membership/index" */))
const _0c4cc990 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _3c75b86e = () => interopDefault(import('../pages/resend-verify-email.vue' /* webpackChunkName: "pages/resend-verify-email" */))
const _ac121f0c = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _62c0122e = () => interopDefault(import('../pages/resubscribed.vue' /* webpackChunkName: "pages/resubscribed" */))
const _063bef12 = () => interopDefault(import('../pages/scheduled-publishing/index.vue' /* webpackChunkName: "pages/scheduled-publishing/index" */))
const _41296df8 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _e524c654 = () => interopDefault(import('../pages/settings/branding.vue' /* webpackChunkName: "pages/settings/branding" */))
const _630640f2 = () => interopDefault(import('../pages/settings/emails.vue' /* webpackChunkName: "pages/settings/emails" */))
const _2239bc76 = () => interopDefault(import('../pages/settings/password.vue' /* webpackChunkName: "pages/settings/password" */))
const _811d7b9c = () => interopDefault(import('../pages/settings/plan-billing.vue' /* webpackChunkName: "pages/settings/plan-billing" */))
const _6561069e = () => interopDefault(import('../pages/settings/profile.vue' /* webpackChunkName: "pages/settings/profile" */))
const _6e659a3e = () => interopDefault(import('../pages/settings/social-channel.vue' /* webpackChunkName: "pages/settings/social-channel" */))
const _6da7f739 = () => interopDefault(import('../pages/settings/subdomain.vue' /* webpackChunkName: "pages/settings/subdomain" */))
const _4ae96e4c = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _204188e8 = () => interopDefault(import('../pages/term-conditions.vue' /* webpackChunkName: "pages/term-conditions" */))
const _4bc8e880 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _a6914ecc = () => interopDefault(import('../pages/verify.vue' /* webpackChunkName: "pages/verify" */))
const _f2c0d436 = () => interopDefault(import('../pages/admin/cancellation-feedbacks.vue' /* webpackChunkName: "pages/admin/cancellation-feedbacks" */))
const _12302da4 = () => interopDefault(import('../pages/admin/checklists.vue' /* webpackChunkName: "pages/admin/checklists" */))
const _9c26c764 = () => interopDefault(import('../pages/admin/content-tags.vue' /* webpackChunkName: "pages/admin/content-tags" */))
const _1436c43c = () => interopDefault(import('../pages/admin/coupons.vue' /* webpackChunkName: "pages/admin/coupons" */))
const _4701f1d4 = () => interopDefault(import('../pages/admin/curated-contents.vue' /* webpackChunkName: "pages/admin/curated-contents" */))
const _3370fc83 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _dee1e042 = () => interopDefault(import('../pages/admin/database/index.vue' /* webpackChunkName: "pages/admin/database/index" */))
const _6c152cf2 = () => interopDefault(import('../pages/admin/form-contents.vue' /* webpackChunkName: "pages/admin/form-contents" */))
const _9df69b80 = () => interopDefault(import('../pages/admin/graphic-contents.vue' /* webpackChunkName: "pages/admin/graphic-contents" */))
const _22eda950 = () => interopDefault(import('../pages/admin/listings.vue' /* webpackChunkName: "pages/admin/listings" */))
const _0ae0c5b0 = () => interopDefault(import('../pages/admin/notes.vue' /* webpackChunkName: "pages/admin/notes" */))
const _be44c014 = () => interopDefault(import('../pages/admin/pricing-table.vue' /* webpackChunkName: "pages/admin/pricing-table" */))
const _f1b7b96c = () => interopDefault(import('../pages/admin/product-templates.vue' /* webpackChunkName: "pages/admin/product-templates" */))
const _444f3b56 = () => interopDefault(import('../pages/admin/promotional-content-templates.vue' /* webpackChunkName: "pages/admin/promotional-content-templates" */))
const _967eb0a8 = () => interopDefault(import('../pages/admin/roles.vue' /* webpackChunkName: "pages/admin/roles" */))
const _106cfaa6 = () => interopDefault(import('../pages/admin/sales-page-template.vue' /* webpackChunkName: "pages/admin/sales-page-template" */))
const _0d0055f8 = () => interopDefault(import('../pages/admin/settings.vue' /* webpackChunkName: "pages/admin/settings" */))
const _dc65cc34 = () => interopDefault(import('../pages/admin/task.vue' /* webpackChunkName: "pages/admin/task" */))
const _101197fb = () => interopDefault(import('../pages/admin/template-tags.vue' /* webpackChunkName: "pages/admin/template-tags" */))
const _536dfaa8 = () => interopDefault(import('../pages/admin/templates.vue' /* webpackChunkName: "pages/admin/templates" */))
const _ec49519a = () => interopDefault(import('../pages/admin/templates-design.vue' /* webpackChunkName: "pages/admin/templates-design" */))
const _678b5a61 = () => interopDefault(import('../pages/admin/textcopies.vue' /* webpackChunkName: "pages/admin/textcopies" */))
const _9613ed12 = () => interopDefault(import('../pages/admin/users.vue' /* webpackChunkName: "pages/admin/users" */))
const _71900c31 = () => interopDefault(import('../pages/agent/top-agents.vue' /* webpackChunkName: "pages/agent/top-agents" */))
const _f2c7be00 = () => interopDefault(import('../pages/emails/workflows/index.vue' /* webpackChunkName: "pages/emails/workflows/index" */))
const _bc6f1354 = () => interopDefault(import('../pages/manage-analytics/contacts/index.vue' /* webpackChunkName: "pages/manage-analytics/contacts/index" */))
const _5bbe495a = () => interopDefault(import('../pages/manage-analytics/contents.vue' /* webpackChunkName: "pages/manage-analytics/contents" */))
const _4fb9935a = () => interopDefault(import('../pages/manage-analytics/emails/index.vue' /* webpackChunkName: "pages/manage-analytics/emails/index" */))
const _a242ad6c = () => interopDefault(import('../pages/manage-analytics/leads/index.vue' /* webpackChunkName: "pages/manage-analytics/leads/index" */))
const _7265d22f = () => interopDefault(import('../pages/manage-analytics/listings.vue' /* webpackChunkName: "pages/manage-analytics/listings" */))
const _62aad73a = () => interopDefault(import('../pages/manage-analytics/streams.vue' /* webpackChunkName: "pages/manage-analytics/streams" */))
const _7fd75324 = () => interopDefault(import('../pages/membership/one-time-payments.vue' /* webpackChunkName: "pages/membership/one-time-payments" */))
const _bcc5f344 = () => interopDefault(import('../pages/membership/subscriptions.vue' /* webpackChunkName: "pages/membership/subscriptions" */))
const _78131024 = () => interopDefault(import('../pages/oauth/authenticate.vue' /* webpackChunkName: "pages/oauth/authenticate" */))
const _292168eb = () => interopDefault(import('../pages/paid-products/manage-customer.vue' /* webpackChunkName: "pages/paid-products/manage-customer" */))
const _30fb8771 = () => interopDefault(import('../pages/paid-products/manage-products.vue' /* webpackChunkName: "pages/paid-products/manage-products" */))
const _1ed08939 = () => interopDefault(import('../pages/streams/create.vue' /* webpackChunkName: "pages/streams/create" */))
const _aee4f28a = () => interopDefault(import('../pages/templates/content-streams/index.vue' /* webpackChunkName: "pages/templates/content-streams/index" */))
const _3e9dc23e = () => interopDefault(import('../pages/templates/content-templates.vue' /* webpackChunkName: "pages/templates/content-templates" */))
const _2230c253 = () => interopDefault(import('../pages/templates/promotional-content.vue' /* webpackChunkName: "pages/templates/promotional-content" */))
const _7131d3a5 = () => interopDefault(import('../pages/tf/verify.vue' /* webpackChunkName: "pages/tf/verify" */))
const _789d9d71 = () => interopDefault(import('../pages/users/income.vue' /* webpackChunkName: "pages/users/income" */))
const _0ee2add2 = () => interopDefault(import('../pages/admin/database/s3-images.vue' /* webpackChunkName: "pages/admin/database/s3-images" */))
const _756ce0ee = () => interopDefault(import('../pages/manage-analytics/leads/detail.vue' /* webpackChunkName: "pages/manage-analytics/leads/detail" */))
const _cd57e4a0 = () => interopDefault(import('../pages/admin/database/_model/index.vue' /* webpackChunkName: "pages/admin/database/_model/index" */))
const _92cca24e = () => interopDefault(import('../pages/agent/create/_agentId.vue' /* webpackChunkName: "pages/agent/create/_agentId" */))
const _3e60b752 = () => interopDefault(import('../pages/agent/profile/_agentId.vue' /* webpackChunkName: "pages/agent/profile/_agentId" */))
const _e6833930 = () => interopDefault(import('../pages/emails/workflows/_id.vue' /* webpackChunkName: "pages/emails/workflows/_id" */))
const _36d7ecfc = () => interopDefault(import('../pages/templates/content-streams/_tag.vue' /* webpackChunkName: "pages/templates/content-streams/_tag" */))
const _0a7bc6ee = () => interopDefault(import('../pages/manage-analytics/emails/_id/detail.vue' /* webpackChunkName: "pages/manage-analytics/emails/_id/detail" */))
const _4b128806 = () => interopDefault(import('../pages/content-items/_id.vue' /* webpackChunkName: "pages/content-items/_id" */))
const _390e4abd = () => interopDefault(import('../pages/license-key/_key.vue' /* webpackChunkName: "pages/license-key/_key" */))
const _3200ee04 = () => interopDefault(import('../pages/processing/_type.vue' /* webpackChunkName: "pages/processing/_type" */))
const _12b7f2e0 = () => interopDefault(import('../pages/streams/_id/index.vue' /* webpackChunkName: "pages/streams/_id/index" */))
const _53fb31a1 = () => interopDefault(import('../pages/paid-products/_id/paid-details.vue' /* webpackChunkName: "pages/paid-products/_id/paid-details" */))
const _5deb32e3 = () => interopDefault(import('../pages/promotional-content/_id/choose-destination.vue' /* webpackChunkName: "pages/promotional-content/_id/choose-destination" */))
const _572ff61f = () => interopDefault(import('../pages/streams/_id/editor.vue' /* webpackChunkName: "pages/streams/_id/editor" */))
const _4485ebf2 = () => interopDefault(import('../pages/streams/_id/editor-blur-component.mixin.js' /* webpackChunkName: "pages/streams/_id/editor-blur-component.mixin" */))
const _e81a4430 = () => interopDefault(import('../pages/streams/_id/editor-components.mixin.js' /* webpackChunkName: "pages/streams/_id/editor-components.mixin" */))
const _f4db1bf0 = () => interopDefault(import('../pages/streams/_id/editor-dom.mixin.js' /* webpackChunkName: "pages/streams/_id/editor-dom.mixin" */))
const _1696a77b = () => interopDefault(import('../pages/streams/_id/editor-register.mixin.js' /* webpackChunkName: "pages/streams/_id/editor-register.mixin" */))
const _07beda6e = () => interopDefault(import('../pages/streams/_id/performance.vue' /* webpackChunkName: "pages/streams/_id/performance" */))
const _7fa6e506 = () => interopDefault(import('../pages/streams/_id/preview.vue' /* webpackChunkName: "pages/streams/_id/preview" */))
const _6f0c41c6 = () => interopDefault(import('../pages/streams/_id/share.vue' /* webpackChunkName: "pages/streams/_id/share" */))
const _c147d858 = () => interopDefault(import('../pages/streams/_id/detail/form/_form.vue' /* webpackChunkName: "pages/streams/_id/detail/form/_form" */))
const _5aad04ac = () => interopDefault(import('../pages/streams/_id/detail/listing/_listing.vue' /* webpackChunkName: "pages/streams/_id/detail/listing/_listing" */))
const _4575bb7a = () => interopDefault(import('../pages/streams/_id/_contentSlug/index.vue' /* webpackChunkName: "pages/streams/_id/_contentSlug/index" */))
const _3a753b41 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _39525cdc = () => interopDefault(import('../pages/_streamSlug/index.vue' /* webpackChunkName: "pages/_streamSlug/index" */))
const _45c9fea4 = () => interopDefault(import('../pages/_streamSlug/_contentSlug/index.vue' /* webpackChunkName: "pages/_streamSlug/_contentSlug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _6f8c2f41,
    name: "admin"
  }, {
    path: "/angent-profile",
    component: _04dbdc64,
    name: "angent-profile"
  }, {
    path: "/callback",
    component: _3e31cc26,
    name: "callback"
  }, {
    path: "/change-password",
    component: _2d8b2272,
    name: "change-password"
  }, {
    path: "/confirm-account-deletion",
    component: _29e735bc,
    name: "confirm-account-deletion"
  }, {
    path: "/cookie-declaration",
    component: _3890da32,
    name: "cookie-declaration"
  }, {
    path: "/create-agent",
    component: _b7ca0a56,
    name: "create-agent"
  }, {
    path: "/forgot-password",
    component: _2548db98,
    name: "forgot-password"
  }, {
    path: "/login",
    component: _041cde58,
    name: "login"
  }, {
    path: "/membership",
    component: _b6bc338c,
    name: "membership"
  }, {
    path: "/privacy-policy",
    component: _0c4cc990,
    name: "privacy-policy"
  }, {
    path: "/resend-verify-email",
    component: _3c75b86e,
    name: "resend-verify-email"
  }, {
    path: "/reset-password",
    component: _ac121f0c,
    name: "reset-password"
  }, {
    path: "/resubscribed",
    component: _62c0122e,
    name: "resubscribed"
  }, {
    path: "/scheduled-publishing",
    component: _063bef12,
    name: "scheduled-publishing"
  }, {
    path: "/settings",
    component: _41296df8,
    name: "settings",
    children: [{
      path: "branding",
      component: _e524c654,
      name: "settings-branding"
    }, {
      path: "emails",
      component: _630640f2,
      name: "settings-emails"
    }, {
      path: "password",
      component: _2239bc76,
      name: "settings-password"
    }, {
      path: "plan-billing",
      component: _811d7b9c,
      name: "settings-plan-billing"
    }, {
      path: "profile",
      component: _6561069e,
      name: "settings-profile"
    }, {
      path: "social-channel",
      component: _6e659a3e,
      name: "settings-social-channel"
    }, {
      path: "subdomain",
      component: _6da7f739,
      name: "settings-subdomain"
    }]
  }, {
    path: "/sign-up",
    component: _4ae96e4c,
    name: "sign-up"
  }, {
    path: "/term-conditions",
    component: _204188e8,
    name: "term-conditions"
  }, {
    path: "/unsubscribe",
    component: _4bc8e880,
    name: "unsubscribe"
  }, {
    path: "/verify",
    component: _a6914ecc,
    name: "verify"
  }, {
    path: "/admin/cancellation-feedbacks",
    component: _f2c0d436,
    name: "admin-cancellation-feedbacks"
  }, {
    path: "/admin/checklists",
    component: _12302da4,
    name: "admin-checklists"
  }, {
    path: "/admin/content-tags",
    component: _9c26c764,
    name: "admin-content-tags"
  }, {
    path: "/admin/coupons",
    component: _1436c43c,
    name: "admin-coupons"
  }, {
    path: "/admin/curated-contents",
    component: _4701f1d4,
    name: "admin-curated-contents"
  }, {
    path: "/admin/dashboard",
    component: _3370fc83,
    name: "admin-dashboard"
  }, {
    path: "/admin/database",
    component: _dee1e042,
    name: "admin-database"
  }, {
    path: "/admin/form-contents",
    component: _6c152cf2,
    name: "admin-form-contents"
  }, {
    path: "/admin/graphic-contents",
    component: _9df69b80,
    name: "admin-graphic-contents"
  }, {
    path: "/admin/listings",
    component: _22eda950,
    name: "admin-listings"
  }, {
    path: "/admin/notes",
    component: _0ae0c5b0,
    name: "admin-notes"
  }, {
    path: "/admin/pricing-table",
    component: _be44c014,
    name: "admin-pricing-table"
  }, {
    path: "/admin/product-templates",
    component: _f1b7b96c,
    name: "admin-product-templates"
  }, {
    path: "/admin/promotional-content-templates",
    component: _444f3b56,
    name: "admin-promotional-content-templates"
  }, {
    path: "/admin/roles",
    component: _967eb0a8,
    name: "admin-roles"
  }, {
    path: "/admin/sales-page-template",
    component: _106cfaa6,
    name: "admin-sales-page-template"
  }, {
    path: "/admin/settings",
    component: _0d0055f8,
    name: "admin-settings"
  }, {
    path: "/admin/task",
    component: _dc65cc34,
    name: "admin-task"
  }, {
    path: "/admin/template-tags",
    component: _101197fb,
    name: "admin-template-tags"
  }, {
    path: "/admin/templates",
    component: _536dfaa8,
    name: "admin-templates"
  }, {
    path: "/admin/templates-design",
    component: _ec49519a,
    name: "admin-templates-design"
  }, {
    path: "/admin/textcopies",
    component: _678b5a61,
    name: "admin-textcopies"
  }, {
    path: "/admin/users",
    component: _9613ed12,
    name: "admin-users"
  }, {
    path: "/agent/top-agents",
    component: _71900c31,
    name: "agent-top-agents"
  }, {
    path: "/emails/workflows",
    component: _f2c7be00,
    name: "emails-workflows"
  }, {
    path: "/manage-analytics/contacts",
    component: _bc6f1354,
    name: "manage-analytics-contacts"
  }, {
    path: "/manage-analytics/contents",
    component: _5bbe495a,
    name: "manage-analytics-contents"
  }, {
    path: "/manage-analytics/emails",
    component: _4fb9935a,
    name: "manage-analytics-emails"
  }, {
    path: "/manage-analytics/leads",
    component: _a242ad6c,
    name: "manage-analytics-leads"
  }, {
    path: "/manage-analytics/listings",
    component: _7265d22f,
    name: "manage-analytics-listings"
  }, {
    path: "/manage-analytics/streams",
    component: _62aad73a,
    name: "manage-analytics-streams"
  }, {
    path: "/membership/one-time-payments",
    component: _7fd75324,
    name: "membership-one-time-payments"
  }, {
    path: "/membership/subscriptions",
    component: _bcc5f344,
    name: "membership-subscriptions"
  }, {
    path: "/oauth/authenticate",
    component: _78131024,
    name: "oauth-authenticate"
  }, {
    path: "/paid-products/manage-customer",
    component: _292168eb,
    name: "paid-products-manage-customer"
  }, {
    path: "/paid-products/manage-products",
    component: _30fb8771,
    name: "paid-products-manage-products"
  }, {
    path: "/streams/create",
    component: _1ed08939,
    name: "streams-create"
  }, {
    path: "/templates/content-streams",
    component: _aee4f28a,
    name: "templates-content-streams"
  }, {
    path: "/templates/content-templates",
    component: _3e9dc23e,
    name: "templates-content-templates"
  }, {
    path: "/templates/promotional-content",
    component: _2230c253,
    name: "templates-promotional-content"
  }, {
    path: "/tf/verify",
    component: _7131d3a5,
    name: "tf-verify"
  }, {
    path: "/users/income",
    component: _789d9d71,
    name: "users-income"
  }, {
    path: "/admin/database/s3-images",
    component: _0ee2add2,
    name: "admin-database-s3-images"
  }, {
    path: "/manage-analytics/leads/detail",
    component: _756ce0ee,
    name: "manage-analytics-leads-detail"
  }, {
    path: "/admin/database/:model",
    component: _cd57e4a0,
    name: "admin-database-model"
  }, {
    path: "/agent/create/:agentId?",
    component: _92cca24e,
    name: "agent-create-agentId"
  }, {
    path: "/agent/profile/:agentId?",
    component: _3e60b752,
    name: "agent-profile-agentId"
  }, {
    path: "/emails/workflows/:id",
    component: _e6833930,
    name: "emails-workflows-id"
  }, {
    path: "/templates/content-streams/:tag?",
    component: _36d7ecfc,
    name: "templates-content-streams-tag"
  }, {
    path: "/manage-analytics/emails/:id?/detail",
    component: _0a7bc6ee,
    name: "manage-analytics-emails-id-detail"
  }, {
    path: "/content-items/:id?",
    component: _4b128806,
    name: "content-items-id"
  }, {
    path: "/license-key/:key?",
    component: _390e4abd,
    name: "license-key-key"
  }, {
    path: "/processing/:type?",
    component: _3200ee04,
    name: "processing-type"
  }, {
    path: "/streams/:id",
    component: _12b7f2e0,
    name: "streams-id"
  }, {
    path: "/paid-products/:id?/paid-details",
    component: _53fb31a1,
    name: "paid-products-id-paid-details"
  }, {
    path: "/promotional-content/:id?/choose-destination",
    component: _5deb32e3,
    name: "promotional-content-id-choose-destination"
  }, {
    path: "/streams/:id?/editor",
    component: _572ff61f,
    name: "streams-id-editor"
  }, {
    path: "/streams/:id?/editor-blur-component.mixin",
    component: _4485ebf2,
    name: "streams-id-editor-blur-component.mixin"
  }, {
    path: "/streams/:id?/editor-components.mixin",
    component: _e81a4430,
    name: "streams-id-editor-components.mixin"
  }, {
    path: "/streams/:id?/editor-dom.mixin",
    component: _f4db1bf0,
    name: "streams-id-editor-dom.mixin"
  }, {
    path: "/streams/:id?/editor-register.mixin",
    component: _1696a77b,
    name: "streams-id-editor-register.mixin"
  }, {
    path: "/streams/:id?/performance",
    component: _07beda6e,
    name: "streams-id-performance"
  }, {
    path: "/streams/:id?/preview",
    component: _7fa6e506,
    name: "streams-id-preview"
  }, {
    path: "/streams/:id?/share",
    component: _6f0c41c6,
    name: "streams-id-share"
  }, {
    path: "/streams/:id?/detail/form/:form?",
    component: _c147d858,
    name: "streams-id-detail-form-form"
  }, {
    path: "/streams/:id?/detail/listing/:listing?",
    component: _5aad04ac,
    name: "streams-id-detail-listing-listing"
  }, {
    path: "/streams/:id?/:contentSlug",
    component: _4575bb7a,
    name: "streams-id-contentSlug"
  }, {
    path: "/",
    component: _3a753b41,
    name: "index"
  }, {
    path: "/:streamSlug",
    component: _39525cdc,
    name: "streamSlug"
  }, {
    path: "/:streamSlug/:contentSlug",
    component: _45c9fea4,
    name: "streamSlug-contentSlug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
