import { watch } from '@vue/composition-api'
import { get } from 'lodash'
import mixpanel from 'mixpanel-browser'

export default function (context) {
  const identify = (userEmail) => {
    if (!userEmail) { return }
    const userDisplayName = get(context, 'store.state.auth.user.profile.fullName', userEmail)

    // mixpanel
    mixpanel.identify(userEmail)
    mixpanel.people.set({
      $name: userDisplayName,
      $email: userEmail
    })

    // gtag
    context.$gtag.config({
      user_id: userEmail
    })
    context.$gtag.set({
      user_properties: {
        user_id: userEmail,
        user_email: userEmail,
        user_display_name: userDisplayName
      }
    })
  }
  identify(get(context, 'store.state.auth.user.email'))
  watch(() => {
    return get(context, 'store.state.auth.user.email')
  }, (userEmail) => {
    identify(userEmail)
  })
}
