<template>
  <v-layout column align-end>
    <div>
      <v-chip
        v-if="promotionCodeData"
        class="ma-2"
        close
        color="primary"
        label
        text-color="white"
        @click:close="removePromotionCode"
      >
        {{ promotionCode }}
      </v-chip>
      <v-text-field
        v-else
        v-model="promotionCode"
        label="Promotion code"
        type="text"
        dense
        hide-details
        outlined
        height="20"
        size="15"
        @keypress.enter="getPromotionCodeData"
      >
        <template #append>
          <v-btn
            color="primary"
            text
            class="ma-0"
            small
            @click="getPromotionCodeData"
          >
            Apply
          </v-btn>
        </template>
      </v-text-field>
    </div>
    <div>
      Discount: <span class="font-weight-medium success--text">{{ discountPercent }}%</span>
      <v-tooltip v-if="!promotionCode && !subscriptionData" top color="grey darken-4">
        <template #activator="{ on, attrs }">
          <v-icon
            size="20"
            v-bind="attrs"
            color="grey darken-3"
            v-on="on"
          >
            info
          </v-icon>
        </template>
        <span>Discount 10% when subscribing 2 plans.<br>Discount 15% when subscribing 3 plans.</span>
      </v-tooltip>
    </div>
    <div>
      <span>Total: </span>
      <span
        v-if="discountPercent"
        class="grey--text text-decoration-line-through text-h6"
      >{{ originalPrice | formatCurrency }}</span>
      <span class="text-h5">{{ estimatedPrice | formatCurrency }}</span>
      <span>/{{ subscribeType === "annual" ? "year" : "month" }}</span>
    </div>
  </v-layout>
</template>

<script>
import { get, sum, flatten } from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'PricingTotal',
  props: {
    code: {
      type: String,
      default: ''
    },
    selectedPlans: {
      type: Array,
      default: () => []
    },
    subscribeType: {
      type: String,
      default: 'monthly'
    },
    pricingConstant: {
      type: Object,
      default: () => ({ })
    }
  },
  data: () => ({
    promotionCode: '',
    promotionCodeData: null
  }),
  computed: {
    ...mapState({
      subscriptionData: (state) => {
        if (get(state, 'auth.user.preferences.stripe.subscription')) {
          return get(state, 'auth.user.preferences.stripe.subscriptionData')
        }
        return null
      }
    }),
    mainPlans() {
      return (this.selectedPlans || []).filter(pricing => get(this.pricingConstant, 'mainRoles', []).includes(get(pricing, 'key')))
    },
    selectedMainPlans() {
      return (this.mainPlans || []).filter(item => item.selected)
    },
    discountPercent() {
      if (!this.selectedPlans?.length) { return 0 }

      if (this.promotionCodeData) {
        return get(this.promotionCodeData, 'coupon.percent_off', 0)
      }

      if (this.subscriptionData) {
        return get(this.subscriptionData, 'discount.coupon.percent_off', 0)
      }

      const discountPercentConfig = get((this.mainPlans || []), '[0].discountPercentConfig', {})
      const max = Math.max(...Object.keys(discountPercentConfig).map(Number))
      return (this.selectedMainPlans.length >= max ? discountPercentConfig[max] : discountPercentConfig[this.selectedMainPlans.length]) || 0
    },
    selectedPrices() {
      const prices = this.selectedPlans.map((item) => {
        const price = [this.subscribeType === 'annual' ? item.annualPrice : item.monthlyPrice]
        if (item.quantity && item.quantity > 1) {
          const additionalPrice = this.subscribeType === 'annual' ? item.annualAdditionalPrice : item.monthlyAdditionalPrice
          price.push((item.quantity - 1) * (additionalPrice || 0))
        }
        return price
      })
      return flatten(prices)
    },
    originalPrice() {
      if (!this.selectedPlans?.length) { return 0 }

      const totalPrice = sum(this.selectedPrices)
      return totalPrice
    },
    discountAmount() {
      return get(this.subscriptionData, 'discount.amount_off', 0) || get(this.promotionCodeData, 'coupon.amount_off', 0) || (this.originalPrice * this.discountPercent / 100)
    },
    estimatedPrice() {
      return this.originalPrice - this.discountAmount
    }
  },
  watch: {
    code(value) {
      this.promotionCode = value
      if (value) {
        this.getPromotionCodeData()
      } else {
        this.promotionCodeData = null
      }
    }
  },
  methods: {
    async getPromotionCodeData() {
      try {
        if (!this.promotionCode) { return }
        const data = await this.$axios.$get(`/promotion-codes/${this.promotionCode}`)
        this.promotionCodeData = data
      } catch (error) {
        this.$helpers.notifyError(error)
        this.promotionCodeData = null
      }
      this.$emit('promotionCodeApplied', this.promotionCode)
    },
    removePromotionCode() {
      this.promotionCodeData = null
      this.$emit('promotionCodeApplied', null)
    }
  }
}
</script>

<style>
</style>
