export const state = () => ({
  uploadFile: null
})

export const mutations = {
  setUploadFile(state, uploadFile) {
    state.uploadFile = uploadFile
  },
  unsetUploadFile(state) {
    state.uploadFile = null
  }
}
