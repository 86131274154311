export const state = () => ({
  connectAccount: null,
  flagReloadChecklist: 0,
  userPricingPromise: null,
  planHandlerActions: []
})

export const getters = {
  connectAccount(state) {
    return state.connectAccount
  },
  flagReloadChecklist(state) {
    return state.flagReloadChecklist
  },
  userPricingPromise(state) {
    return state.userPricingPromise
  },
  planHandlerActions(state) {
    return state.planHandlerActions
  }
}

export const mutations = {
  setConnectAccount(state, connectAccount) {
    state.connectAccount = connectAccount
  },
  setFlagReloadChecklist(state) {
    state.flagReloadChecklist = state.flagReloadChecklist + 1
  },
  setUserPricingPromise(state, promise) {
    state.userPricingPromise = promise
  },
  addPlanHandlerAction(state, { name, params }) {
    state.planHandlerActions.push({
      name, params
    })
  }
}

export const actions = {}
