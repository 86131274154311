const ARCHIVE_TYPES = ['zip', 'rar', 'gz', 'tar', '7z']

const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp']

const AUDIO_TYPES = ['mp3', 'wav', 'ogg', 'aac', 'flac', 'm4a', 'wma']

const VIDEO_TYPES = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm']

const SPREADSHEET_TYPES = ['xls', 'xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xlt', 'csv']

const PRESENTATION_TYPES = ['ppt', 'pptx', 'pptm', 'ppsx', 'ppsm', 'pps', 'potx', 'potm', 'pot']

const DOCUMENT_TYPES = ['doc', 'docx', 'docm', 'dotx', 'dotm', 'dot']

const TEXT_TYPES = ['txt', 'rtf']

const PDF_TYPES = ['pdf']

const ALL_TYPES = [
  ...ARCHIVE_TYPES,
  ...IMAGE_TYPES,
  ...AUDIO_TYPES,
  ...VIDEO_TYPES,
  ...SPREADSHEET_TYPES,
  ...PRESENTATION_TYPES,
  ...DOCUMENT_TYPES,
  ...TEXT_TYPES,
  ...PDF_TYPES
]

export {
  ARCHIVE_TYPES,
  IMAGE_TYPES,
  AUDIO_TYPES,
  VIDEO_TYPES,
  SPREADSHEET_TYPES,
  PRESENTATION_TYPES,
  DOCUMENT_TYPES,
  TEXT_TYPES,
  PDF_TYPES,
  ALL_TYPES
}
