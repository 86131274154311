import { fromPairs, cloneDeep, isEqual, get } from 'lodash'

export const state = () => ({
  oldStream: null,
  stream: null,
  forceRenderHeader: 0,
  showingStream: null,
  showingContentItems: null,
  flagRefreshStream: 0
})

export const getters = {
  hasChange(state) {
    return !isEqual(state.oldStream, state.stream)
  },
  isStreamTemplate(state) {
    return state.stream && state.stream.stream_type === 'Template'
  },
  isSinglePropertyPage(state) {
    const { stream } = state
    return stream && (stream.streamTheme === 'single-property' || (stream.stream_type === 'Template' && stream.originalContentItem))
  },
  isBiolink(state) {
    const { stream } = state
    return stream && stream.streamTheme === 'biolink'
  },
  isNewsletter(state) {
    const { stream } = state
    return stream && stream.streamTheme === 'newsletter'
  },
  tags(state) {
    if (state.stream && Array.isArray(state.stream.template_tags) && state.stream.template_tags.length) {
      return state.stream.template_tags
    }
    return []
  },
  gatedContent(state) {
    return {
      _id: get(state, 'stream._id'),
      ...get(state, 'stream.gatedContent')
    }
  },
  gatedContentEnabled(state) {
    return get(state, 'stream.gatedContent.enabled', false)
  },
  showingStream(state) {
    return state.showingStream
  },
  showingContentItems(state) {
    return state.showingContentItems
  },
  flagRefreshStream(state) {
    return state.flagRefreshStream
  },
  forceRenderHeader(state) {
    return state.forceRenderHeader
  },
  contentItems(state) {
    return get(state, 'stream.contentItems')
  }
}

export const mutations = {
  setStream(state, stream) {
    if (!state.stream) {
      state.oldStream = cloneDeep(stream)
    }
    state.stream = cloneDeep(stream)
  },
  updateStream(state, updateData) {
    state.stream = { ...state.stream, ...updateData }
  },
  setTags(state, tags) {
    if (!state.stream) {
      state.stream = {}
    }
    state.stream.template_tags = tags
  },
  updateForceRenderHeader(state) {
    state.forceRenderHeader++
  },
  setShowing(state, data) {
    state.showingStream = data.stream
    state.showingContentItems = fromPairs(data.contentItems.map(item => [item._id, item]))
  },
  setFlagRefreshStream(state) {
    state.flagRefreshStream++
  }
}

export const actions = {}
