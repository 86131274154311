import { get } from 'lodash'

export default {
  required: (value, msg = 'This field is required') => {
    let result = !!value || value === 0 || msg
    if (Array.isArray(value) && value.length === 0) {
      result = msg
    }
    return result
  },
  email: (value) => {
    let result = true
    const reg = /.+@.+\..+/i
    if (value && !reg.test(value)) {
      result = 'Please enter valid email address'
    }
    return result
  },
  password: (value) => {
    let result = true
    if (value && !(value.length >= 8 && /(?=.*[A-Z])/.test(value) && /(?=.*\d)/.test(value))) {
      result = 'Password must be at least 8 characters long contain a number and an uppercase letter'
    }
    return result
  },
  confirmPassword: (value, valueConfirm) => {
    let result = true
    if (value !== valueConfirm) {
      result = 'The password confirmation does not match'
    }
    return result
  },
  min: (value, minValue) => {
    let result = true
    if (!isNaN(value) && value) {
      result = !!(parseInt(value) >= minValue) || ('Min value is ' + minValue)
    }
    return result
  },
  max: (value, maxValue) => {
    let result = true
    if (!isNaN(value) && value) {
      result = !!(parseInt(value) <= maxValue) || ('Max value is ' + maxValue)
    }
    return result
  },
  greaterThan: (value, minValue) => {
    let result = true
    if (!isNaN(value) && value) {
      result = !!(Number(value) > minValue) || ('Value must greater than ' + minValue)
    }
    return result
  },
  validLink: (value) => {
    let result = true
    if (value && !/^(http|https):\/\/[^ "]+$/.test(value)) {
      result = 'Please enter valid link'
    }
    return result
  },
  phone: (value) => {
    let result = true
    if (value && (value.length !== 10 || /[^0-9]/.test(value))) {
      result = 'Phone number incorrect'
    }
    return result
  },
  minArraySize: (value, minSize = 0) => {
    return (get(value, 'length', 0) >= minSize) || ('This field must have at least ' + minSize + ' element')
  },
  charLength: (value, max) => {
    let result = true
    if (value && value.length && max < 0) {
      return false
    }
    if (value && value.length > max) {
      result = 'Max length is ' + max
    }
    return result
  },
  isValidSubdomain: (subdomain) => {
    let result = true
    // Regular expression for a valid subdomain
    const subdomainRegex = /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/
    const trimmedSubdomain = subdomain ? subdomain.trim() : ''
    if (trimmedSubdomain) {
      if (trimmedSubdomain.length > 63) {
        result = 'Subdomain must be less than 63 characters'
      } else if (!subdomainRegex.test(trimmedSubdomain)) {
        result = 'Subdomain must contain only letters, numbers, hyphens and cannot begin or end with a hyphen'
      }
    }
    return result
  }
}
