<template>
  <v-app>
    <v-main class="d-flex position-relative h-screen common-module">
      <nuxt class="min-w-960" />
      <PopupLimitPermission class="position-absolute" />
      <PopupPricingPlan class="position-absolute" />
      <PricingPlanHandler class="position-absolute" />
    </v-main>
  </v-app>
</template>

<script>
import PopupLimitPermission from '@/components/auth/popups/popup-limit-permission.vue'
import PopupPricingPlan from '@/components/auth/popups/popup.pricing-plan.vue'
import PricingPlanHandler from '@/components/auth/pricing-plan-handler.vue'

export default {
  components: {
    PopupLimitPermission,
    PopupPricingPlan,
    PricingPlanHandler
  }
}
</script>

<style lang="scss">
.common-module .v-main__wrap {
  display: flex;
  flex-direction: column;
  background: #eef0f2;
  overflow-y: auto;
}
</style>
