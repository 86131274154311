const dataCy = {
  BtnCreateContentStream: 'BtnCreateContentStream',
  BtnGeneralSaleMarketing: 'BtnGeneralSaleMarketing',
  BtnPaidProduct: 'BtnPaidProduct',
  BtnSinglePropertyWebsite: 'BtnSinglePropertyWebsite',
  BtnBiolink: 'BtnBiolink',
  BtnNewsletter: 'BtnNewsletter',
  TxtUseTemplateTitle: 'TxtUseTemplateTitle',
  BtnUseThisTemplate: 'BtnUseThisTemplate',
  BtnPreviewThisTemplate: 'BtnPreviewThisTemplate',
  BtnUseToCreateThisTemplate: 'BtnUseToCreateThisTemplate',
  IconContent: 'IconContent',
  IconGlobalStyle: 'IconGlobalStyle',
  IconComponentStyles: 'IconComponentStyles',
  IconAddBlock: 'IconAddBlock',
  IconBlockSetting: 'IconBlockSetting',
  BtnAddContentT: 'BtnAddContent',
  BtnAssign: 'BtnAssign',
  IconCloseArticle: 'IconCloseArticle',
  BtnSave: 'BtnSave',
  BtnSaveAddLibrary: 'BtnSaveAddLibrary',
  LnkMenu: 'LnkMenu',
  ChkArticleItem: 'ChkArticleItem',
  ImgArticleItem: 'ImgArticleItem',
  TxtTitleArticle: 'TxtTitleArticle',
  TxtDesArticle: 'TxtDesArticle',
  BtnCancelArticle: 'BtnCancelArticle',
  BtnChooseArticle: 'BtnChooseArticle',
  BtnMenu: 'BtnMenu',
  BtnSubMenu: 'BtnSubMenu',
  IconClose: 'IconClose',
  RadUpload: 'RadUpload',
  RadLibrary: 'RadLibrary',
  IconCanva: 'IconCanva',
  IconLink: 'IconLink',
  IconUnsplash: 'IconUnsplash',
  TxtWeblinkArticle: 'TxtWeblinkArticle',
  CbxFontFamily: 'CbxFontFamily',
  TxtBGColor: 'TxtBGColor',
  BtnWebLink: 'BtnWebLink',
  BtnPublish: 'BtnPublish',
  IcoShareSocialChannel: 'IcoShareSocialChannel',
  IcoSaveStream: 'IcoSaveStream',
  IcoPaidProduct: 'IcoPaidProduct',
  BtnPublishContentItem: 'BtnPublishContentItem',
  BtnYesSaveMyWork: 'BtnYesSaveMyWork',
  BtnPickTemplateInGlobal: 'BtnPickTemplateInGlobal',
  BtnYesUseDefaultTemplate: 'BtnYesUseDefaultTemplate',
  TabSEOSetting: 'TabSEOSetting',
  TabShareLinks: 'TabShareLinks',
  TabSchedulePromotionalPosts: 'TabSchedulePromotionalPosts',
  TxtSlugTitle: 'TxtSlugTitle',
  BtnSaveSlugTitle: 'BtnSaveSlugTitle',
  TxtSEOTitle: 'TxtSEOTitle',
  TxtMetaDescription: 'TxtMetaDescription',
  RadioUpload: 'RadioUpload',
  RadioChooseFromGraphicLibrary: 'RadioChooseFromGraphicLibrary',
  BtnSaveSetting: 'BtnSaveSetting',
  BtnUseImage: 'BtnUseImage',
  LnkCopySocialLink: 'LnkCopySocialLink',
  BtnAddSchedulePromationalPosts: 'BtnAddSchedulePromationalPosts',
  BtnUseThisContent: 'BtnUseThisContent',
  TxtPromotionalTemplate: 'TxtPromotionalTemplate',
  TxtAddedPromotionalTitle: 'TxtAddedPromotionalTitle',
  TxtAddedPromotionalStatus: 'TxtAddedPromotionalStatus',
  BtnPromotionalButtonMenu: 'BtnPromotionalButtonMenu',
  LnkMenuPublish: 'LnkMenuPublish',
  LnkMenuEdit: 'LnkMenuEdit',
  LnkMenuArchive: 'LnkMenuArchive',
  DivConnectedFacebook: 'DivConnectedFacebook',
  DivConnectedLinkedin: 'DivConnectedLinkedin',
  DivConnectedInstagram: 'DivConnectedInstagram',
  BtnPromotionalPublishNow: 'BtnPromotionalPublishNow',
  DivAnalyseFacebook: 'DivAnalyseFacebook',
  DivAnalyseLinkedin: 'DivAnalyseLinkedin',
  DivAnalyseInstagram: 'DivAnalyseInstagram',
  DivAnalyseFacebookAds: 'DivAnalyseFacebookAds',
  DivAnalyseViews: 'DivAnalyseViews',
  BtnAddPromotionalPosts: 'BtnAddPromotionalPosts'
}

module.exports = dataCy
