<template>
  <v-layout class="e-drop-file-upload">
    <v-layout
      v-if="(!multiple && !file) || multiple"
      :class="['drop-zone', dragging ? 'drop-zone-over' : '']"
      @drop="dropHandler"
      @dragover.prevent="dragOverHandler"
      @dragenter="dragEnterHandler"
      @dragleave="dragLeaveHandler"
    >
      <v-input
        v-if="!$slots.upload"
        :value="file"
        :rules="rules"
        hide-details
        class="px-5 pt-5 pb-8 btn-upload"
      >
        <v-icon size="80" class="pb-2 drop-zone-title">
          cloud_upload
        </v-icon>
        <p class="mb-2 drop-zone-title text-subtitle-1 text-center">
          {{ multiple ? 'Drop files here or click to upload' : 'Drop file here or click to upload' }}
        </p>
        <v-layout column class="text-center">
          <p class="mb-0 grey--text text--darken-1">
            Support {{ customAcceptText || acceptText || '' }} file only
          </p>
          <p class="grey--text text--darken-1">
            Maximum Upload File Size: 25MB
          </p>
        </v-layout>
        <v-btn depressed class="v-btn-default rounded-lg text-caption font-weight-bold mx-auto">
          Upload from Computer
        </v-btn>
      </v-input>
      <slot name="upload" />

      <input
        type="file"
        :multiple="multiple"
        :accept="acceptText"
        @change="onChange"
      >

      <v-overlay :value="$store.getters.isUploading" absolute>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
          class="absolute-center"
        />
      </v-overlay>
    </v-layout>

    <template v-else>
      <v-layout v-if="!hideDetails" class="px-5 py-4 bg-gray-200 rounded-lg align-center position-relative">
        <img :src="getFileIcon(file.filePath || file.url || file.name)" alt="document type" class="h-27 mr-3">
        <p class="mb-0 text-subtitle-1">
          {{ getName(file.filePath || file.url || file.name) }}
        </p>
        <div class="position-absolute right-10">
          <v-icon @click="removeFile">
            close
          </v-icon>
        </div>
      </v-layout>
      <slot name="uploaded" :remove-file="removeFile" />
    </template>
  </v-layout>
</template>

<script>
import { uploadFile2S3 } from '@/utils/upload-s3'
import { getName, getFileIcon } from '@/utils/file'

export default {
  name: 'EDropFileUpload',

  props: {
    value: {
      type: [Object, Array],
      default: () => null
    },
    rules: {
      type: Array,
      default: () => []
    },
    accept: {
      type: Array,
      default: () => []
    },
    customAcceptText: {
      type: String,
      default: ''
    },
    multiple: Boolean,
    hideDetails: Boolean
  },

  data: () => ({
    file: null,
    dragging: false
  }),

  computed: {
    acceptText() {
      return this.accept.map(type => `.${type}`).join(', ')
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.file = val
      }
    }
  },

  methods: {
    getName,
    getFileIcon,
    dragOverHandler(event) {
      event.dataTransfer.dropEffect = 'copy'
      if (!this.$store.getters.isUploading) {
        this.dragging = true
      }
    },
    dragEnterHandler() {
      if (!this.$store.getters.isUploading) {
        this.dragging = true
      }
    },
    dragLeaveHandler() {
      this.dragging = false
    },
    dropHandler(event) {
      event.preventDefault()
      if (!this.$store.getters.isUploading) {
        const files = event.dataTransfer.files
        if (!files.length) {
          this.dragging = false
          return
        }
        this.uploadFiles(files)
      }
    },
    onChange(e) {
      if (!this.$store.getters.isUploading) {
        const files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          this.dragging = false
          return
        }
        this.uploadFiles(files)
      }
    },
    isValidUploadFile(file) {
      if (file && this.accept.length) {
        const ext = file.name.split('.').pop()
        return this.accept.includes(ext)
      }
      return true
    },
    uploadFiles(files) {
      if (this.multiple) {
        for (let i = 0; i < files.length; i++) {
          if (this.isValidUploadFile(files[i])) {
            this.uploadFile(files[i])
          }
        }
      } else {
        this.uploadFile(files[0])
      }
      this.dragging = false
    },
    async uploadFile(file) {
      try {
        if (!this.isValidUploadFile(file)) {
          this.$notify.error({
            title: 'File',
            message: `Unsupported file type. Please upload ${this.acceptText} file only`
          })
          return
        }
        if (file) {
          const data = await uploadFile2S3(file)
          this.file = this.multiple ? [...(this.file || []), data] : data
          this.$emit('input', this.file)
        }
      } catch (error) {
        this.$notify.error({ title: 'Upload', message: this.$helpers.apiErrorMessage(error) })
      }
    },
    removeFile() {
      this.file = null
      this.$emit('input', this.file)
    }
  }
}
</script>

<style lang="scss">
.e-drop-file-upload .btn-upload {
  .v-input__control .v-input__slot {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &.error--text {
    border-color: var(--v-error-base) !important;
  }
}
</style>

<style lang="scss" scoped>
.drop-zone {
  min-height: 200px;
  position: relative;
  .btn-upload {
    border: 2px dashed var(--v-gray-base);
  }
}

.drop-zone-over .btn-upload {
  background: #5C5C5C;
  opacity: 0.8;
}

.drop-zone-title {
  color: #757575;
}

.drop-zone:hover {
  .btn-upload {
    border: 2px solid var(--v-primary-base);
  }
  .drop-zone-title {
    color: var(--v-primary-base);
  }
}

.drop-zone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
