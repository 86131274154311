<template>
  <v-layout column>
    <v-form ref="form" class="row overflow-y-auto mb-15 pa-5">
      <v-layout column class="col-4" align-center>
        <v-avatar size="106" class="mb-3">
          <img :src="$helpers.getFilePreview(formData.avatar) || require('~/assets/images/avatar.png')">
        </v-avatar>
        <EDialogUploadAndCropPhoto
          :width="200"
          :height="200"
          class="flex-0-0"
          @change="uploadAvatar"
        >
          <template slot="upload">
            <v-btn
              large
              depressed
              :loading="$store.getters.isUploading"
              class="v-btn-default mt-0"
            >
              Upload Avatar
            </v-btn>
          </template>
        </EDialogUploadAndCropPhoto>
        <v-text-field
          v-model="formData.avatar"
          height="0"
          flat
          dense
          type="hidden"
          :rules="[$formRules.required]"
        />
      </v-layout>

      <v-layout column class="col-8">
        <e-input label="Name">
          <v-text-field
            v-model="formData.name"
            dense
            outlined
            placeholder="Name"
            :rules="[$formRules.required]"
          />
        </e-input>

        <e-input label="Email">
          <v-text-field
            v-model="formData.email"
            dense
            outlined
            placeholder="Email"
            :rules="[$formRules.required, $formRules.email]"
          />
        </e-input>

        <e-input label="Phone Number">
          <v-text-field
            v-model="formData.phone"
            dense
            outlined
            placeholder="Phone Number"
            :rules="[$formRules.required, $formRules.phone]"
          />
        </e-input>

        <e-input label="License Number">
          <v-text-field
            v-model="formData.certificateNo"
            dense
            outlined
            placeholder="License Number"
            :rules="[$formRules.required]"
          />
        </e-input>

        <e-input label="Time Zone">
          <v-autocomplete
            v-model="formData.timezone"
            :items="timezone"
            item-value="abbreviation"
            dense
            outlined
            :rules="[$formRules.required]"
            placeholder="Time Zone"
            class="validate-placeholder"
          />
        </e-input>

        <e-confirm
          ref="syncAvatarConfirm"
          head-line="Sync avatar?"
          head-text="Do you want to sync the avatar to all stream headers, contact forms?"
          no-text="No"
          yes-text="Yes"
          @no="saveData(false)"
          @yes="saveData(true)"
        />
      </v-layout>
    </v-form>

    <v-layout class="w-full position-absolute bottom-0 white pa-5 border-t border-t-secondary border-solid">
      <v-spacer />
      <v-btn
        large
        depressed
        outlined
        color="primary"
        class="mr-3"
        @click="$emit('close')"
      >
        Cancel
      </v-btn>
      <v-btn
        large
        depressed
        color="primary"
        :loading="$store.getters.isUploading || $store.getters.isRequesting"
        @click="doBeforeSaveData"
      >
        Save Changes
      </v-btn>
    </v-layout>
  </v-layout>
</template>

<script>
import { get } from 'lodash'
import { getFileUrl, uploadFile2S3 } from '@/utils/upload-s3'
import EDialogUploadAndCropPhoto from '@/plugins/components/e-dialog-upload-and-crop-photo.vue'

export default {
  components: {
    EDialogUploadAndCropPhoto
  },

  data: () => ({
    formData: {},
    timezone: [
      { abbreviation: 'HST', offset: -10 },
      { abbreviation: 'AKST', offset: -9 },
      { abbreviation: 'PST', offset: -8 },
      { abbreviation: 'MST', offset: -7 },
      { abbreviation: 'CST', offset: -6 },
      { abbreviation: 'EST', offset: -5 }
    ],
    timeInterval: null,
    isAvatarChanged: false
  }),

  mounted() {
    this.formData = this.$helpers.cloneDeep(this.$helpers.get(this.$auth, 'user.contact', {}))
    if (!this.formData.name) {
      this.formData.name = this.$helpers.get(this.$auth, 'user.profile.name')
    }
    if (!this.formData.email) {
      this.formData.email = this.$helpers.get(this.$auth, 'user.email')
    }
    this.timezone = this.timezone.map((tz) => {
      tz.text = `${tz.abbreviation}(${this.calcTime(tz.offset)})`
      return tz
    })
    this.timeInterval = setInterval(() => {
      this.timezone = this.timezone.map((tz) => {
        tz.text = `${tz.abbreviation}(${this.calcTime(tz.offset)})`
        return tz
      })
    }, 60000) // update every minute
  },

  beforeDestroy() {
    clearInterval(this.timeInterval)
  },

  methods: {
    calcTime(offset) {
      const date = new Date()
      const utc = date.getTime() + (date.getTimezoneOffset() * 60000)
      const newDate = new Date(utc + (3600000 * offset))
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    },

    doBeforeSaveData() {
      if (!this.$refs.form.validate()) { return }
      if (this.isAvatarChanged && this.formData.avatar) {
        this.$refs.syncAvatarConfirm.clickHandle()
      } else {
        this.saveData(false)
      }
    },

    async saveData(syncAvatar = true) {
      try {
        await this.$axios.$put(
          '/users/checklists/profile-branding',
          {
            ...this.formData,
            avatar: getFileUrl(this.formData.avatar) || get(this.formData, 'avatar.filePath'),
            syncAvatar
          }
        )
        this.$auth.fetchUser()
        this.$helpers.notifySuccess('Update successfully!')
        this.$emit('close')
        setTimeout(() => {
          this.$store.commit('user/setFlagReloadChecklist')
        }, 3000)
      } catch (error) {
        this.$helpers.notifyError(error)
      }
    },

    async uploadAvatar({ file, fileName }) {
      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('fileName', fileName)
        try {
          const data = await uploadFile2S3(file)
          this.formData.avatar = data
          this.isAvatarChanged = true
        } catch (error) {
          this.$helpers.notifyError(this.$helpers.apiErrorMessage(error))
        }
      }
    }
  }
}
</script>

<style>

</style>
