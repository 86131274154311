<template>
  <v-dialog
    v-model="dialog"
    :content-class="'e-dialog rounded-lg overflow-hidden ' + contentClass"
    :max-width="dialogSize || maxWidth"
    :persistent="persistent"
    :retain-focus="retainFocus"
    :style="{ minHeight: minHeight ? minHeight + 'px' : '' }"
    width="100%"
    scrollable
    @input="hide"
  >
    <v-card class="wrap-content position-relative" :class="{ 'fill-height-content': fillHeight }">
      <v-card-title
        v-if="title || $slots.title || dense"
        class="elevation-0 grey--text text--darken-4"
        :class="[titleLine ? 'bb-secondary': '', dense ? 'px-4 pt-4 pb-0' : 'px-5 py-3']"
      >
        <h1 v-if="title" class="text-subtitle-1 font-weight-medium">
          <v-btn
            v-if="backAction"
            text
            small
            color="primary"
            @click="$emit('onBack')"
          >
            <v-icon>keyboard_backspace</v-icon>
          </v-btn>
          {{ title }}
        </h1>
        <slot name="title" />
        <v-spacer />
        <slot name="title-actions" />
        <v-icon
          v-if="!hideClose"
          class="btn-close"
          :data-cy="$dataCy.IconClose + title.replace(/\s|\//g, '')"
          @click="hide"
        >
          close
        </v-icon>
      </v-card-title>

      <v-card-text ref="dialogContentRef" class="d-flex flex-column pa-0 elevation-0 overflow-x-hidden grey--text text--darken-4">
        <slot name="default" />
      </v-card-text>

      <v-card-actions
        v-if="$slots.actions || useDefaultActions"
        class="px-5 py-4 elevation-0"
        :class="{ 'bt-secondary': actionLine }"
      >
        <slot name="actions" />
        <template v-if="useDefaultActions">
          <v-spacer />
          <v-btn
            large
            outlined
            color="primary"
            class="px-5 rounded-lg"
            @click="hide"
          >
            Cancel
          </v-btn>
          <v-btn
            large
            depressed
            color="primary"
            class="px-5 rounded-lg"
            @click="save"
          >
            Save
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EDialog',

  props: {
    value: {
      type: Boolean,
      required: true
    },
    actionLine: { // Adds top border to v-card-actions
      type: Boolean,
      default: true
    },
    contentClass: {
      type: String,
      default: ''
    },
    dense: { // Lowers max height of v-card-title
      type: Boolean,
      default: false
    },
    fillHeight: { // Fills height of v-card-text
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      validator: v => ['', 'small', 'medium', 'large', 'x-large', '2x-large', 'full'].includes(v),
      default: ''
    },
    maxWidth: {
      type: [String, Number],
      default: '773'
    },
    minHeight: {
      type: [String, Number],
      default: ''
    },
    persistent: {
      type: Boolean,
      default: true
    },
    retainFocus: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    backAction: {
      type: Boolean,
      default: false
    },
    titleLine: { // Adds bottom border to v-card-title
      type: Boolean,
      default: true
    },
    hideClose: Boolean,
    useDefaultActions: Boolean
  },

  data: () => ({
    dialog: false
  }),

  computed: {
    dialogSize() {
      switch (this.size) {
        case 'small':
          return '400'
        case 'medium':
          return '600'
        case 'large':
          return '700'
        case 'x-large':
          return '1000'
        case '2x-large':
          return '1200'
        case 'full':
          return '100%'
        default:
          return ''
      }
    }
  },

  watch: {
    value(v) {
      this.dialog = v
      if (v) {
        this.$nextTick(() => {
          this.scrollToTop()
        })
      }
    }
  },

  methods: {
    scrollToTop() {
      this.$refs.dialogContentRef && this.$refs.dialogContentRef.scrollTo(0, 0)
    },
    hide() {
      this.$emit('input', false)
      this.$emit('close', false)
    },
    save() {
      this.$emit('save')
    }
  }
}
</script>

<style lang="scss" scoped>
.e-dialog .wrap-content {
  max-height: 90vh;
  &.fill-height-content {
    height: 87vh;
  }
  .btn-close {
    z-index: 2;
  }
}
</style>
