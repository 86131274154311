export const state = () => ({
  page: 1,
  list: null,
  isStopped: false,
  unreadCount: 0
})

export const getters = {
  list(state) {
    return state.list
  }
}

export const mutations = {
  updateList(state, { list, isStopped, page }) {
    if (!state.list || !list) {
      state.list = list
    } else {
      state.list = state.list.concat(list)
    }
    if (typeof page !== 'undefined') {
      state.page = page
    } else {
      state.page++
    }
    state.isStopped = isStopped
  },
  updateListItem(state, { id, data }) {
    state.list = state.list.map((item) => {
      if (item._id === id) {
        return {
          ...item,
          ...(data || {})
        }
      }
      return item
    })
  },
  setUnreadCount(state, count) {
    state.unreadCount = count || 0
  },
  markAllAsRead(state) {
    state.list = state.list.map((item) => {
      return { ...item, isRead: true }
    })
  },
  resetList(state) {
    state.list = null
    state.page = 1
    state.isStopped = false
  }
}
