export default function ($axios, context) {
  return {
    async getPublishedUrl(streamId, channel) {
      const url = await $axios.$get(`/streams/${streamId}/published-url`)
      return {
        status: url.status,
        origin: url.origin,
        pathname: url.pathname,
        search: `?channel=${channel || ''}`,
        href: `${url.href}?channel=${channel || ''}`
      }
    },

    async getValidatePublish(streamId, params) {
      const validate = await $axios.$get(`/streams/${streamId}/validate-publish`, { params })
      return validate
    },

    async updateStream(streamId, body = {}) {
      const data = await $axios.$patch(`/streams/${streamId}`, body)
      return data
    },

    async duplicateStream(streamId, { cloneGatedContent = false } = {}) {
      context.$store.commit('setSubmiting', { updateStream: true })
      try {
        const { _id } = await $axios.$post(`/streams/${streamId}/duplicate`, {
          cloneGatedContent
        })
        context.$helpers.gaTracking('Dashboard', 'dashboard_stream_duplicate')
        context.$helpers.tractMixpanel('dashboard_stream_duplicate', 'duplicate')
        context.$notify.success({
          title: 'Stream',
          message: 'Duplicate successfully!'
        })
        return _id
      } catch (error) {
        const errorMessage = context.$helpers.apiErrorMessage(error)
        if (context.$helpers.apiStatusCode(error) !== 403) {
          context.$notify.error({
            title: 'Stream',
            message: errorMessage
          })
        }
      }
      context.$store.commit('setSubmiting', { updateStream: false })
      return null
    },

    async loadStreamMenu(streamId, params) {
      const data = await $axios.$get(`/streams/${streamId}/menu`, { params })
      return data
    }
  }
}
