export default function ($axios, context) {
  return {
    async getNotification(params = {}) {
      try {
        const { page, isStopped } = context.$store.state.notification
        if (isStopped) { return }

        const result = await $axios.$get('/notification', {
          params: { page, ...params }
        })
        context.$store.commit('notification/updateList', {
          list: result.data,
          isStopped: result.isStopped
        })
        context.$store.commit('notification/setUnreadCount', result.unreadCount)
      } catch (error) {}
    }
  }
}
