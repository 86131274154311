<template>
  <e-dialog
    v-model="isShowing"
    :title-line="!!title"
    :title="title"
    :dense="!title"
    :max-width="maxWidth"
    class="position-relative"
    @close="hide"
  >
    <v-layout column>
      <component
        :is="allowedAction[popupAction.action]"
        v-if="popupAction.action"
        v-bind="params"
        @close="isShowing = false"
        @saved="done"
        @cancel="isShowing = false"
      />
    </v-layout>
  </e-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { has, get } from 'lodash'
import GettingStartedPublicStream from './popup-action/getting-started-public-stream.vue'
import GettingStartedStreamFirstView from './popup-action/getting-started-stream-first-view.vue'
import GettingStartedStreamFirstLead from './popup-action/getting-started-stream-first-lead.vue'
import GettingStartedPublicListing from './popup-action/getting-started-public-listing.vue'
import GettingStartedPublicProduct from './popup-action/getting-started-public-product.vue'
import GettingStartedListingFirstLead from './popup-action/getting-started-listing-first-lead.vue'
import GettingStartedCreateProduct from './popup-action/getting-started-create-product.vue'
import GettingStartedProductFirstSale from './popup-action/getting-started-product-first-sale.vue'
import GettingStartedContactBranding from './popup-action/getting-started-contact-branding.vue'

import FormManageSocials from '@/components/users/branding/partials/form-manage-socials.vue'
import FormManageLogo from '@/components/users/branding/partials/form-manage-logo.vue'
import GettingStartedSubdomain from '@/components/users/subdomain/setup-subdomain.vue'

export default {
  name: 'PopupAction',
  components: {
    GettingStartedPublicStream,
    GettingStartedStreamFirstView,
    GettingStartedStreamFirstLead,
    GettingStartedPublicListing,
    GettingStartedListingFirstLead,
    GettingStartedCreateProduct,
    GettingStartedPublicProduct,
    GettingStartedProductFirstSale,
    GettingStartedContactBranding,
    GettingStartedSubdomain,
    FormManageSocials,
    FormManageLogo
  },
  data: () => ({
    isShowing: false,
    allowedAction: {
      'gettingStarted.contentStream.publicStream': 'GettingStartedPublicStream',
      'gettingStarted.contentStream.firstView': 'GettingStartedStreamFirstView',
      'gettingStarted.contentStream.firstLead': 'GettingStartedStreamFirstLead',
      'gettingStarted.singlePropertyPage.profile': 'GettingStartedContactBranding',
      'gettingStarted.singlePropertyPage.publicSinglePropertyPage': 'GettingStartedPublicListing',
      'gettingStarted.singlePropertyPage.firstLead': 'GettingStartedListingFirstLead',
      'gettingStarted.paidProduct.product': 'GettingStartedCreateProduct',
      'gettingStarted.paidProduct.publicProduct': 'GettingStartedPublicProduct',
      'gettingStarted.paidProduct.firstSale': 'GettingStartedProductFirstSale',
      'gettingStarted.branding.subdomain': 'GettingStartedSubdomain',
      'gettingStarted.branding.socials': 'FormManageSocials',
      'gettingStarted.branding.logo': 'FormManageLogo'
    }
  }),
  computed: {
    ...mapGetters(['popupAction']),
    maxWidth() {
      return {
        'gettingStarted.singlePropertyPage.profile': 600,
        'gettingStarted.branding.socials': 550,
        'gettingStarted.branding.subdomain': 600
      }[get(this.popupAction, 'action')] || 900
    },
    title() {
      return {
        'gettingStarted.singlePropertyPage.profile': 'Add Listing CTA',
        'gettingStarted.branding.socials': 'Add Social Links',
        'gettingStarted.branding.subdomain': 'Setup Subdomain',
        'gettingStarted.branding.logo': 'Your Logo'
      }[get(this.popupAction, 'action')]
    },
    params() {
      return {
        'gettingStarted.branding.subdomain': {
          subdomain: this.$helpers.get(this.$auth, 'user.settings.subdomain')
        }
      }[get(this.popupAction, 'action')] || {}
    }
  },
  watch: {
    popupAction: {
      deep: true,
      handler(val) {
        if (val && val.action && has(this.allowedAction, val.action)) {
          this.isShowing = true
        }
      }
    }
  },
  methods: {
    hide() {
      this.$store.commit('setPopupAction', {
        action: null
      })
    },
    done() {
      this.$helpers.notifySuccess('Update successfully!')
      this.hide()
      this.isShowing = false
      setTimeout(() => {
        this.$store.commit('user/setFlagReloadChecklist')
      }, 3000)
    }
  }
}
</script>

<style>

</style>
