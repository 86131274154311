export default function ($axios, context) {
  return {
    async getMicrositeDetails(params) {
      const { data } = await $axios.$get('/content-items/details', { params })
      return data
    },

    async getPublishedUrl(contentItemId) {
      const url = await $axios.$get(`/content-items/${contentItemId}/published-url`)
      return url
    },

    async getForm(formId) {
      const { formContent } = await $axios.$get('/form-contents/prep-data', {
        params: { formContentId: formId }
      })
      return formContent
    }
  }
}
