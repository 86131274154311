<template v-cloak>
  <v-tooltip top :max-width="maxWidth" color="grey darken-4">
    <template v-if="text" #activator="{ on, attrs }">
      <v-icon
        class="e-info-icon"
        :color="color"
        dark
        v-bind="attrs"
        :size="size"
        v-on="on"
      >
        info
      </v-icon>
    </template>
    <span class="text-caption">{{ text }}</span>
  </v-tooltip>
</template>

<script>

export default {
  name: 'EInfo',

  props: {
    text: {
      type: [String, Number],
      default: ''
    },
    color: {
      type: String,
      default: 'secondary'
    },
    size: {
      type: [Number, String],
      default: 20
    },
    maxWidth: {
      type: [Number, String],
      default: 300
    }
  }
}
</script>
<style lang="scss" scoped>
.e-info-icon {
  cursor: help;
}
</style>
