<template>
  <v-app>
    <v-main>
      <v-layout wrap class="authen-layout overflow-y-auto">
        <v-flex sm4 xs12 class="flex-0-0 pl-10 pr-14 primary left-content">
          <div class="flex-0-0 mb-5">
            <a href="https://eown.io"><img src="/logo-white.svg"></a>
          </div>
          <h6 class="mb-7 white--text text-h6">
            Better content experience. Better leads.
          </h6>
          <p class="white--text text-body-2">
            Create personalized content experience for your business on Social, Email, SEO and SMS  in the twinkling of an eye
          </p>
        </v-flex>

        <v-flex sm8 xs12 class="d-flex white right-content">
          <nuxt />
        </v-flex>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.authen-layout {
  height: 100vh;
  .left-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (max-width: 700px) {
  .authen-layout .left-content {
    display: none;
  }
  .authen-layout .right-content {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
