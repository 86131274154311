<template>
  <v-layout column>
    <v-layout class="px-5 pt-8 pb-6">
      <e-input>
        <button
          v-for="social in socialIcons"
          :key="social.name"
          fab
          small
          depressed
          class="mr-2"
          @click.prevent="openPopupEditSocial(social)"
        >
          <img :src="hasSocialUrl(social.name) ? social.activeIcon : social.inactiveIcon" class="w-20">
        </button>
      </e-input>
    </v-layout>

    <v-layout class="w-full white px-5 pb-5" :class="hideLine ? '' : 'pt-5 border-t border-t-secondary border-solid'">
      <v-spacer v-if="!leftActions" />
      <v-btn
        v-if="!hideCancel"
        large
        outlined
        color="primary"
        class="mr-3 rounded-lg"
        @click="$emit('close')"
      >
        Cancel
      </v-btn>
      <v-btn
        large
        depressed
        color="primary"
        class="rounded-lg"
        :loading="$store.getters.isUploading || $store.getters.isRequesting"
        @click="save"
      >
        Save Changes
      </v-btn>
    </v-layout>

    <PopupEditSocialLink
      v-model="showPopupEditSocialLink"
      :social="editSocialData"
      @save="updateSocialLinkItem"
    />
  </v-layout>
</template>

<script>
import PopupEditSocialLink from '../popup.edit-social-link.vue'
import { socialIcons } from '@/constants/socials'

export default {
  name: 'FormManageSocials',

  components: {
    PopupEditSocialLink
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },
    hideCancel: {
      type: Boolean,
      default: false
    },
    hideLine: {
      type: Boolean,
      default: false
    },
    leftActions: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    socialIcons,
    formData: [],
    editSocialData: {},
    showPopupEditSocialLink: false
  }),

  async mounted() {
    if (this.value && this.value.length) {
      this.formData = this.$helpers.cloneDeep(this.value)
    } else {
      const { branding } = await this.$axios.$get(`/users/${this.$auth.user._id}/branding`)
      this.formData = this.$helpers.ensureArray(branding?.socials)
    }
  },

  methods: {
    hasSocialUrl(socialName) {
      return this.formData.find(item => item.name === socialName && item.link)
    },
    openPopupEditSocial(social) {
      const formDataSocial = this.formData.find(sl => sl.name === social.name)
      this.editSocialData = this.$helpers.cloneDeep({ ...social, ...(formDataSocial || {}) })
      this.showPopupEditSocialLink = !this.showPopupEditSocialLink
    },
    updateSocialLinkItem(social) {
      const index = this.formData.findIndex(sl => sl.name === social.name)
      if (index !== -1) {
        this.formData[index] = social
        this.formData = this.formData.filter(sl => sl.link)
      } else if (social.link) {
        this.formData.push(social)
      }
      this.formData = this.formData.map(item => ({
        _id: item._id,
        name: item.name,
        link: item.link.trim()
      }))
    },
    async save() {
      try {
        await this.$axios.$put('/users/checklists/company-branding', {
          socialLinks: this.formData
        })
        this.$emit('saved', this.formData)
      } catch (error) {
        this.$notify.error({
          title: 'User',
          message: this.$helpers.apiErrorMessage(error)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
