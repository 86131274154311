<template>
  <v-app class="bg-transparent">
    <v-layout class="membership-session w-full h-screen">
      <v-layout class="sidebar">
        <v-layout column>
          <v-layout align-center justify-center class="my-5 flex-grow-0">
            <img src="/logo-eown.svg" alt="EOwn Logo" class="w-50">
          </v-layout>
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in menuItems"
                :key="i"
                :to="item.href"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-layout>
      </v-layout>
      <v-layout class="h-screen overflow-y-auto" column>
        <v-layout class="flex-grow-0 px-10 py-4" align-center justify-space-between>
          <v-spacer />
          <v-layout justify-end class="flex-grow-0">
            <v-menu bottom offset-y min-width="150">
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  icon
                  large
                  v-on="on"
                >
                  <v-icon>
                    mdi-account-outline
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in accountItems"
                  :key="index"
                  @click="() => item.action && item.action()"
                >
                  <v-list-item-icon>
                    <v-icon small>
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-layout>
        </v-layout>
        <nuxt />
      </v-layout>
    </v-layout>
  </v-app>
</template>

<script>
export default {
  computed: {
    menuItems() {
      const getSessionHref = (route = '') => `/membership/${route}`

      return [
        {
          icon: 'payments',
          text: 'Products',
          href: getSessionHref('one-time-payments')
        },
        {
          icon: 'shopping_bag',
          text: 'Memberships',
          href: getSessionHref('subscriptions')
        }
      ]
    },

    accountItems() {
      return [{
        icon: 'logout',
        text: 'Logout',
        action: this.logout
      }]
    }
  },

  methods: {
    logout() {
      this.$cookies.remove('membership-session-id')
      this.$router.push('/membership')
    }
  }
}
</script>

<style lang="scss">
.membership-session {
  .sidebar {
    box-shadow: 1px 0px 0px 0px #DDD;
    max-width: 269px;
  }
}
</style>
