<template>
  <v-layout
    column
    justify-center
    align-center
    class="px-20 pb-10 pt-0 text-center"
  >
    <span class="text-h5">
      Share your published property websites to start generating leads from your property listings.
    </span>
    <span class="mt-3">
      Capture your audience to your database by incorporating forms, quizzes, property listings, lead magnets, or advertising graphics into your content streams
    </span>
    <v-img :src="image" class="rounded-lg mt-10" />
    <v-btn
      class="mt-5"
      color="primary"
      href="https://stream-studio.eown.io/streams/645562bd28ad9600088a9495"
      target="blank"
      elevation="0"
      large
    >
      Get tips on growing your listing leads.
    </v-btn>
    <span class="mt-5">
      Or
      <a href="https://stream-studio.eown.io/streams/6455632c28ad9600088a9499" class="primary--text" target="blank">
        learn more about the Listing Management dashboard.
      </a>
    </span>
  </v-layout>
</template>

<script>
import image from '@/assets/images/getting-started/stream-first-lead.png'

export default {
  data: () => ({
    image
  })
}
</script>

<style>

</style>
