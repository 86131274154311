import Vue from 'vue'

import VueCompositionAPI from '@vue/composition-api'
import components from './components'
import directives from './directives'

import rules from '@/utils/rules'
import { init as initUploadS3 } from '@/utils/upload-s3'
import dataCy from '@/constants/data-cy'

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})

Vue.use(VueCompositionAPI)

Object.entries(directives).forEach(([name, directive]) => {
  Vue.directive(name, directive)
})

export default (context, inject) => {
  inject('formRules', rules)
  inject('dataCy', dataCy)
  initUploadS3(context)
}
