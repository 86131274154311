<template>
  <v-card
    v-bind="vCardAttribute"
    rounded="xl"
    @click="toggle"
  >
    <v-card-text v-bind="vCardTextAttribute">
      <v-layout column>
        <v-img :src="pricingPlanData.image" />
        <v-layout column class="px-4 py-2">
          <v-checkbox v-model="selected" class="ma-0" hide-details="">
            <template #label>
              <div
                v-if="subscribeType === 'monthly' && pricingPlanData.monthlyPrice"
                :class="[
                  vCardAttribute.dark ? 'white--text' : 'grey--text text--darken-4'
                ]"
                class="text-body-1"
              >
                <span class="text-h5">{{ pricingPlanData.monthlyPrice | formatCurrency }}</span>
                <span>/month</span>
              </div>
              <div
                v-if="subscribeType === 'annual' && pricingPlanData.annualPrice"
                :class="[
                  vCardAttribute.dark ? 'white--text' : 'grey--text text--darken-4'
                ]"
                class="text-body-1"
              >
                <span class="text-h5">{{ pricingPlanData.annualPrice | formatCurrency }}</span>
                <span>/year</span>
                <span class="text-caption">({{ (pricingPlanData.annualPrice / 12) | formatCurrency }}/month)</span>
              </div>
            </template>
          </v-checkbox>
          <span class="text-h5 font-weight-medium my-2">{{ pricingPlanData.name }}</span>
          <v-layout column class="">
            <span class="text-caption font-weight-bold leading-1p5">
              {{ pricingPlanData.tagline }}
            </span>
            <span class="text-caption">
              {{ pricingPlanData.summary }}
            </span>
          </v-layout>
          <v-divider class="my-5" />
          <div v-if="Array.isArray(pricingPlanData.shortFeatures) && pricingPlanData.shortFeatures.length > 1" class="text-caption">
            <span class="font-weight-bold">Unlimited:</span>
            <ul>
              <li v-for="(feature, idx) in pricingPlanData.shortFeatures" :key="`short-feature-${idx}`">
                {{ feature }}
              </li>
            </ul>
          </div>
          <div v-else class="text-caption">
            <span class="font-weight-bold">Unlimited:</span>
            <span>{{ pricingPlanData.shortFeatures && pricingPlanData.shortFeatures[0] }}</span>
          </div>
        </v-layout>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { get, compact, pick } from 'lodash'

export default {
  name: 'PricingPlanCheckbox',

  props: {
    value: {
      type: Boolean
    },
    type: {
      type: String,
      required: true
    },
    pricingData: {
      type: Object,
      default: () => ({})
    },
    subscribeType: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    selected: false,
    rules: {
      quantity: [
        val => (val > 0 && val <= 10) || 'Not allowed'
      ]
    }
  }),

  computed: {
    vCardAttribute() {
      return {
        outlined: true,
        dark: this.selected,
        elevation: this.selected ? 1 : 0,
        color: this.selected ? 'primary' : '',
        class: ['pricing-plan-checkbox lighten-2', this.type, 'mx-2', { selected: this.selected }]
      }
    },

    vCardTextAttribute() {
      return {
        class: [this.vCardAttribute.dark ? 'white--text' : 'grey--text text--darken-4', 'pa-2']
      }
    },

    pricingPlanData() {
      const shortFeatures = compact(get(this.pricingData, 'shortFeatures', '').split('\n'))
      const pickingName = ['monthlyPrice', 'annualPrice']

      const planData = {
        contentStreams: {
          ...pick(this.pricingData, pickingName),
          name: get(this.pricingData, 'name').replace('Unlimited', ''),
          image: '/images/unlimited-content-streams-small.png',
          tagline: this.pricingData.tagline || 'Market your Services with Content',
          summary: this.pricingData.summary || 'Create and repurpose content into landing pages, blogs, gated content and websites, newsletters, and embeddable webpages.',
          shortFeatures: shortFeatures.length ? shortFeatures : [
            'Content streams, leads, visitors, customizable graphics, forms, gated content, downloads, content templates',
            'Hide EOwn branding',
            'Custom Domain'
          ]
        },

        listingMarketing: {
          ...pick(this.pricingData, pickingName),
          name: get(this.pricingData, 'name').replace('Unlimited', ''),
          image: '/images/unlimited-listing-marketing-small.png',
          tagline: this.pricingData.tagline || 'Market your Property Listings',
          summary: this.pricingData.summary || 'Create high-converting property websites and collect seller and buyer leads.',
          shortFeatures: shortFeatures.length ? shortFeatures : ['property listings, property websites, leads, visitors']
        },

        digitalProducts: {
          ...pick(this.pricingData, pickingName),
          name: get(this.pricingData, 'name').replace('Unlimited', ''),
          image: '/images/unlimited-digital-products-small.png',
          tagline: this.pricingData.tagline || 'Generate revenues with your Content',
          summary: this.pricingData.summary || 'Host digital products and Create high-converting checkout experiences to monetize your content',
          shortFeatures: shortFeatures.length ? shortFeatures : [
            'Sales pages, checkout pages, payments, transactions, students',
            'Video hosting'
          ]
        }
      }[this.type] || {
        ...pick(this.pricingData, pickingName),
        name: get(this.pricingData, 'name').replace('Unlimited', ''),
        tagline: this.pricingData.tagline,
        summary: this.pricingData.summary,
        shortFeatures: []
      }

      planData.allowQuantity = this.pricingData[this.subscribeType + 'AdditionalPrice']

      return planData
    }
  },

  watch: {
    value(val) {
      this.selected = val
    }
  },

  mounted() {
    this.selected = this.value
  },

  methods: {
    toggle() {
      this.$emit('input', !this.value)
    }

  }
}
</script>

<style lang="scss" scoped>
.pricing-plan-checkbox {
  color: var(--v-anchor-base) !important;
  border-radius: 30px !important;
  overflow: hidden;
}
</style>
