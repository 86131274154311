import { get, isEqual, isObject, isArray, isEmpty, pick } from 'lodash'
import moment from 'moment'
import mixpanel from 'mixpanel-browser'
import { getFilePreview } from '@/utils/upload-s3'
import { getOrDefaultStreamCover, getStreamIcon } from '@/utils/streams'
import { copyToClipboard } from '@/utils/index'

const getByPath = (data, path, defaultValue) => get(data, path, defaultValue)

const ensureObject = (obj, defaultValue) => {
  if (isObject(obj) && !isArray(obj)) {
    return obj
  } if (isObject(defaultValue) && !isArray(defaultValue)) {
    return defaultValue
  }
  return {}
}

const ensureArray = (arr, defaultValue) => {
  if (isArray(arr)) {
    return arr
  } if (isArray(defaultValue)) {
    return defaultValue
  }
  return []
}
const tractMixpanel = (event, parameter) => {
  mixpanel.track(event, {
    parameter
  })
}
const tractMixpanelGroup = (event, parameter, group) => {
  mixpanel.track(event, {
    company: [group],
    parameter
  })
}
function cloneDeep(obj) {
  return obj ? JSON.parse(JSON.stringify(obj)) : obj
}

const formatDate = (date, format = 'MM/DD/YYYY', timezone = true) => {
  if (date) {
    return timezone ? moment.utc(date).format(format) : moment(date).format(format)
  }
  return date
}

const apiErrorMessage = (error) => {
  try {
    console.trace(error)
    if (get(error, 'response.status') === 410 && window.Cookiebot) {
      window.Cookiebot.renew()
    }
    if (error && typeof error === 'object') {
      // console.log('error', error)
      // console.log('error.data', error.data)
      // console.log('error.response', error.response)
      // console.log('error.response.data', error.response.data)
      // console.log('error.data.message', error.data.message)
      // console.log('error.message', error.message)
      // return (error.data && (error.data.message || error.data.error || JSON.stringify(error.data, null, 2))) || error.data || error.message || error
      console.log('a', get(error, 'response.data.message'))
      return get(error, 'response.data.message') || get(error, 'response.data.error') || get(error, 'message')
    }
    return typeof error === 'string' ? error : JSON.stringify(error, null, 2)
  } catch (error) {
    return String(error)
  }
}

const apiStatusCode = (error) => {
  return get(error, 'response.data.statusCode')
}

const updateQueryUrl = (vue, queryObj) => {
  const currentQuery = JSON.parse(JSON.stringify(vue.$route.query))
  const tmpCurrentQuery = JSON.parse(JSON.stringify(vue.$route.query))
  const query = JSON.parse(JSON.stringify(queryObj))
  for (const key in query) {
    if (typeof query[key] === 'undefined' || !query[key]) {
      delete query[key]
      delete tmpCurrentQuery[key]
    }
  }
  if (!isEqual(query, currentQuery)) {
    vue.$router.push({ query: Object.assign({}, tmpCurrentQuery, query) })
  }
}

const replaceQueryUrl = (vue, queryObj) => {
  const currentQuery = JSON.parse(JSON.stringify(vue.$route.query))
  const tmpCurrentQuery = JSON.parse(JSON.stringify(vue.$route.query))
  const query = JSON.parse(JSON.stringify(queryObj))
  for (const key in query) {
    if (typeof query[key] === 'undefined' || !query[key]) {
      delete query[key]
      delete tmpCurrentQuery[key]
    }
  }
  if (!isEqual(query, currentQuery)) {
    vue.$router.replace({ query: Object.assign({}, tmpCurrentQuery, query) })
  }
}

const cleanQueryParams = (query, exclude = ['filterBy']) => {
  const newQuery = {}
  for (const key in query) {
    if (!isEmpty(query[key]) && !exclude.includes(key) && !['all', '{}', '[]'].includes(query[key])) {
      newQuery[key] = query[key]
    }
  }
  return newQuery
}

const getEmbedUrlParams = (url, { autoplay = 1 } = {}) => {
  if (url.includes('youtube.com')) {
    const id = url.replace('https://www.youtube.com/embed/', '')
    return `${url}?autoplay=${autoplay}&mute=1&controls=0&showinfo=0&modestbranding=1&loop=1&playlist=${id}&disablekb=1&fs=0&iv_load_policy=3&cc_load_policy=0&autohide=0&enablejsapi=1`
  }
  if (url.includes('vimeo.com')) {
    return `${url}?autoplay=${autoplay}&loop=1&muted=1&controls=0&enablejsapi=1`
  }
  return url
}

const scrollToFirstError = () => {
  const el = document.querySelector('.error--text')
  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export default (context, inject) => {
  mixpanel.init(context.$config.mixpanelKey, { debug: process.env.NODE_ENV !== 'production' })

  const gaTracking = (category, action, label, customAttrs = {}) => {
    // GA 4
    if (context.$gtag) {
      context.$gtag.event(action, {
        event_category: category,
        event_label: label,
        ...customAttrs
      })
    }
  }

  const gatedContentUnlocked = (contentItem) => {
    if (get(contentItem, 'gatedContent.product') && get(contentItem, 'gatedContent.enabled') && get(contentItem, 'gatedContent.productType') === 'gated-content') {
      const cookieName = `gated-content-${get(contentItem, 'gatedContent.product')}`
      const cookieValue = context.$cookies.get(cookieName)
      return cookieValue
    }
  }

  const markGatedContentUnlock = (contentItem) => {
    if (get(contentItem, 'gatedContent.product')) {
      const cookieName = `gated-content-${get(contentItem, 'gatedContent.product')}`
      context.$cookies.set(cookieName, true, {
        path: '/',
        maxAge: 60 * 60 * 24,
        httpOnly: false
      })
    }
  }

  const notifyError = (error, title) => {
    if (get(error, 'response.data.rolePermission')) {
      return
    }
    context.$notify.error({
      title: title || 'Error',
      message: typeof error === 'string' ? error : apiErrorMessage(error)
    })
  }

  const notifySuccess = (message, title) => {
    context.$notify.success({
      title: title || 'Success',
      message: typeof message === 'string' ? message : apiErrorMessage(message)
    })
  }

  const notifyInfo = (message, title) => {
    context.$notify.info({
      title: title || 'Info',
      message: typeof message === 'string' ? message : apiErrorMessage(message)
    })
  }

  const notifyWarning = (message, title) => {
    context.$notify.warning({
      title: title || 'Warning',
      message: typeof message === 'string' ? message : apiErrorMessage(message)
    })
  }

  const getCurrentUrl = () => {
    return context.route.fullPath
  }

  const getBackUrl = (to) => {
    const cbUrl = context.route.query.cbUrl
    return cbUrl || to
  }

  const scrollToElement = (selector) => {
    const el = document.querySelector(selector)
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const canAccessModule = (action, moduleName) => {
    const user = context.$auth.user
    if (!user || !action || !moduleName) {
      return false
    }
    const aclRules = get(user, 'acl.can', [])
    if (!aclRules || !aclRules.length) {
      return false
    }

    if (action === 'any') {
      return aclRules.some(r => (
        r.includes('read:any') ||
        r.includes('create:any') ||
        r.includes('update:any') ||
        r.includes('delete:any') ||
        r.includes('publish:any') ||
        r.includes(`read:${moduleName}`) ||
        r.includes(`create:${moduleName}`) ||
        r.includes(`update:${moduleName}`) ||
        r.includes(`delete:${moduleName}`) ||
        r.includes(`publish:${moduleName}`)
      ))
    } else {
      return aclRules.includes(`${action}:${moduleName}`)
    }
  }

  inject('helpers', {
    get: getByPath,
    pick,
    ensureObject,
    ensureArray,
    cloneDeep,
    formatDate,
    tractMixpanel,
    tractMixpanelGroup,
    apiErrorMessage,
    apiStatusCode,
    updateQueryUrl,
    cleanQueryParams,
    gaTracking,
    getFilePreview,
    getOrDefaultStreamCover,
    getStreamIcon,
    getEmbedUrlParams,

    gatedContentUnlocked,
    markGatedContentUnlock,

    notifyError,
    notifySuccess,
    notifyInfo,
    notifyWarning,

    copyToClipboard,
    replaceQueryUrl,

    scrollToFirstError,
    getCurrentUrl,
    getBackUrl,

    scrollToElement,

    canAccessModule
  })
}
