<template v-cloak>
  <v-tooltip v-bind="cptOptions" :max-width="maxWidth">
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <template v-if="!$slots['tooltip-text']">
          <span :class="textClass" v-html="text" />
        </template>
        <slot name="tooltip-text" />
      </div>
    </template>

    <v-flex column wrap class="e-tooltip-popup">
      <span
        v-for="(tooltipText, tIdx) in cptTooltips"
        :key="tIdx"
        class="mb-1 text-caption"
        v-html="tooltipText"
      />
      <div :class="`triangle triangle-${position}`" />
    </v-flex>
  </v-tooltip>
</template>

<script>

export default {
  name: 'ETooltip',

  props: {
    position: {
      type: String,
      default: 'top'
    },
    text: {
      type: String,
      default: ''
    },
    textClass: {
      type: [String, Array],
      default: 'text-body-1'
    },
    tooltips: {
      type: [String, Array],
      default: ''
    },
    maxWidth: {
      type: [Number, String],
      default: ''
    }
  },

  computed: {
    cptOptions() {
      const options = {
        transition: 'fade-transition',
        'z-index': 999,
        'content-class': 'e-tooltip ma-0 px-3 pt-3 pb-2 e-tooltip-primary',
        disabled: !this.cptTooltips.length
      }
      options[this.position] = true
      return options
    },
    cptTooltips() {
      let tooltips = []
      if (this.tooltips) {
        tooltips = Array.isArray(this.tooltips) ? this.tooltips : [this.tooltips]
      } else if (this.text) {
        tooltips = [this.text]
      }
      return tooltips
    }
  }
}
</script>
<style lang="scss">
.e-tooltip {
  .e-tooltip-popup span {
    display: block;
  }
  .triangle {
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid transparent;
  }
  .triangle-left {
    right: 0;
    top: 50%;
    border-right: 0;
    margin-top: -8px;
    margin-right: -8px;
  }
  .triangle-right {
    left: 0;
    top: 50%;
    border-left: 0;
    margin-top: -8px;
    margin-left: -8px;
  }
  .triangle-top {
    bottom: 0;
    left: 50%;
    border-bottom: 0;
    margin-left: -8px;
    margin-bottom: -8px;
  }
  .triangle-bottom {
    top: 0;
    left: 50%;
    border-top: 0;
    margin-left: -8px;
    margin-top: -8px;
  }
}

@mixin tooltip-style-color($color) {
  .triangle-left {
    border-left-color: $color;
  }
  .triangle-right {
    border-right-color: $color;
  }
  .triangle-top {
    border-top-color: $color;
  }
  .triangle-bottom {
    border-bottom-color: $color;
  }
}

.e-tooltip.e-tooltip-primary {
  background-color: #212121;
  color: #fff;
  @include tooltip-style-color(#212121);
}
</style>
